import React from "react";
import {
  Icon,
  Button,
  Tooltip,
  Dropdown,
  Menu,
  Table,
  Modal,
  Input,
} from "antd";
import Plan from "./Plan";
import AddCredits from "./AddCredits";
import AddJobCredits from "../AddJobCredits/AddJobCredits";
import "./OrgDetailHeader.scss";
import { getAccountName } from "../../utils/PortalUtils";
import PendingCreditRequestModel from "../PendingCreditRequestModel";

const planTypes = ["Limited", "Unlimited"];

class OrgDetailHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlanVisible: false,
      isAddCreditsVisible: false,
      isAddJobCreditVisible: false,
      isRequestedCreditModelVisible: false,
      currentPendingRequest: null,
      pixelType: "ServerToServer",
    };
    this.togglePlanVisibility = this.togglePlanVisibility.bind(this);
    this.toggleAddCreditsVisibility =
      this.toggleAddCreditsVisibility.bind(this);
    this.toggleAddJobCreditsVisibility =
      this.toggleAddJobCreditsVisibility.bind(this);
    this.requestApprovedOrRejected = this.requestApprovedOrRejected.bind(this);
    this.toggleRequestedCreditModelVisibility =
      this.toggleRequestedCreditModelVisibility.bind(this);
  }

  componentDidUpdate() {
    const { pendingRequests } = this.props;
    const { currentPendingRequest } = this.state;
    if (pendingRequests?.length && currentPendingRequest === null)
      this.setState({ currentPendingRequest: pendingRequests });
  }

  togglePlanVisibility() {
    const { isPlanVisible } = this.state;
    this.setState({
      isPlanVisible: !isPlanVisible,
    });
  }
  toggleRequestedCreditModelVisibility() {
    const { isRequestedCreditModelVisible } = this.state;
    this.setState({
      isRequestedCreditModelVisible: !isRequestedCreditModelVisible,
    });
  }

  toggleAddCreditsVisibility() {
    const { isAddCreditsVisible } = this.state;
    this.setState({
      isAddCreditsVisible: !isAddCreditsVisible,
    });
  }

  toggleAddJobCreditsVisibility() {
    const { isAddJobCreditVisible } = this.state;
    this.setState({
      isAddJobCreditVisible: !isAddJobCreditVisible,
    });
  }
  popoverContent(columns) {
    const { isRequestedCreditModelVisible, currentPendingRequest } = this.state;
    return (
      <Modal
        title={"Requested Credits"}
        onCancel={this.toggleRequestedCreditModelVisibility}
        zIndex={2050}
        width={1000}
        visible={isRequestedCreditModelVisible}
        okText="Save"
        destroyOnClose
        centered
        footer={[
          <Button
            key="back"
            onClick={this.toggleRequestedCreditModelVisibility}
          >
            Close
          </Button>,
        ]}
      >
        <Table
          pagination={false}
          dataSource={currentPendingRequest}
          columns={columns}
          scroll={{ y: 240 }}
        />
      </Modal>
    );
  }

  requestApprovedOrRejected(status, key) {
    const { plan, smsPlan, changePendingRequestStatus, pendingRequests } =
      this.props;
    const { currentPendingRequest } = this.state;
    const filteredData = pendingRequests.filter((item) => item.Id === key);
    const filteredDataForApprovedCredits = currentPendingRequest.filter(
      (item) => item.Id === key
    );

    const planId = filteredData[0].CreditType === "Text" ? smsPlan.Id : plan.Id;
    const filter = {
      Id: filteredData[0].Id,
      CreditType: filteredData[0].CreditType,
      Status: status,
      PlanId: planId,
      RequestCredits: filteredData[0].RequestCredits,
      ApprovedCredits: filteredDataForApprovedCredits[0].RequestCredits,
    };
    changePendingRequestStatus(filter, filteredData[0].Id);
    const newStatus = currentPendingRequest.filter((item) => item.Id !== key);
    this.setState({
      currentPendingRequest: newStatus,
    });
  }

  handleApprovedCreditsChange = (event, Id, creditType, record, text) => {
    const updatedData = this.state.currentPendingRequest.map((item) =>
      item.Id === Id ? { ...item, RequestCredits: event } : item
    );
    this.setState({
      currentPendingRequest: updatedData,
    });
  };

  changePixelType = (value) => {
    this.setState({ pixelType: value });
  }

  downloadTrackingInfo = () => {
    const { fetchOrgTrackingInfo, orgDetails } = this.props;
    const { pixelType } = this.state;
    fetchOrgTrackingInfo(orgDetails.Id, pixelType);
  };

  render() {
    const {
      orgDetails,
      onEdit,
      plan,
      onAddCredits,
      onUpdatePlan,
      onCreatePlan,
      onAddJobCredits,
      accountTypes,
      smsPlan,
      orgTrackingInfoApiStatus,
    } = this.props;
    const {
      isPlanVisible,
      isAddCreditsVisible,
      isAddJobCreditVisible,
      currentPendingRequest,
      pixelType,
    } = this.state;

    const columns = [
      {
        title: "UserName",
        dataIndex: "UserName",
        key: "UserName",
      },
      {
        title: "Credits",
        dataIndex: "RequestCredits",
        key: "RequestCredits",
        editable: true,
        render: (text, record) => {
          return (
            <Input
              value={text}
              onChange={(e) =>
                this.handleApprovedCreditsChange(
                  e.target.value,
                  record.Id,
                  record.CreditType
                )
              }
            />
          );
        },
      },
      {
        title: "CreditType",
        dataIndex: "CreditType",
        key: "CreditType",
      },
      {
        title: "",
        dataIndex: "",
        key: "CreditTypeButtons",

        render: (record) => (
          <div style={{ display: "flex", gap: "7px" }}>
            <Button
              className="credit-btn"
              type="primary"
              onClick={() =>
                this.requestApprovedOrRejected("approved", record.Id)
              }
            >
              Approve
            </Button>
            <Button
              className="credit-btn"
              type="primary"
              onClick={() =>
                this.requestApprovedOrRejected("rejected", record.Id)
              }
            >
              Reject
            </Button>
          </div>
        ),
      },
    ];
    const moreMenu = () => (
      <Menu>
        <Menu.Item onClick={() => onEdit()} key="edit">
          Edit
        </Menu.Item>
      </Menu>
    );
    let creditAdditionButton = null;
    if (plan || smsPlan) {
      creditAdditionButton = (
        <Button
          type="primary"
          className="credit-btn"
          onClick={this.toggleAddCreditsVisibility}
        >
          Add Credits
        </Button>
      );
    }

    const accountName = getAccountName(orgDetails?.AccountTypeId, accountTypes);

    return (
      <div className="job-header">
        <div>
          <div className="jobtitle-status-wrapper">
            <Tooltip>
              <span id="title">{orgDetails.Name} </span>{" "}
              <span className="paidact">{accountName}</span>
            </Tooltip>
            <span id="status" className="statusfix">
              <div size="small" className="job-status">
                {orgDetails.IsActive ? "(Active)" : "(InActive)"}
              </div>
            </span>
          </div>
          <div>
            <span id="client">{orgDetails.Address} </span>
            <span id="id">{orgDetails.Id}</span>
          </div>
        </div>
        <div className="job-share-button-wrapper" />
        <select
          id="trackingInfoType"
          onChange={(event) => this.changePixelType(event.target.value)}
          value={pixelType}
          style={{ marginRight: "4px" }}
        >
          <option value="ServerToServer">
            Server To Server Tracking Implementation
          </option>
          <option value="TrackingPixel">
            Pixel Based Tracking Implementation{" "}
          </option>
        </select>
        <Button
          type="primary"
          className="download-tracking-info-btn"
          onClick={this.downloadTrackingInfo}
          loading={orgTrackingInfoApiStatus === "PENDING"}
        >
          Download
        </Button>
        {accountName?.toLowerCase() === "aryapulse" ? (
          <div>
            <Button type="primary" onClick={this.toggleAddJobCreditsVisibility}>
              Add job credits
            </Button>
            <AddJobCredits
              toggleAddJobCreditsVisibility={this.toggleAddJobCreditsVisibility}
              isVisible={isAddJobCreditVisible}
              orgDetails={orgDetails}
              onAddJobCredits={onAddJobCredits}
            />
          </div>
        ) : (
          <React.Fragment>
            <Button type="primary" onClick={this.togglePlanVisibility}>
              Plan
            </Button>
            {creditAdditionButton}
            {plan || smsPlan ? (
              <Button
                className="credit-btn"
                type="primary"
                onClick={this.toggleRequestedCreditModelVisibility}
              >
                Requested Credits
              </Button>
            ) : null}

            <PendingCreditRequestModel
              isRequestedCreditModelVisible={
                this.state.isRequestedCreditModelVisible
              }
              columns={columns}
              currentPendingRequest={currentPendingRequest}
              toggleRequestedCreditModelVisibility={
                this.toggleRequestedCreditModelVisibility
              }
            />
            <Plan
              orgDetails={orgDetails}
              plan={plan}
              planTypes={planTypes}
              onCreatePlan={onCreatePlan}
              onUpdatePlan={onUpdatePlan}
              togglePlanVisibility={this.togglePlanVisibility}
              isVisible={isPlanVisible}
              smsPlan={smsPlan}
            />
            <AddCredits
              orgDetails={orgDetails}
              plan={plan}
              onAddCredits={onAddCredits}
              toggleAddCreditsVisibility={this.toggleAddCreditsVisibility}
              isVisible={isAddCreditsVisible}
              smsPlan={smsPlan}
            />
          </React.Fragment>
        )}
        <div className="more-wrapper">
          <Dropdown overlay={() => moreMenu()} placement="bottomRight">
            <Button className="more">
              <Icon style={{ transform: "rotate(90deg)" }} type="ellipsis" />
            </Button>
          </Dropdown>
        </div>
      </div>
    );
  }
}

export default OrgDetailHeader;
