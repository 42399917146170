import React from "react";
import { Modal, Form, Tabs } from "antd";
import _ from "lodash";
import "./OrgDetailHeader.scss";
import AddCreditDetails from "./AddCreditDetails";

const { TabPane } = Tabs;

class AddCredits extends React.Component {
  constructor(props) {
    super(props);
    this.handleOk = this.handleOk.bind(this);

    this.state = {
      activePanel: "Arya",
    };
  }

  handleOk() {
    const {
      form,
      plan,
      orgDetails,
      onAddCredits,
      toggleAddCreditsVisibility,
      smsPlan,
    } = this.props;
    const { activePanel } = this.state;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const formValues = _.cloneDeep(values);
        const planValues = activePanel === "Arya" ? plan : smsPlan;
        if (onAddCredits && planValues && orgDetails) {
          onAddCredits(formValues, planValues, activePanel);
        }
        toggleAddCreditsVisibility();
      }
    });
  }

  onChangePanel = (value) => {
    this.setState({
      activePanel: value,
    });
  };

  render() {
    const {
      form,
      plan,
      isVisible,
      orgDetails,
      toggleAddCreditsVisibility,
      smsPlan,
    } = this.props;
    const { activePanel } = this.state;

    const selectedPlan = activePanel === "Arya" ? plan : smsPlan;
    const addCredits = <AddCreditDetails form={form} plan={selectedPlan} />;

    return (
      <Modal
        title={`Add Credits- ${orgDetails.Name}`}
        visible={isVisible}
        okText="Save"
        destroyOnClose={true}
        onOk={this.handleOk}
        onCancel={toggleAddCreditsVisibility}
      >
        <Tabs
          tabBarStyle={{
            marginBottom: "0px",
          }}
          onTabClick={this.onChangePanel}
          destroyInactiveTabPane
          animated={false}
          activeKey={this.state.activePanel}
        >
          <TabPane tab={<div>Arya</div>} key="Arya">
            {addCredits}
          </TabPane>

          <TabPane tab={<div>Text</div>} key="Text">
            {addCredits}
          </TabPane>
        </Tabs>
      </Modal>
    );
  }
}

const AddCreditsComponent = Form.create()(AddCredits);

export default AddCreditsComponent;
