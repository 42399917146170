import React from "react";
import { Radio, Button, Skeleton } from "antd";

export default class MessageServiceProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveButtonVisibility: false
    };
    this.onSave = this.onSave.bind(this);
    this.onServiceProviderChange = this.onServiceProviderChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { messageServiceProviders, getSubscribedProvider } = this.props;
    if (prevProps.messageServiceProviders !== messageServiceProviders) {
      this.setState({
        serviceProviderDetails: getSubscribedProvider(messageServiceProviders)
      });
    }
  }

  onServiceProviderChange(event) {
    const serviceProvider = event.target.value;
    this.setState({
      serviceProviderDetails: serviceProvider,
      saveButtonVisibility: true
    });
  }

  onSave() {
    const { updateMessageServiceProvider, orgDetails } = this.props;
    const { serviceProviderDetails } = this.state;
    const query = {
      ProviderId: serviceProviderDetails.Id,
      Order: serviceProviderDetails.Order
    };
    updateMessageServiceProvider(orgDetails.OrgGuid, query, "message");
    this.setState({
      saveButtonVisibility: false
    });
  }

  render() {
    const { serviceProviderDetails, saveButtonVisibility } = this.state;
    const { messageServiceProviders, getSubscribedProvider } = this.props;
    return (
      <div className="contact-settings">
        <div className="contact-settings-admin">
          <div className="contact-settings-header">
            <div className="contact-settings-title">
              Text Messaging Service Provider
            </div>
            <div className="contact-settings-description">
              Setup your text messaging provider
            </div>
          </div>
          <Button
            className="app-save"
            disabled={!saveButtonVisibility}
            onClick={this.onSave}
          >
            Save Changes
          </Button>
        </div>
        <Skeleton
          active
          paragraph={{ rows: 2 }}
          loading={!messageServiceProviders.length}
        >
          <div className="contact-settings-container">
            <div className="contact-type-options">
              <div className="contact-options-heading">
                Select text messaging information provider:
              </div>
              <Radio.Group
                value={
                  serviceProviderDetails ||
                  getSubscribedProvider(messageServiceProviders)
                }
                onChange={this.onServiceProviderChange}
              >
                {messageServiceProviders.map(serviceProvider => (
                  <Radio
                    className="radio-contact-option"
                    value={serviceProvider}
                    key={serviceProvider}
                    disabled={
                      serviceProvider.DisplayName.toLowerCase() === "twilio"
                    }
                  >
                    {serviceProvider.DisplayName}
                  </Radio>
                ))}
              </Radio.Group>
            </div>
          </div>
        </Skeleton>
      </div>
    );
  }
}
