export const FETCH_CONFIG_API_STATUS = "FETCH_CONFIG_API_STATUS";

export const SET_CONFIG = "SET_CONFIG";

export const setConfigApiStatus = (status) => ({
  type: FETCH_CONFIG_API_STATUS,
  payload: status,
});

export const setConfig = (payload) => ({
  type: SET_CONFIG,
  payload,
});
