import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Drawer } from "antd";
import { getClientDetails, getSubsdiaryCompanies, getCompetitorCompanies, getIndustries } from "../../reducers/clientReducer";
import * as ClientActions from "../../actions/clientActions";

const mapStateToProps = state => ({
    clientDetails: getClientDetails(state),
    subsdiaryCompanies: getSubsdiaryCompanies(state),
    competitorCompanies: getCompetitorCompanies(state),
    industries: getIndustries(state)
});

const mapDispatchToProps = {
    createClient: ClientActions.createClient,
    updateClient: ClientActions.updateClient,
    fetchClientDetails: ClientActions.fetchClientDetails,
    fetchSubsdiaryCompanies: ClientActions.fetchSubsdiaryCompanies,
    fetchCompetitorCompanies: ClientActions.fetchCompetitorCompanies,
    fetchIndustries: ClientActions.fetchIndustries
};

class ClientDetail extends React.Component {
    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(oldClientDetails, values) {
        const { createClient, updateClient, clientId, mode } = this.props;
        let clientDetails = {};
        if (oldClientDetails) {
            clientDetails = oldClientDetails;
        }

        Object.keys(values).forEach(key => {
            if (typeof values[key] !== "undefined" && key !== "CompetitorCompanies" && key !== "Industries") {
                clientDetails[key] = values[key];
            }
        });
        if (mode === "create") {
            createClient(clientDetails);
        } else {
            updateClient(clientId, clientDetails);
        }
    }

    componentDidMount() {
        const { mode } = this.props;
        if (mode === "edit") {
            const { fetchClientDetails, clientId } = this.props;
            fetchClientDetails(clientId);
        }
        //make api calls for subsdiaries, competitors and industries
    }

    handleClose() {
        const { handleClose } = this.props;
        handleClose();
    }

    render() {
        const { visible, mode } = this.props;
        if (!visible) {
            return null;
        }
        return (
            <Drawer
                onClose={this.handleClose}
                visible={true}
                className="client-form-drawer"
                title={mode === "create" ? "New Client" : "Client"}
            >
                Yet to be implemented
                {/* <Skeleton
                    active
                    paragraph={{ rows: 16 }}
                    loading={mode === "edit" ? (clientDetails ? false : true) : false}
                >
                    <ClientFormComponent
                        clientDetails={clientDetails}
                        handleClose={this.handleClose}
                        handleSubmit={this.handleSubmit}
                        orgDetails={orgDetails}
                        allSubsdiaryCompanies={subsdiaryCompanies}
                        allIndustries={industries}
                        allCompetitorCompanies={competitorCompanies}
                        mode={mode}
                    />
                </Skeleton> */}
            </Drawer>
        )
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ClientDetail)
);