import _ from "lodash";
export default function PortalReducer(state = {}, action) {
  switch (action.type) {
    case "SET_ALL_PORTAL_COUNTRIES": {
      const { allPortalCountries } = action.payload;
      const portalSettings = _.cloneDeep(state.portalSettings) || {};
      allPortalCountries.forEach(countryDetails => {
        if (!portalSettings[countryDetails.Portal]) {
          portalSettings[countryDetails.Portal] = {};
        }
        portalSettings[countryDetails.Portal].countries =
          countryDetails.Countries;
      });
      return {
        ...state,
        portalSettings
      };
    }

    case "SET_ALL_PORTAL_CREDENTIALS": {
      const { allPortalCredentials } = action.payload;
      const portalSettings = _.cloneDeep(state.portalSettings) || {};
      allPortalCredentials.forEach(portalDetails => {
        if (!portalSettings[portalDetails.Portal]) {
          portalSettings[portalDetails.Portal] = {};
        }
        const newPortalDetails = _.cloneDeep(portalDetails);
        if (
          newPortalDetails.Portal !== "Internal" &&
          newPortalDetails.Portal !== "Social"
        ) {
          newPortalDetails.OrganizationPortalAuthenticationOptionsViewModels = (
            newPortalDetails.OrganizationPortalAuthenticationOptionsViewModels ||
            []
          ).map(credential => {
            if (credential.AuthenticationOption.CountryCode === undefined)
              return {
                ...credential,
                AuthenticationOption: {
                  ...credential.AuthenticationOption,
                  CountryCode: "All"
                }
              };
            return credential;
          });
        }
        portalSettings[newPortalDetails.Portal].portalCredentials =
          newPortalDetails.OrganizationPortalAuthenticationOptionsViewModels;
      });
      return {
        ...state,
        portalSettings
      };
    }
    case "SET_PORTAL_ACCOUNT_USERS": {
      const { portal, accountIndex, users } = action.payload;
      const portalSettings = _.cloneDeep(state.portalSettings);
      _.set(
        portalSettings,
        [portal, "portalCredentials", accountIndex, "AssignedUserIds"],
        users
      );
      return {
        ...state,
        portalSettings
      };
    }
    case "SET_AUTHENTICATION_API_STATUS": {
      const { portal, accountIndex, status } = action.payload;
      const portalSettings = _.cloneDeep(state.portalSettings);
      const authenticationApiStatus =
        portalSettings[portal].authenticationApiStatus || [];
      authenticationApiStatus[accountIndex] = status;
      portalSettings[portal] = {
        ...portalSettings[portal],
        authenticationApiStatus
      };
      return {
        ...state,
        portalSettings
      };
    }
    case "SET_PORTAL_CREDENTIALS": {
      const { portal, credentials } = action.payload;
      let newCredentials = { AuthenticationOption: credentials };
      const portalSettings = _.cloneDeep(state.portalSettings);
      if (!credentials.CountryCode) {
        newCredentials = {
          ...newCredentials,
          AuthenticationOption: {
            ...newCredentials.AuthenticationOption,
            CountryCode: "All"
          }
        };
      }
      portalSettings[portal].portalCredentials.push(newCredentials);
      return {
        ...state,
        portalSettings
      };
    }
    case "DELETE_PORTAL_CREDENTIALS": {
      const { portal, accountIndex } = action.payload;
      const portalSettings = _.cloneDeep(state.portalSettings);
      portalSettings[portal].portalCredentials.splice(accountIndex, 1);
      return {
        ...state,
        portalSettings
      };
    }
    case "UPDATE_PORTAL_ACCOUNT_USERS": {
      const { portal, portalAccountDetails } = action.payload;
      const portalSettings = _.cloneDeep(state.portalSettings);
      const allPortalCredentials = _.get(
        portalSettings,
        [portal, "portalCredentials"],
        []
      );
      const currentAccountIndex = allPortalCredentials.findIndex(account => {
        return compareCredentials(
          account.AuthenticationOption,
          portalAccountDetails.AuthenticationOption
        );
      });
      if (currentAccountIndex !== -1) {
        let updatedAssignedUserIds = [];
        const currentPortalCredential =
          allPortalCredentials[currentAccountIndex];
        updatedAssignedUserIds = _.difference(
          currentPortalCredential.AssignedUserIds,
          portalAccountDetails.UnAssignedUserIds
        );
        updatedAssignedUserIds = _.union(
          updatedAssignedUserIds,
          portalAccountDetails.AssignedUserIds
        );
        _.set(
          portalSettings,
          [portal, "portalCredentials", currentAccountIndex, "AssignedUserIds"],
          updatedAssignedUserIds
        );
      }

      return {
        ...state,
        portalSettings
      };
    }
    default:
      return state;
  }
}

function getPortalSettings(state) {
  return _.get(state, ["PortalReducer", "portalSettings"], {});
}

function compareCredentials(auth1, auth2) {
  const _auth1 = { ...auth1 };
  const _auth2 = { ...auth2 };
  if (_auth2.CountryCode === null) {
    _auth2.CountryCode = "All";
  }
  if (_auth1.CountryCode === null) {
    _auth1.CountryCode = "All";
  }
  return _.isEqual(_auth1, _auth2);
}

export { PortalReducer, getPortalSettings };
