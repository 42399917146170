import React from "react";
import { Skeleton } from "antd";
import OrgFormComponent from "../../components/OrgForm/OrgForm";
import * as OrgActions from "../../actions/orgActions";
import {
  getOrgDetails,
  getAllCountries,
  getAuthenticatedCountries,
  getJobStatuses,
  getOrgAryaFeatures,
  getOrgConnectFeatures,
  getOrgList,
  getDefaultJobStatus,
  getAccountTypes,
  getLicenceTypes,
  getOrgConfiguration
} from "../../reducers/orgReducer";
import { connect } from "react-redux";
import { getApiStatus } from "../../reducers/ApiStatusReducer";

const mapStateToProps = state => ({
  orgDetails: getOrgDetails(state),
  orgList: getOrgList(state),
  allCountries: getAllCountries(state),
  authenticatedCountries: getAuthenticatedCountries(state),
  jobStatuses: getJobStatuses(state),
  defaultJobStatus: getDefaultJobStatus(state),
  orgAryaFeatures: getOrgAryaFeatures(state),
  orgConnectFeatures: getOrgConnectFeatures(state),
  accountTypes: getAccountTypes(state),
  licenceTypes: getLicenceTypes(state),
  updateOrgAryaFeaturesApiStatus : getApiStatus(state,'updateOrgAryaFeaturesApiStatus'),
  updateOrgAryaConnectFeaturesApiStatus : getApiStatus(state,'updateOrgAryaConnectFeaturesApiStatus'),
  orgConfiguration: getOrgConfiguration(state),
});

const mapDispatchToProps = {
  // fetch api calls
  fetchOrgDetails: OrgActions.fetchOrgDetails,
  fetchCountries: OrgActions.fetchCountries,
  fetchAuthenticatedCountries: OrgActions.fetchAuthenticatedCountries,
  fetchJobStatuses: OrgActions.fetchJobStatuses,
  fetchOrgAryaFeatures: OrgActions.fetchOrgAryaFeatures,
  fetchOrgConnectFeatures: OrgActions.fetchOrgConnectFeatures,
  fetchOrgConfiguration: OrgActions.fetchOrgConfiguration,
  //update api calls
  updateOrg: OrgActions.updateOrg,
  updateAuthenticatedCountries: OrgActions.updateAuthenticatedCountries,
  updateOrgJobStatusMappings: OrgActions.updateOrgJobStatusMappings,
  updateOrgAryaFeatures: OrgActions.updateOrgAryaFeatures,
  updateOrgConnectFeatures: OrgActions.updateOrgConnectFeatures,
  updateOrgConfiguration: OrgActions.updateOrgConfiguration,
  //clear calls
  clearOrgDetails: OrgActions.clearOrgDetails,
  clearJobStatuses: OrgActions.clearJobStatuses,
  clearAuthenticatedCountries: OrgActions.clearAuthenticatedCountries
};

class OrganizationEdit extends React.Component {
  constructor(props) {
    super(props);
    this.updateOrg = this.updateOrg.bind(this);
    this.state = {
      connectFeatureApiInProgress: false
    };
  }

  componentDidMount() {
    const {
      fetchOrgDetails,
      fetchAuthenticatedCountries,
      fetchOrgAryaFeatures,
      match,
      fetchJobStatuses,
      allCountries,
      fetchCountries,
      fetchOrgConnectFeatures,
      orgList,
      fetchOrgConfiguration
    } = this.props;
    let orgGuid = null;
    const { orgId } = match.params;
    const sourceCofigurationQuery = { IsAuthorized: null, IsEnabled: null };
    if (orgList) {
      for (let i = 0; i < orgList.length; i++) {
        if (orgId === orgList[i].Id) {
          orgGuid = orgList[i].Guid;
          break;
        }
      }
    }
    fetchOrgDetails(orgId);
    if (!allCountries) {
      fetchCountries();
    }
    fetchAuthenticatedCountries(orgId);
    fetchJobStatuses(orgId);
    fetchOrgAryaFeatures(orgId);
    if (orgGuid !== null) {
      fetchOrgConnectFeatures(orgGuid);
    }
    fetchOrgConfiguration(orgId, sourceCofigurationQuery);
  }

  componentDidUpdate() {
    const {
      orgDetails,
      fetchOrgConnectFeatures,
      orgConnectFeatures
    } = this.props;
    const { connectFeatureApiInProgress } = this.state;
    if (orgDetails && !orgConnectFeatures && !connectFeatureApiInProgress) {
      this.setState({ connectFeatureApiInProgress: true });
      fetchOrgConnectFeatures(orgDetails.OrgGuid);
    }
  }

  updateOrg(jobStatuses, values) {
    const {
      updateOrg,
      orgDetails,
      allCountries,
      updateAuthenticatedCountries,
      updateOrgJobStatusMappings
    } = this.props;
    const updatedOrg = {};
    Object.keys(values).forEach(key => {
      if (
        typeof values[key] !== "undefined" &&
        key !== "AryaOpenStatuses" &&
        key !== "AryaCloseStatuses" &&
        key !== "CountryRadioCheck" &&
        key !== "IsAtsIntegrated" &&
        key !== "JobStatuses" &&
        key !== "OrgStatus"
      ) {
        updatedOrg[key] = values[key];
      }
    });
    updatedOrg.ThirtyStackRankType = "MIX";
    let countryCodesArray = [];
    if (values["CountryRadioCheck"] === "All") {
      for (let i = 0; i < allCountries.length; i++) {
        countryCodesArray.push(allCountries[i].Iso2Code);
      }
    } else if (values["CountryRadioCheck"] === "Manual") {
      const countryNames = values["AuthenticatedCountries"]; // string values for countries
      for (let i = 0; i < countryNames.length; i++) {
        for (let j = 0; j < allCountries.length; j++) {
          if (countryNames[i] === allCountries[j].Name) {
            countryCodesArray.push(allCountries[i].Iso2Code);
            break;
          }
        }
      }
    }
    if (values["OrgStatus"] === "Active") {
      updatedOrg.IsActive = true;
    } else {
      updatedOrg.IsActive = false;
    }
    if (values["IsAtsIntegrated"] === "No") {
      updatedOrg.OrgType = "WOATS";
    }
    updatedOrg.ThirtyStackRankType = "MIX";
    const countryCodesCollection = {};
    countryCodesCollection.CountryCodes = countryCodesArray;
    const jobStatusesCollection = {};
    jobStatusesCollection.JobStatuses = jobStatuses;
    jobStatusesCollection.DefaultJobStatus = values.DefaultJobStatus;
    
    updateOrg(orgDetails.Id, updatedOrg);
    updateAuthenticatedCountries(orgDetails.Id, countryCodesCollection);
    updateOrgJobStatusMappings(orgDetails.Id, jobStatusesCollection);
  }

  handleAryaFeaturesUpdate = (orgId,aryaFeatures, candidateMatchingJobsMaxRecencyInMonths) => {
    const { updateOrgAryaFeatures, updateOrgConfiguration} = this.props;
    const organizationFeatureCollection = {};
    organizationFeatureCollection.AryaFeatures = aryaFeatures;
    updateOrgAryaFeatures(orgId, organizationFeatureCollection);
    if (
      candidateMatchingJobsMaxRecencyInMonths > 0 ||
      candidateMatchingJobsMaxRecencyInMonths === ""
    ) {
      const monthsCount =
        candidateMatchingJobsMaxRecencyInMonths === ""
          ? -1
          : candidateMatchingJobsMaxRecencyInMonths;
      updateOrgConfiguration(orgId, {
        CandidateMatchingJobsMaxRecencyInMonths: monthsCount,
      });
    }
  }

  handleAryaConnectFeaturesUpdate = (orgGuid,connectFeatures) => {
    const { updateOrgConnectFeatures } = this.props;
    
    const connectFeaturesCollection = {};
    connectFeaturesCollection.ConnectFeatures = connectFeatures;
    updateOrgConnectFeatures(orgGuid, connectFeaturesCollection);
  }

  render() {
    const {
      orgDetails,
      allCountries,
      authenticatedCountries,
      jobStatuses,
      orgAryaFeatures,
      orgConnectFeatures,
      defaultJobStatus,
      licenceTypes,
      accountTypes,
      updateOrgAryaFeaturesApiStatus,
      updateOrgAryaConnectFeaturesApiStatus,
      updateOrgConfiguration,
      orgConfiguration
    } = this.props;
    return (
      <Skeleton
        active
        paragraph={{ rows: 16 }}
        loading={
          orgDetails &&
            allCountries &&
            authenticatedCountries &&
            jobStatuses &&
            orgAryaFeatures &&
            orgConnectFeatures &&
            defaultJobStatus
            ? false
            : true
        }
      >
        <OrgFormComponent
          type="edit"
          onEdit={this.updateOrg}
          orgDetails={orgDetails}
          allCountries={allCountries}
          authenticatedCountries={authenticatedCountries}
          jobStatuses={jobStatuses}
          defaultJobStatus={defaultJobStatus}
          orgAryaFeatures={orgAryaFeatures}
          orgConnectFeatures={orgConnectFeatures}
          accountTypes={accountTypes}
          licenceTypes={licenceTypes}
          isReadOnly 
          updateAryaFeaturesUpdate={this.handleAryaFeaturesUpdate}
          updateAryaConnectFeaturesUpdate={this.handleAryaConnectFeaturesUpdate}
          updateOrgAryaFeaturesApiStatus={updateOrgAryaFeaturesApiStatus}
          updateOrgAryaConnectFeaturesApiStatus={updateOrgAryaConnectFeaturesApiStatus}
          updateOrgConfiguration={updateOrgConfiguration}
          orgConfiguration={orgConfiguration}
        />
      </Skeleton>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationEdit);
