import _ from "lodash";
export default function UserReducer(
  state = {
    userListForPortalConfig: {},
    userListForPortalConfigModal: [],
    userListForPortalAccount: {},
  },
  action
) {
  let newState;
  switch (action.type) {
    case "SET_USER_LIST": {
      const { users } = action.payload;
      return { ...state, userList: users };
    }
    case "SET_USER_DETAILS": {
      newState = _.cloneDeep(state);
      newState.userDetails = action.payload;
      return newState;
    }
    case "SET_USER_LIST_FOR_PORTAL_CONFIG": {
      const { users } = action.payload;
      const userIds = users.map((user) => user.Id);
      const usersById = {
        ...state.userListForPortalConfig,
      };
      userIds.forEach((user, index) => {
        usersById[user] = {
          ...users[index],
        };
      });
      return {
        ...state,
        userListForPortalConfig: {
          ...usersById,
        },
      };
    }
    case "SET_USER_LIST_FOR_PORTAL_CONFIG_MODAL": {
      let userIdsForModal = [...state.userListForPortalConfigModal];
      const { users } = action.payload;
      if (action.payload.page === 1) {
        userIdsForModal = [];
      }
      userIdsForModal.push(...users.map((user) => user.Id));
      return { ...state, userListForPortalConfigModal: userIdsForModal };
    }
    case "SET_TOTAL_USERS_COUNT": {
      const { totalCount } = action.payload;
      return {
        ...state,
        totalCount,
      };
    }
    case "SET_MAX_COUNT": {
      const { maxCount } = action.payload;
      return {
        ...state,
        maxCount,
      };
    }
    case "SET_BULK_USER_CONFIG": {
      const { userConfigByIds } = action.payload;
      return { ...state, userConfiguration: userConfigByIds };
    }
    case "UPDATE_BULK_USER_CONFIG": {
      const { userConfigByIds } = action.payload;
      const newUserConfig = { ...state.userConfiguration, ...userConfigByIds };
      return { ...state, userConfiguration: newUserConfig };
    }
    case "SET_BULK_USER_CONFIG_API_STATUS": {
      const { status } = action.payload;
      return {
        ...state,
        bulkUserConfigApiStatus: status,
      };
    }
    case "SET_USER_CREATION_STATUS": {
      newState = _.cloneDeep(state);
      newState.userCreationStatus = action.payload;
      return newState;
    }

    case "SET_USER_UPDATE_STATUS": {
      newState = _.cloneDeep(state);
      newState.userUpdateStatus = action.payload;
      return newState;
    }
    case "SET_ARYA_USER_FEATURES": {
      newState = _.cloneDeep(state);
      newState.aryaUserFeatures = action.payload;
      return newState;
    }
    case "SET_CONNECT_USER_FEATURES": {
      newState = _.cloneDeep(state);
      newState.connectUserFeatures = action.payload;
      return newState;
    }
    case "SET_USER_CALLER_IDS": {
      newState = _.cloneDeep(state);
      newState.userCallerIds = action.payload;
      return newState;
    }
    case "SET_USERS_FOR_PORTAL_ACCOUNT": {
      const { users } = action.payload;
      const usersById = users.map((user) => {
        return { [user.Id]: user };
      });
      return {
        ...state,
        userListForPortalAccount: Object.assign(
          {},
          state.userListForPortalAccount,
          ...usersById
        ),
      };
    }
    case "SET_ALL_USER_IDS": {
      const { userIds } = action.payload;
      return {
        ...state,
        allUserIds: userIds,
      };
    }
    case "SET_LOCATIONS":
      newState = _.cloneDeep(state);
      newState.utilities = _.get(newState, "utilities", {});
      newState.utilities.locations = action.payload;
      return newState;

    case "SET_ZIP_CODES":
      newState = _.cloneDeep(state);
      newState.utilities = _.get(newState, "utilities", {});
      newState.utilities.zipCodes = action.payload;
      return newState;

    default:
      return state;
  }
}

function getUserDetails(state) {
  return state.UserReducer.userDetails;
}

function getUserList(state) {
  return state.UserReducer.userList;
}

function getAryaUserFeatures(state) {
  return state.UserReducer.aryaUserFeatures;
}

function getConnectUserFeatures(state) {
  return state.UserReducer.connectUserFeatures;
}

function getUserCallerIds(state) {
  return state.UserReducer.userCallerIds;
}

function getUserCreationStatus(state) {
  return state.UserReducer.userCreationStatus;
}

function getUserUpdateStatus(state) {
  return state.UserReducer.userUpdateStatus;
}

function getUsersForPortalConfig(state) {
  return _.get(state, ["UserReducer", "userListForPortalConfig"], []);
}

function getUsersForPortalConfigModal(state) {
  return _.get(state, ["UserReducer", "userListForPortalConfigModal"], []);
}

function getUserConfiguration(state) {
  return state.UserReducer.userConfiguration;
}

function getTotalUsersCount(state) {
  return _.get(state, ["UserReducer", "totalCount"]);
}

function getMaxCount(state) {
  return _.get(state, ["UserReducer", "maxCount"]);
}

function getBulkUserConfigApiStatus(state) {
  return _.get(state, ["UserReducer", "bulkUserConfigApiStatus"]);
}

function getUsersForPortalAccount(state) {
  return _.get(state, ["UserReducer", "userListForPortalAccount"]);
}

function getAllUserIds(state) {
  return _.get(state, ["UserReducer", "allUserIds"], []);
}

function getUserUtilities(state) {
  return _.get(state, ["UserReducer", "utilities"], {});
}

export {
  UserReducer,
  getUserCreationStatus,
  getUserUpdateStatus,
  getUserDetails,
  getUserList,
  getAryaUserFeatures,
  getConnectUserFeatures,
  getUserCallerIds,
  getUsersForPortalConfig,
  getUsersForPortalConfigModal,
  getUserConfiguration,
  getTotalUsersCount,
  getMaxCount,
  getBulkUserConfigApiStatus,
  getUsersForPortalAccount,
  getAllUserIds,
  getUserUtilities,
};
