import React from "react";
import {
  Row,
  Col,
  Dropdown,
  Button,
  Icon,
  Menu,
  Popover,
  Badge,
  Tooltip,
} from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { getSourceImage } from "../../utils/SourceUtils";
import { getAccountName } from "../../utils/PortalUtils";
import "./OrgListElement.scss";

export default class OrgListElement extends React.Component {
  getPortalIcons = () => {
    const { enabledPortals } = this.props;
    const portalIcons = enabledPortals?.map((portal) => {
      return (
        <Tooltip title={` ${portal} `} key={portal}>
          <Badge className="portal-icon-badge">
            {getSourceImage(portal, { fontSize: 32, verticalAlign: "middle" })}
          </Badge>
        </Tooltip>
      );
    });
    return <div>{portalIcons}</div>;
  };
  render() {
    const { org, orgStat, plan, accountTypes } = this.props;
    const moreMenu = () => (
      <Menu onClick={() => null}>
        <Menu.Item key="edit">
          <Link to={`/orgs/${org.Id}/edit`}>Edit</Link>
        </Menu.Item>
        {/* <Menu.Item key="copy" onClick={() => null}>
          Add Credits
        </Menu.Item> */}
      </Menu>
    );
    const accountName = getAccountName(org?.AccountTypeId, accountTypes);
    return (
      <Row className="job-card">
        <Col xs={24} sm={4}>
          <Link to={{ pathname: `/orgs/${org?.Id}` }}>
            <div className="job-title-wrapper">
              <div title={org?.Name} className="job-title">
                {org?.Name}{" "}
              </div>
              <span className="pilotact">{accountName}</span>
            </div>

            <div className="job-id-time">
              <div className="job-id" display="inline">
                {org?.Id}
              </div>
              <div className="job-id-time-dot" />
              <div className="job-time">
                {" "}
                {moment.utc(org?.CreatedDate).local().fromNow()}
              </div>
            </div>
          </Link>
        </Col>
        <Col xs={24} sm={2}>
          <div className="org-licences">{orgStat?.Licenses}</div>
        </Col>
        <Col xs={24} sm={2}>
          <div className="org-portals">
            <Popover
              content={this.getPortalIcons()}
              placement="top"
              trigger="hover"
              className="org-portals-popover"
            >
              {orgStat?.Portals}
            </Popover>
          </div>
        </Col>
        <Col xs={24} sm={2}>
          <div className="org-ats">{orgStat?.Vaults}</div>
        </Col>
        <Col xs={24} sm={2}>
          <div className="org-ats">{orgStat?.Clients}</div>
        </Col>
        <Col xs={24} sm={3}>
          <div className="org-credit">
            {_.get(plan, "AvailableCredits", "NA")}
          </div>
        </Col>
        <Col xs={24} sm={2}>
          {moment.utc(org?.SubscriptionExpiry).local().fromNow()}
        </Col>
        <Col xs={24} sm={3}>
          <div className="org-active">
            {orgStat?.ActivatedJobs}/{orgStat?.TotalJobs}
          </div>
        </Col>
        <Col xs={24} sm={2}>
          <div className="org-status-change">
            {org?.IsActive ? "Active" : "InActive"}
          </div>
          {/* <Select
            size="small"
            className="job-status-change-filter"
            dropdownClassName="job-status-change-filter-dropdown  floating-menu-popup"
            defaultValue={org.IsActive ? "Active" : "InActive"}
          >
            <Option value={"Active"}>Active</Option>
            <Option value={"InActive"}>InActive</Option>
          </Select> */}
        </Col>

        <Col xs={24} sm={2} className="actbtn-padding">
          <Dropdown overlay={() => moreMenu()} placement="bottomRight">
            <Button className="more">
              <Icon style={{ transform: "rotate(90deg)" }} type="ellipsis" />
            </Button>
          </Dropdown>
        </Col>
      </Row>
    );
  }
}
