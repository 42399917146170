import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./ConnectDetails.scss";
import * as OrgActions from "../../actions/orgActions";
import {
  getOrgAryaFeatures,
  getOrgConnectFeatures,
  getOrgDetails,
  getAudioServiceProviders,
  getMessageServiceProviders
} from "../../reducers/orgReducer";
import ConnectFeatures from "../../components/ConnectDetails/ConnectFeatures";
import AudioServiceProvider from "../../components/ConnectDetails/AudioServiceProvider";
import MessageServiceProvider from "../../components/ConnectDetails/MessageServiceProvider";

const mapStateToProps = state => ({
  orgAryaFeatures: getOrgAryaFeatures(state),
  orgConnectFeatures: getOrgConnectFeatures(state),
  orgDetails: getOrgDetails(state),
  audioServiceProviders: getAudioServiceProviders(state),
  messageServiceProviders: getMessageServiceProviders(state)
});

const mapDispatchToProps = {
  updateOrgConnectFeatures: OrgActions.updateOrgConnectFeatures,
  updateServiceProvider: OrgActions.updateServiceProvider,
  fetchMessageServiceProviders: OrgActions.fetchMessageServiceProviders,
  fetchAudioServiceProviders: OrgActions.fetchAudioServiceProviders,
  clearAudioServiceProviders: OrgActions.clearAudioServiceProviders,
  clearMessageServiceProviders: OrgActions.clearMessageServiceProviders
};

class ConnectDetails extends React.Component {
  componentDidMount() {
    const {
      orgDetails,
      fetchAudioServiceProviders,
      fetchMessageServiceProviders
    } = this.props;
    fetchAudioServiceProviders(orgDetails.OrgGuid);
    fetchMessageServiceProviders(orgDetails.OrgGuid);
  }

  componentWillUnmount() {
    const {
      clearAudioServiceProviders,
      clearMessageServiceProviders
    } = this.props;
    clearAudioServiceProviders();
    clearMessageServiceProviders();
  }

  getSubscribedServiceProvider = serviceProviders => {
    for (let i = 0; i < serviceProviders.length; i++) {
      if (serviceProviders[i].IsSubscribed) {
        return serviceProviders[i];
      }
    }
  };

  render() {
    const {
      orgAryaFeatures,
      orgConnectFeatures,
      updateOrgConnectFeatures,
      orgDetails,
      updateServiceProvider,
      audioServiceProviders,
      messageServiceProviders
    } = this.props;
    return (
      <div>
        <ConnectFeatures
          orgAryaFeatures={orgAryaFeatures}
          orgConnectFeatures={orgConnectFeatures}
          updateOrgConnectFeatures={updateOrgConnectFeatures}
          orgDetails={orgDetails}
        />
        <AudioServiceProvider
          updateAudioServiceProvider={updateServiceProvider}
          orgDetails={orgDetails}
          audioServiceProviders={audioServiceProviders || []}
          getSubscribedProvider={this.getSubscribedServiceProvider}
        />
        <MessageServiceProvider
          updateMessageServiceProvider={updateServiceProvider}
          orgDetails={orgDetails}
          messageServiceProviders={messageServiceProviders || []}
          getSubscribedProvider={this.getSubscribedServiceProvider}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConnectDetails)
);

export { ConnectDetails as ConnectDetailsWithoutRouter };
