import React from "react";
import { Button, Radio } from "antd";

export default class SourcingBehaviourType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaveEnabled: false,
      stackRankType: ""
    };
    this.onSave = this.onSave.bind(this);
    this.onSourcingTypeChange = this.onSourcingTypeChange.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { sourcingType } = props;
    if (!state.stackRankType) {
      return {
        stackRankType: sourcingType
      };
    }
    return null;
  }

  onSourcingTypeChange(event) {
    const sourcingType = event.target.value;
    this.setState({
      stackRankType: sourcingType,
      isSaveEnabled: true
    });
  }

  onSave() {
    const { onSourcingTypeSave } = this.props;
    const { stackRankType } = this.state;
    if (onSourcingTypeSave) {
      onSourcingTypeSave(stackRankType);
    }
    this.setState({
      isSaveEnabled: false
    });
  }

  render() {
    const { stackRankType, isSaveEnabled } = this.state;
    return (
      <div className="sourcelimit-section">
        <div className="security-section">
          <Button
            className="sourcing-behaviour-save-button"
            type="primary"
            shape="round"
            disabled={!isSaveEnabled}
            onClick={this.onSave}
          >
            Save changes
          </Button>
          <div className="contact-type-options">
            <div className="contact-options-heading">Sourcing Behavior</div>

            <Radio.Group
              value={stackRankType}
              onChange={this.onSourcingTypeChange}
            >
              <Radio value="Rank" className="radio-contact-option">
                Source candidates based on Rank
              </Radio>
              <Radio value="Mix" className="radio-contact-option">
                Source candidates from all the sources equally
              </Radio>
            </Radio.Group>
          </div>
        </div>
      </div>
    );
  }
}
