import React from "react";
import _ from "lodash";
import { Form, InputNumber } from "antd";
import { validateNumber } from "../../utils/FieldValidators";

const FormItem = Form.Item;
const AddCreditDetails = (props) => {
  const { form, plan } = props;
  const { getFieldDecorator } = form;
  return (
    <div>
      <p className="creitmoney">{plan?.AvailableCredits ?? ""}</p>
      <p className="creitlbl">Credits Remaining</p>
      <div className="add-more-credits">
        <FormItem label="Add more credits" colon={false} required={true}>
          {getFieldDecorator("Credits", {
            rules: [
              {
                required: true,
                message: "Credits are required",
              },
              {
                validator: validateNumber,
              },
            ],
            initialValue: 0,
          })(<InputNumber placeholder="Enter Credits" />)}
        </FormItem>
      </div>
    </div>
  );
};

export default AddCreditDetails;
