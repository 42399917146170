import * as orgRepository from "../repository/orgRespository";
import { message } from "antd";
import _ from "lodash";
import {
  setSourceConfig,
  updateSourceConfigRow,
  setFetchOrgConfigStatus,
  setEligibleSources,
  setEligibleSourcesStatus,
  setOrgTrackingInfoStatus,
  setLicenceTypes,
  setAccountTypes,
} from "./ActionsCreators/OrgActionCreator";
import { fetchSourceConfigPayload, getAccountName } from "../utils/PortalUtils";
import {
  getAccountTypes,
  getOrgAryaFeatures,
  getOrgConnectFeatures,
} from "../reducers/orgReducer";
import { saveBase64AsFile } from "../utils/FileUtils";
import { setApiStatus } from "./ActionsCreators/ApiStatusActionCreator";
import { getProductVariant } from "../utils/ProductVariantDetails";

message.config({
  top: 100,
  duration: 10,
  maxCount: 1,
});

function clearOrgList() {
  return (dispatch) => {
    dispatch({
      type: "SET_ORG_LIST",
      payload: null,
    });
  };
}

function clearOrgStats() {
  return (dispatch) => {
    dispatch({
      type: "SET_ORG_STATS",
      payload: null,
    });
  };
}

function clearPlanList() {
  return (dispatch) => {
    dispatch({
      type: "SET_PLAN_LIST",
      payload: null,
    });
  };
}

function clearOrgDetails() {
  return (dispatch) => {
    dispatch({
      type: "SET_ORG_DETAILS",
      payload: null,
    });
  };
}

function clearJobStatuses() {
  return (dispatch) => {
    dispatch({
      type: "SET_JOB_STATUSES",
      payload: null,
    });
  };
}

function clearAuthenticatedCountries() {
  return (dispatch) => {
    dispatch({
      type: "SET_AUTH_COUNTRIES",
      payload: null,
    });
  };
}

function createBotContextForOrg(orgGuid) {
  return (dispatch) => {
    const orgBotContextDetails = getBotContextDetails(orgGuid);
    orgRepository.createBotContextForOrg(orgBotContextDetails).catch((err) => {
      message.error("Failed to create bot context");
    });
  };
}

function createOrg(orgDetails, redirectOptions) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const orgCreationResponse = await orgRepository.createOrg(orgDetails);
      const payload = orgCreationResponse.data;
      const orgId = payload.Id;
      const orgGuid = payload.OrgGuid;
      const accountTypes = getAccountTypes(state);
      const accountType = getAccountName(payload.AccountTypeId, accountTypes);
      if (accountType?.toLowerCase() !== "aryapulse") {
        Promise.all([
          orgRepository.updateAuthenticatedCountries(
            orgId,
            orgDetails.countryCodesCollection
          ),
          orgRepository.updateOrgJobStatusMappings(
            orgId,
            orgDetails.JobStatusesCollection
          ),
          orgRepository.upsertConnectOrg(orgGuid, orgDetails),
          orgRepository.upsertPayOrg(orgGuid, orgDetails),
        ]).then(() => {
          dispatch({ type: "SET_ORG_DETAILS", payload: payload });
        });
      } else {
        dispatch({ type: "SET_ORG_DETAILS", payload: payload });
      }
      message.success("Organization created successfully");
      if (redirectOptions) {
        redirectOptions.history.push(redirectOptions.redirectUrl);
      }
    } catch (err) {
      dispatch({ type: "SET_ORG_DETAILS", payload: null });
      if (err.response.status === 409) {
        message.error("An organization with the same name already exists");
      } else if (err.response.status === 403) {
        message.error("You are not authorized to create an organization.");
      } else {
        message.error("Failed to create Organization. Please try again");
      }
    }
  };
}

function updateOrg(orgId, orgDetails) {
  return async (dispatch) => {
    try {
      const updateOrgResponse = await orgRepository.updateOrg(
        orgId,
        orgDetails
      );
      const orgGuid = updateOrgResponse.data.OrgGuid;
      Promise.all([
        orgRepository.upsertConnectOrg(orgGuid, orgDetails),
        orgRepository.upsertPayOrg(orgGuid, orgDetails),
      ]).then(() => {
        message.success("Organization updated successfullly");
        window.location.pathname = `/orgs`;
      });
    } catch (err) {
      message.error("Organization update failed");
    }
  };
}

function collectOrgIds(organizations) {
  const orgIdsCollection = {};
  let orgIds = [];
  for (let i = 0; i < organizations.length; i++) {
    orgIds.push(organizations[i].Id);
  }
  orgIdsCollection.OrganizationIds = orgIds;
  return orgIdsCollection;
}

function collectOrgGuids(organizations) {
  const orgGuidsCollection = {};
  let orgGuids = [];
  for (let i = 0; i < organizations.length; i++) {
    orgGuids.push(organizations[i].Guid);
  }
  orgGuidsCollection.GroupIds = orgGuids;
  return orgGuidsCollection;
}

const setBulkOrgConfig = (configuration) => ({
  type: "SET_BULK_ORG_CONFIG",
  payload: { orgConfigByIds: configuration },
});

function fetchOrgList(filter) {
  return (dispatch) => {
    orgRepository
      .fetchOrgList(filter)
      .then((response) => {
        const orgs = response.data.Organizations;
        dispatch({
          type: "SET_ORG_LIST",
          payload: orgs,
        });
        const totalOrgs = response.data.Total;
        dispatch({
          type: "SET_ORG_COUNT",
          payload: totalOrgs,
        });
        const orgIdsCollection = collectOrgIds(orgs);
        orgRepository
          .fetchOrgStats(orgIdsCollection)
          .then((res) => {
            dispatch({
              type: "SET_ORG_STATS",
              payload: res.data,
            });
          })
          .catch(() => {
            message.error("Failed to fetch the organization stats");
          });
        orgRepository
          .fetchBulkOrgConfiguration(orgIdsCollection)
          .then((response) => {
            dispatch(setBulkOrgConfig(response.data));
          });
        const orgGuidsCollection = collectOrgGuids(orgs);
        orgRepository
          .fetchPlanList(orgGuidsCollection)
          .then((res) => {
            dispatch({
              type: "SET_PLAN_LIST",
              payload: res.data,
            });
          })
          .catch((err) => {
            dispatch({
              type: "SET_PLAN_LIST",
              payload: null,
            });
            if (err.response.status === 403) {
              message.error("Unauthorized request");
            } else if (err.response.status === 400) {
              message.error("Invalid request");
            } else {
              message.error("Failed to fetch the plan list");
            }
          });
      })
      .catch(() => {
        message.error("Failed to fetch the organization list");
        dispatch({
          type: "SET_ORG_LIST",
          payload: null,
        });
      });
  };
}

function fetchOrgPlan(orgGuidsCollection) {
  return (dispatch) => {
    orgRepository
      .fetchPlanList(orgGuidsCollection)
      .then((response) => {
        const payload = response.data;
        if (orgGuidsCollection?.ProductVariantIds) {
          const currentOrgProductVariantDetails =
            payload?.[orgGuidsCollection.GroupIds[0].toLowerCase()];
          dispatch({
            type: "SET_PRODUCTVARIANT_DETAILS",
            payload: currentOrgProductVariantDetails,
          });
        } else {
          dispatch({
            type: "SET_PLAN_LIST",
            payload,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "SET_PLAN_LIST",
          payload: null,
        });
        if (err.response.status === 403) {
          message.error("Unauthorized request");
        } else if (err.response.status === 400) {
          message.error("Invalid request");
        } else {
          message.error("Failed to fetch the organization plan");
        }
      });
  };
}

function fetchOrgStat(orgIdsCollection) {
  return (dispatch) => {
    orgRepository
      .fetchOrgStats(orgIdsCollection)
      .then((response) => {
        const payload = response.data;
        dispatch({
          type: "SET_ORG_STAT",
          payload,
        });
      })
      .catch(() => {
        message.error("Failed to fetch the organization stat");
        dispatch({
          type: "SET_ORG_STAT",
          payload: null,
        });
      });
  };
}

function fetchOrgDetails(orgId) {
  return (dispatch) => {
    orgRepository
      .fetchOrgDetails(orgId)
      .then((response) => {
        const payload = response.data;
        dispatch({
          type: "SET_ORG_DETAILS",
          payload,
        });
      })
      .catch(() => {
        dispatch({
          type: "SET_ORG_DETAILS",
          payload: null,
        });
      });
  };
}

function fetchTestPixelJobUrls(clientId) {
  return async () => {
    try {
      const response = await orgRepository.fetchTestPixelJobUrls(clientId);
      return response.data;
    } catch (err) {
      message.error("Failed to fetch test pixel job urls");
    }
  };
}

function fetchCareerPortalClient(orgId) {
  return async () => {
    try {
      const response = await orgRepository.fetchCareerPortalClient(orgId);
      return response.data;
    } catch (err) {
      message.error("Failed to fetch career portal client");
    }
  };
}

function fetchClickDetails(clickId) {
  return async () => {
    try {
      const response = await orgRepository.fetchClickDetails(clickId);
      return response.data;
    } catch (err) {
      message.error("Failed to fetch click details");
    }
  };
}

function fetchCountries() {
  return (dispatch) => {
    orgRepository
      .getCountries()
      .then((response) => {
        const payload = response.data;
        dispatch({
          type: "SET_ALL_COUNTRIES",
          payload,
        });
      })
      .catch(() => {
        dispatch({
          type: "SET_ALL_COUNTRIES",
          payload: null,
        });
      });
  };
}

function fetchAuthenticatedCountries(orgId) {
  return (dispatch) => {
    orgRepository
      .getAuthenticatedCountries(orgId)
      .then((response) => {
        const payload = response.data;
        dispatch({
          type: "SET_AUTH_COUNTRIES",
          payload,
        });
      })
      .catch(() => {
        dispatch({
          type: "SET_AUTH_COUNTRIES",
          payload: null,
        });
      });
  };
}

function fetchJobStatuses(orgId) {
  return (dispatch) => {
    orgRepository
      .fetchJobStatuses(orgId)
      .then((response) => {
        let jobStatuses = _.get(response, ["data", "JobStatuses"], []);
        const defaultStatusId = _.get(response, ["data", "DefaultStatusId"], 0);
        let defaultJobStatus = null;
        dispatch({
          type: "SET_JOB_STATUSES",
          payload: jobStatuses,
        });
        if (jobStatuses && jobStatuses.length > 0) {
          for (let i = 0; i < jobStatuses.length; i++) {
            if (jobStatuses[i].Id === defaultStatusId) {
              defaultJobStatus = jobStatuses[i].Name;
              break;
            }
          }
          dispatch({
            type: "SET_DEFAULT_JOB_STATUS",
            payload: defaultJobStatus,
          });
        }
      })
      .catch(() => {
        dispatch({
          type: "SET_JOB_STATUSES",
          payload: null,
        });
      });
  };
}

function fetchOrgAryaFeatures(orgId) {
  return (dispatch) => {
    orgRepository
      .fetchOrgAryaFeatures(orgId)
      .then((response) => {
        let payload = response.data;
        dispatch({
          type: "SET_ORG_ARYA_FEATURES",
          payload,
        });
      })
      .catch(() => {
        dispatch({
          type: "SET_ORG_ARYA_FEATURES",
          payload: null,
        });
      });
  };
}

function fetchOrgConnectFeatures(orgGuid) {
  return (dispatch) => {
    orgRepository
      .fetchOrgConnectFeatures(orgGuid)
      .then((response) => {
        let payload = response.data;
        dispatch({
          type: "SET_ORG_CONNECT_FEATURES",
          payload,
        });
      })
      .catch(() => {
        dispatch({
          type: "SET_ORG_CONNECT_FEATURES",
          payload: null,
        });
      });
  };
}

function fetchAvailableAryaFeatures() {
  return (dispatch) => {
    orgRepository
      .fetchAvailableAryaFeatures()
      .then((response) => {
        let payload = response.data;
        dispatch({
          type: "SET_AVAILABLE_ARYA_FEATURES",
          payload,
        });
      })
      .catch(() => {
        dispatch({
          type: "SET_AVAILABLE_ARYA_FEATURES",
          payload: null,
        });
      });
  };
}

function fetchAvailableConnectFeatures() {
  return (dispatch) => {
    orgRepository
      .fetchAvailableConnectFeatures()
      .then((response) => {
        let payload = response.data;
        dispatch({
          type: "SET_AVAILABLE_CONNECT_FEATURES",
          payload,
        });
      })
      .catch(() => {
        dispatch({
          type: "SET_AVAILABLE_CONNECT_FEATURES",
          payload: null,
        });
      });
  };
}

function updateAuthenticatedCountries(orgId, countryCodesCollection) {
  return (dispatch) => {
    orgRepository
      .updateAuthenticatedCountries(orgId, countryCodesCollection)
      .then((response) => {
        let payload = response.data;
      })
      .catch(() => { });
  };
}

function updateOrgJobStatusMappings(orgId, jobStatusesCollection) {
  return (dispatch) => {
    orgRepository
      .updateOrgJobStatusMappings(orgId, jobStatusesCollection)
      .then((response) => {
        let payload = response.data;
      })
      .catch(() => { });
  };
}

function updateOrgAryaFeatures(orgId, aryaFeaturesCollection) {
  return async (dispatch, getState) => {
    const orgAryaFeatures = getOrgAryaFeatures(getState());
    const updatedOrgFeatures = {
      AryaFeatures: _.differenceWith(
        aryaFeaturesCollection.AryaFeatures,
        orgAryaFeatures,
        _.isEqual
      ),
    };
    try {
      dispatch(setApiStatus("updateOrgAryaFeaturesApiStatus", "PENDING"));
      await orgRepository.updateOrgAryaFeatures(orgId, updatedOrgFeatures);
      dispatch({
        type: "SET_ORG_ARYA_FEATURES",
        payload: aryaFeaturesCollection.AryaFeatures,
      });
      dispatch(setApiStatus("updateOrgAryaFeaturesApiStatus", "SUCCESS"));
    } catch (e) {
      dispatch(setApiStatus("updateOrgAryaFeaturesApiStatus", "FAILED"));
    }
  };
}

function updateOrgConnectFeatures(orgGuid, ConnectFeaturesCollection) {
  return async (dispatch, getState) => {
    const orgAryaConnectFeatures = getOrgConnectFeatures(getState());
    const updatedOrgConnectFeatures = {
      ConnectFeatures: _.differenceWith(
        ConnectFeaturesCollection.ConnectFeatures,
        orgAryaConnectFeatures,
        _.isEqual
      ),
    };
    try {
      dispatch(
        setApiStatus("updateOrgAryaConnectFeaturesApiStatus", "PENDING")
      );
      await orgRepository.updateOrgConnectFeatures(
        orgGuid,
        updatedOrgConnectFeatures
      );
      dispatch({
        type: "SET_ORG_CONNECT_FEATURES",
        payload: ConnectFeaturesCollection.ConnectFeatures,
      });
      dispatch(
        setApiStatus("updateOrgAryaConnectFeaturesApiStatus", "SUCCESS")
      );
      message.success("Updated sucessfully");
    } catch (e) {
      dispatch(setApiStatus("updateOrgAryaConnectFeaturesApiStatus", "FAILED"));
      message.error("Failed to update org connect features");
    }
  };
}

function fetchAudioServiceProviders(orgGuid) {
  const searchQuery = {
    Type: "Audio",
  };
  return (dispatch) => {
    orgRepository
      .fetchServiceProviders(orgGuid, searchQuery)
      .then((response) => {
        dispatch({
          type: "SET_AUDIO_SERVICE_PROVIDERS",
          payload: response.data.Providers,
        });
      })
      .catch(() => {
        message.error("Failed to fetch service providers");
      });
  };
}

function fetchMessageServiceProviders(orgGuid) {
  const searchQuery = {
    Type: "Message",
  };
  return (dispatch) => {
    orgRepository
      .fetchServiceProviders(orgGuid, searchQuery)
      .then((response) => {
        dispatch({
          type: "SET_MESSAGE_SERVICE_PROVIDERS",
          payload: response.data.Providers,
        });
      })
      .catch(() => {
        message.error("Failed to fetch service providers");
      });
  };
}

function clearAudioServiceProviders() {
  return (dispatch) => {
    dispatch({
      type: "SET_AUDIO_SERVICE_PROVIDERS",
      payload: null,
    });
  };
}

function clearMessageServiceProviders() {
  return (dispatch) => {
    dispatch({
      type: "SET_MESSAGE_SERVICE_PROVIDERS",
      payload: null,
    });
  };
}

function updateServiceProvider(orgGuid, subscribeQuery, type) {
  return (dispatch) => {
    return orgRepository
      .updateServiceProvider(orgGuid, subscribeQuery)
      .then(() => {
        if (type === "audio") {
          dispatch(fetchAudioServiceProviders(orgGuid));
        } else if (type === "message") {
          dispatch(fetchMessageServiceProviders(orgGuid));
        }
        message.success("Updated successfully");
      })
      .catch(() => {
        message.error("Failed to update service provider");
      });
  };
}

function fetchOrgConfiguration(orgId, sourceCofigurationQuery) {
  return (dispatch) => {
    dispatch(setFetchOrgConfigStatus("PENDING"));
    orgRepository
      .fetchOrgConfiguration(orgId, sourceCofigurationQuery)
      .then((response) => {
        const payload = response.data;
        dispatch({
          type: "SET_ORG_CONFIG",
          payload,
        });
        dispatch(setFetchOrgConfigStatus("SUCCESS"));
      })
      .catch(() => {
        dispatch({
          type: "SET_ORG_CONFIG",
          payload: null,
        });
        dispatch(setFetchOrgConfigStatus("FAILED"));
      });
  };
}

function updateOrgConfiguration(orgId, orgConfiguration) {
  const sourceConfigurationQuery = { IsAuthorized: null, IsEnabled: null };
  return (dispatch) => {
    return orgRepository
      .updateOrgConfiguration(orgId, orgConfiguration)
      .then((response) => {
        dispatch(fetchOrgConfiguration(orgId, sourceConfigurationQuery));
        message.success("Updated successfully");
      })
      .catch(() => {
        message.error("Failed to update org configuration");
      });
  };
}

function handleSmsPlan(guidsCollection, dispatch) {
  const productVariant = getProductVariant("SMS");
  if (productVariant) {
    const payloadForSmsPlan = _.cloneDeep(guidsCollection);
    payloadForSmsPlan.ProductVariantIds = productVariant;
    dispatch(fetchOrgPlan(payloadForSmsPlan));
  }
}

function createPlan(plan, orgGuid) {
  const guidsCollection = {};
  guidsCollection.GroupIds = [orgGuid];
  return (dispatch) => {
    orgRepository
      .createPlan(plan)
      .then((response) => {
        dispatch(fetchOrgPlan(guidsCollection));
        handleSmsPlan(guidsCollection, dispatch);
        message.success("Plan created successfully");
      })

      .catch((err) => {
        if (err.response.status === 403) {
          message.error("You are not authorized to create plan");
        } else if (err.response.status === 400) {
          message.error("Invalid request");
        } else {
          message.error("Failed to create plan");
        }
      });
  };
}

// function fetchPlan(planId) {
//   return dispatch => {
//     orgRepository
//       .fetchPlan(planId)
//       .then(response => {
//         const payload = response.data;
//         dispatch({
//           type: "SET_PLAN_DETAIL",
//           payload
//         });
//       })
//       .catch(() => {
//         message.error("Failed to fetch plan");
//       });
//   };
// }

function updatePlan(planId, plan, orgGuid) {
  const guidsCollection = {};
  guidsCollection.GroupIds = [orgGuid];
  return (dispatch) => {
    orgRepository
      .updatePlan(planId, plan)
      .then((response) => {
        dispatch(fetchOrgPlan(guidsCollection));
        handleSmsPlan(guidsCollection, dispatch);
        message.success("Plan updated successfully");
      })
      .catch((err) => {
        if (!err || !err.response) {
          message.error("Failed to update plan");
        } else if (err.response.status === 403) {
          message.error("You are not authorized to update plan");
        } else if (err.response.status === 400) {
          message.error("Invalid request");
        } else {
          message.error("Failed to update plan");
        }
      });
  };
}

function addCredits(paymentCommand, orgGuid) {
  const guidsCollection = {};
  guidsCollection.GroupIds = [orgGuid];
  return (dispatch) => {
    return orgRepository
      .addCredits(paymentCommand)
      .then((response) => {
        dispatch(fetchOrgPlan(guidsCollection));
        handleSmsPlan(guidsCollection, dispatch);
        message.success("Credits added successfully");
      })
      .catch((err) => {
        if (err.response.status === 403) {
          message.error("You are not authorized to add credits");
        } else if (err.response.status === 400) {
          message.error("Invalid request");
        } else {
          message.error("Failed to add credits");
        }
      });
  };
}

function getBotContextDetails(orgGuid) {
  const botContext = {
    ContextEntityId: orgGuid,
    Type: "Org",
    Questions: [
      {
        Question: "What is the best number to call you?",
        ExpectedAnswer: "PhoneNumber",
        CategoryName: "Call",
        Order: 1,
      },
      {
        Question: "What is the best time to call you?",
        ExpectedAnswer: "Appointment",
        CategoryName: "Appointment",
        Order: 2,
      },
    ],
  };
  return botContext;
}

function addJobCredits(payload) {
  return (dispatch) => {
    orgRepository
      .addJobCredits(payload)
      .then((response) => {
        message.success("job credits add successfully");
      })
      .catch((err) => {
        if (!err || !err.response) {
          message.error("Failed to add job credits");
        } else if (err.response.status === 403) {
          message.error("You are not authorized to add job credits");
        } else if (err.response.status === 400) {
          message.error("Invalid request");
        } else {
          message.error("Failed to add job credits");
        }
      });
  };
}

function updateSourceConfig(payload) {
  const newPayload = fetchSourceConfigPayload(payload);

  return async (dispatch) => {
    dispatch(updateSourceConfigRow({ ...newPayload, status: "PENDING" }));
    try {
      await orgRepository.setSourceConfig(newPayload);
      dispatch(setSourceConfig(newPayload));
      dispatch(updateSourceConfigRow({ ...newPayload, status: "SUCCESS" }));
    } catch (err) {
      message.error("Failed to Update Org configuration");
      dispatch(updateSourceConfigRow({ ...newPayload, status: "FAILED" }));
    }
  };
}

function createSourceConfig(payload) {
  const newPayload = fetchSourceConfigPayload(payload);

  return async (dispatch) => {
    try {
      await orgRepository.setSourceConfig(newPayload);
      dispatch(fetchOrgConfiguration(payload.orgId, null));
    } catch (err) {
      message.error("Failed to Create Org configuration");
    }
  };
}

function getEligibleSourceConfig(acctType) {
  return async (dispatch) => {
    dispatch(setEligibleSourcesStatus("PENDING"));
    try {
      const response = await orgRepository.getEligibleSources(acctType);
      dispatch(setEligibleSources(response.data));
      dispatch(setEligibleSourcesStatus("SUCCESS"));
    } catch (err) {
      message.error("Failed to GET Allowed Sources");
      dispatch(setEligibleSourcesStatus("FAILED"));
    }
  };
}

function fetchAccountTypes() {
  return async (dispatch) => {
    try {
      const response = await orgRepository.getAccountTypes();
      dispatch(setAccountTypes(response.data));
    } catch (err) {
      message.error("Failed to GET Account Types");
    }
  };
}

function fetchLicenceTypes() {
  return async (dispatch) => {
    try {
      const response = await orgRepository.getLicenceTypes();
      dispatch(setLicenceTypes(response.data));
    } catch (err) {
      message.error("Failed to GET Licence Types");
    }
  };
}

function getPendingRequests(orgId) {
  return async (dispatch) => {
    try {
      const responce = await orgRepository.getPendingRequests(orgId);
      dispatch({ type: "SET_PENDING_REQUESTS", payload: responce.data });
    } catch (error) {
      message.error("Failed to get pending requests for requested credits");
    }
  };
}

function changePendingRequestStatus(filter, id) {
  return async () => {
    orgRepository
      .changePendingRequestStatus(filter, id)
      .then((response) => {
        message.success("Request status updated successfully");
      })
      .catch((error) => {
        message.error("Failed to update request status");
      });
  };
}

function configureAOD(orgId, payload) {
  return async () => {
    try {
      await orgRepository.configureAOD(orgId, payload);
      message.success("AOD configured successfully for orgId: " + orgId);
    } catch (err) {
      message.error("Failed to configure AOD");
    }
  };
}

function fetchOrgTrackingInfo(orgId, pixelType) {
  return async (dispatch) => {
    dispatch(setOrgTrackingInfoStatus("PENDING"));
    try {
      const response = await orgRepository.fetchOrgTrackingInfo(
        orgId,
        pixelType
      );
      const { FileName: fileName, FileContentBase64: fileContentBase64 } =
        response.data;
      saveBase64AsFile(fileContentBase64, fileName);
      dispatch(setOrgTrackingInfoStatus("SUCCESS"));
    } catch (err) {
      message.error("failed to download tracking info details");
      dispatch(setOrgTrackingInfoStatus("FAILED"));
    }
  };
}

export {
  createOrg,
  fetchCountries,
  fetchOrgList,
  fetchOrgDetails,
  fetchTestPixelJobUrls,
  fetchClickDetails,
  fetchCareerPortalClient,
  fetchOrgAryaFeatures,
  updateOrg,
  fetchAuthenticatedCountries,
  fetchJobStatuses,
  clearOrgDetails,
  clearJobStatuses,
  clearAuthenticatedCountries,
  updateAuthenticatedCountries,
  updateOrgJobStatusMappings,
  updateOrgAryaFeatures,
  fetchAvailableAryaFeatures,
  fetchOrgConnectFeatures,
  fetchAvailableConnectFeatures,
  updateOrgConnectFeatures,
  updateOrgConfiguration,
  fetchOrgConfiguration,
  clearOrgList,
  clearOrgStats,
  fetchOrgStat,
  createPlan,
  updatePlan,
  addCredits,
  addJobCredits,
  fetchOrgPlan,
  clearPlanList,
  collectOrgGuids,
  fetchAudioServiceProviders,
  fetchMessageServiceProviders,
  updateServiceProvider,
  clearAudioServiceProviders,
  clearMessageServiceProviders,
  createBotContextForOrg,
  updateSourceConfig,
  createSourceConfig,
  getEligibleSourceConfig,
  fetchAccountTypes,
  fetchLicenceTypes,
  getPendingRequests,
  changePendingRequestStatus,
  fetchOrgTrackingInfo,
  configureAOD,
};
