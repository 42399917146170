import React from "react";
import { Input, Icon, Button } from "antd";
import "./ClientListHeader.scss";

export default class ClientListHeader extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        const { openClientDrawer } = this.props;
        openClientDrawer("create", null);
    }
    render() {
        return (
            <div className="client-list-header">
                <div className="client-list-title">Client Accounts</div>
                <div className="user-client-list-controls">
                    <Input.Search
                        placeholder="Yet to be implemented"
                        className="user-client-search"
                    />
                    <Button className="add-job-button" onClick={this.handleClick}>
                        <Icon type="plus-circle" theme="filled" className="add-job-icon" />
                    </Button>
                </div>
            </div>
        )
    }
}