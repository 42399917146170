import _ from "lodash";
import { SET_CONFIG } from "../actions/ActionsCreators/ConfigActionsCreator";

const initialState = {
  UserConfig: {},
};
function ConfigReducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_CONFIG: {
      newState = _.cloneDeep(state);
      newState.UserConfig = { ...action.payload };
      return newState;
    }
    default:
      return state;
  }
}

function getConfig(state) {
  return _.get(state, ["ConfigReducer", "UserConfig"], {});
}

export { ConfigReducer, getConfig };
