import { FETCH_CONFIG_API_STATUS } from "../actions/ActionsCreators/ConfigActionsCreator";
import { SET_FETCH_SOURCE_CONFIG_API_STATUS,SET_ELIGIBLE_SOURCES_API_STATUS, SET_FETCH_ORG_TRACKING_INFO_API_STATUS } from "../actions/ActionsCreators/OrgActionCreator";
import { SET_API_STATUS } from '../actions/ActionsCreators/ApiStatusActionCreator';

const initialState = {};

function ApiStatusReducer(state = initialState, action) {
  switch (action.type) {
    case "FETCH_LOCATION_API_STATUS":
      return {
        ...state,
        locationApiStatus: action.payload,
      };
    case FETCH_CONFIG_API_STATUS:
      return {
        ...state,
        configApiStatus: action.payload,
      };
    case SET_FETCH_SOURCE_CONFIG_API_STATUS:
    return {
      ...state,
      fetchOrgSourceConfigApiStatus: action.payload,
    };
    case SET_ELIGIBLE_SOURCES_API_STATUS:
    return {
      ...state,
      fetchEligibleSourcesApiStatus: action.payload,
    };
    case SET_FETCH_ORG_TRACKING_INFO_API_STATUS:
    return {
      ...state,
      fetchOrgTrackingInfoApiStatus: action.payload,
    };
    case SET_API_STATUS:
    return {
      ...state,
      [action.payload.apiName] : action.payload.status,
    };

    default:
      return state;
  }
}

function getApiStatus(state, apiName) {
  return state.ApiStatusReducer[apiName];
}

export { ApiStatusReducer, getApiStatus };
