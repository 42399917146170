import React from "react";
import { Input, Icon, Button } from "antd";
import "./UserListHeader.scss";

const { Search } = Input;
export default class UserFilter extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { openUserDrawer } = this.props;
    openUserDrawer("create", null);
  }
  render() {
    const { onSearch, onChange } = this.props;
    return (
      <div className="client-list-header">
        <div className="client-list-title">User Accounts</div>
        <div className="user-client-list-controls">
          <Search
            className="user-client-search"
            placeholder="Search"
            size="default"
            onSearch={onSearch}
            onChange={onChange}
            allowClear
          />
          <Button className="add-job-button" onClick={this.handleClick}>
            <Icon type="plus-circle" theme="filled" className="add-job-icon" />
          </Button>
        </div>
      </div>
    );
  }
}
