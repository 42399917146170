import axios from "axios";
import { Auth } from "aws-amplify";
import Config from "../config/config.js";

const apiV3Client = axios.create({ baseURL: Config.apiV3Url });
const connectV1Client = axios.create({ baseURL: Config.urls.aryaConnect });
const payV1Client = axios.create({ baseURL: Config.urls.aryaPay });
const aryaNotifyV1Client = axios.create({ baseURL: Config.urls.aryaNotify });
const smartJobV1Client = axios.create({ baseURL: Config.urls.smartjob });
const careerPortalClient = axios.create({ baseURL: Config.urls.careerPortal });

function axiosInterceptor(config) {
  return Auth.currentSession()
    .then((session) => {
      const { jwtToken: accessToken } = session.getIdToken();
      const { headers } = config;
      headers.Authorization = `Bearer ${accessToken}`;
      headers["x-app-id"] = Config.appId;
      return Promise.resolve(config);
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      return Promise.resolve(config);
    });
}

apiV3Client.interceptors.request.use(axiosInterceptor);
connectV1Client.interceptors.request.use(axiosInterceptor);
payV1Client.interceptors.request.use(axiosInterceptor);
smartJobV1Client.interceptors.response.use(axiosInterceptor);
export {
  apiV3Client,
  connectV1Client,
  payV1Client,
  aryaNotifyV1Client,
  smartJobV1Client,
  careerPortalClient,
};
