import React from "react";
import { withAuthenticator } from "aws-amplify-react";
import AppRouter from "./AppRouter";
import LoginForm from "./components/Auth/LoginForm";

function App() {
  return <AppRouter />;
}

export default withAuthenticator(App, false, [<LoginForm />]);

//export default App;
