import React from 'react';
import { Switch, Tooltip } from 'antd';
import styles from './PortalCardView.module.scss';
import { portalToIcon } from '../../../utils/SourceUtils';

export function PortalCardView(props){

    const { 
        source, 
        updateSourceConfig,
        orgId,
        tabkey
    } = props;
    const sourceId  = source?.Id;
    const portalName = source?.Source?.Portal;
    const isPortalEnabled = source?.IsEnabled;
    const isMutating = source?.IsMutating ?? "SUCCESS";

    const handleOnchange =(value) =>{
        
        const payload ={
            id : sourceId,
            sourceGroup: tabkey,
            portalIds: [source?.PortalId],
            orgId : parseInt(orgId),
            userId: -1,
            IsEnabled: value ? 1:0,
        };
        updateSourceConfig(payload);
    }

    const style = { fontSize: '32px', color: '#08c'};

    return(
        <div className={styles.portalCardView}>
            <div className={styles.portalCardHeader}>
                <div className={styles.portalHeaderLeft}>
                    <div className={styles.togglePortal}>
                        <Switch loading={isMutating !== "SUCCESS"} onChange={handleOnchange} checked={isPortalEnabled}/>
                    </div>
                    <div className={styles.portalLogo}>
                        <Tooltip title={portalName}>
                        <div className={styles.portalLogoBlock}>
                            <div className={styles.portalLogoBlockItem}>{portalToIcon(portalName?.toUpperCase(),style)}</div>
                            <div className={styles.portalLogoBlockItem}>{portalName}</div>
                        </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
    );
}