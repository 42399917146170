import _ from "lodash";
export default function AdminReducer(state = {}, action) {
  let newState;
  switch (action.type) {
    case "ADMIN_LOGIN_SUCCESS": {
      newState = _.cloneDeep(state);
      newState.orgDetails = action.payload;
      return newState;
    }

    case "ADMIN_LOGOUT": {
      localStorage.clear();
      window.location.pathname = "/";
      break;
    }

    default:
      return state;
  }
}

export { AdminReducer };
