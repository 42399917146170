import {
  payV1Client,
  connectV1Client,
  apiV3Client,
  smartJobV1Client,
} from "./baseRepository";

export function createUser(orgId, userDetails) {
  return apiV3Client.post(`organizations/${orgId}/users`, userDetails);
}

export function upsertConnectUser(userGuid, userDetails) {
  const connectUserDetails = extractConnectUserDetails(userGuid, userDetails);
  return connectV1Client.post("users", connectUserDetails);
}

export function upsertAryaPayUser(userGuid, userDetails) {
  const aryaPayUserDetails = extractAryaPayUserDetails(userGuid, userDetails);
  return payV1Client.post("users", aryaPayUserDetails);
}

export function updateUser(userId, userDetails) {
  return apiV3Client.put(`users/${userId}`, userDetails);
}

export function resetUserPassword(userId, passowrdResetPayload) {
  return apiV3Client.put(`users/${userId}/password`, passowrdResetPayload);
}

export function fetchAllUserIds(orgId) {
  return apiV3Client.get(`organizations/${orgId}/user_ids`);
}

export function fetchUserList(query, orgId) {
  return apiV3Client.post(`users/_adminsearch?orgId=${orgId}`, query);
}

export function fetchUserDetails(userId) {
  return apiV3Client.get(`users/${userId}`);
}

export function fetchUserListForPortalConfig(query, orgId) {
  return apiV3Client.post(`users/_adminsearch?orgId=${orgId}`, query);
}

export function fetchBulkUserSourceConfiguration(userIdsCollection) {
  return apiV3Client.post(`users/_config`, {
    UserIds: userIdsCollection,
  });
}

export function updateBulkUserConfiguration(userConfigurationsCollection) {
  return apiV3Client.put(`users/_config`, {
    UsersPortalConfigurations: userConfigurationsCollection,
  });
}

export function fetchAryaUserFeatures(userId) {
  return apiV3Client.get(`users/${userId}/features`);
}

export function fetchConnectUserFeatures(userGuid) {
  return connectV1Client.get(`users/${userGuid}/features`);
}

export function fetchUserCallerIds(userGuid) {
  return connectV1Client.get(`_config/audio/users/${userGuid}`);
}

export function updateUserAryaFeatures(userId, aryaFeaturesCollection) {
  return apiV3Client.put(`users/${userId}/features`, aryaFeaturesCollection);
}

export function updateUserConnectFeatures(userGuid, aryaFeaturesCollection) {
  return connectV1Client.put(
    `users/${userGuid}/features`,
    aryaFeaturesCollection
  );
}

export function updateUserCallerIds(userGuid, userCallerIdCollection) {
  return connectV1Client.put(
    `_config/audio/users/${userGuid}`,
    userCallerIdCollection
  );
}

export function extractConnectUserDetails(userGuid, userDetails) {
  const connectUserDetails = {};
  connectUserDetails.Id = userGuid;
  connectUserDetails.OrgId = userDetails.OrgGuid;
  connectUserDetails.OrgName = userDetails.OrgName;
  connectUserDetails.Email = userDetails.Email;
  connectUserDetails.Phone = userDetails.OfficePhone;
  connectUserDetails.FirstName = userDetails.FirstName;
  connectUserDetails.LastName = userDetails.LastName;
  return connectUserDetails;
}

export function extractAryaPayUserDetails(userGuid, userDetails) {
  const aryaPayUserDetails = {};
  aryaPayUserDetails.UserId = userGuid;
  aryaPayUserDetails.GroupName = userDetails.OrgName;
  aryaPayUserDetails.GroupId = userDetails.OrgGuid;
  aryaPayUserDetails.Email = userDetails.Email;
  aryaPayUserDetails.FirstName = userDetails.FirstName;
  aryaPayUserDetails.LastName = userDetails.LastName;
  return aryaPayUserDetails;
}

export function getLocations(filter) {
  return apiV3Client.post("locations", {
    Country: filter.country,
    SearchTerm: filter.search,
    Size: filter.size || 100,
  });
}

export function getSmartjobLocations(filter) {
  return smartJobV1Client.post("locations", {
    Country: filter.country,
    SearchTerm: filter.search,
    Size: filter.size || 100,
  });
}

export function getZipCodes(filter) {
  return apiV3Client.post("zips", {
    Country: filter.country,
    Location: filter.location,
    Size: filter.size || 100,
  });
}

export function getSmartjobZipCodes(filter) {
  return smartJobV1Client.post("zips", {
    Country: filter.country,
    Location: filter.location,
    Size: filter.size || 100,
  });
}
