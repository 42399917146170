import React from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { Tabs, Skeleton } from "antd";
import { connect } from "react-redux";
import "./OrganizationDetails.scss";
import * as OrgActions from "../../actions/orgActions";
import * as UserActions from "../../actions/userActions";
import {
  getOrgDetails,
  getAllCountries,
  getAuthenticatedCountries,
  getJobStatuses,
  getDefaultJobStatus,
  getOrgAryaFeatures,
  getOrgConnectFeatures,
  getOrgList,
  getOrgStats,
  getOrgStat,
  getPlanList,
  getOrgConfiguration,
  getOrgGuid,
  getAccountTypes,
  getLicenceTypes,
  getCurrentOrgProductVariantDetails,
  getPendingRequests1,
} from "../../reducers/orgReducer";
import OrgDetailHeader from "../../components/OrgDetailHeader/OrgDetailHeader";
import OrgDetailsComponent from "../../components/OrganizationDetails/OrganizationDetails";
import UserListContainer from "../UserList/UserList";
import ClientListContainer from "../ClientList/ClientList";
import ConnectDetailsContainer from "../ConnectDetails/ConnectDetails";
import PortalsDetailContainer from "../PortalsDetail/PortalsDetail";
import { getProductVariant } from "../../utils/ProductVariantDetails";
import { getApiStatus } from "../../reducers/ApiStatusReducer";
const { TabPane } = Tabs;

const mapStateToProps = (state) => ({
  orgDetails: getOrgDetails(state),
  orgList: getOrgList(state),
  orgStats: getOrgStats(state),
  orgStat: getOrgStat(state),
  planList: getPlanList(state),
  // plan: getPlan(state),
  allCountries: getAllCountries(state),
  authenticatedCountries: getAuthenticatedCountries(state),
  jobStatuses: getJobStatuses(state),
  orgAryaFeatures: getOrgAryaFeatures(state),
  orgConnectFeatures: getOrgConnectFeatures(state),
  defaultJobStatus: getDefaultJobStatus(state),
  accountTypes: getAccountTypes(state),
  licenceTypes: getLicenceTypes(state),
  orgConfiguration: getOrgConfiguration(state),
  smsPlan: getCurrentOrgProductVariantDetails(state),
  newOrgGuid: getOrgGuid(state),
  pendingRequests: getPendingRequests1(state),
  orgTrackingInfoApiStatus: getApiStatus(state, 'fetchOrgTrackingInfoApiStatus'),
});

const mapDispatchToProps = {
  // fetch api calls
  fetchOrgDetails: OrgActions.fetchOrgDetails,
  fetchCountries: OrgActions.fetchCountries,
  fetchAuthenticatedCountries: OrgActions.fetchAuthenticatedCountries,
  fetchJobStatuses: OrgActions.fetchJobStatuses,
  fetchOrgAryaFeatures: OrgActions.fetchOrgAryaFeatures,
  fetchOrgConnectFeatures: OrgActions.fetchOrgConnectFeatures,
  fetchOrgStat: OrgActions.fetchOrgStat,
  fetchOrgPlan: OrgActions.fetchOrgPlan,
  fetchAllUserIds: UserActions.fetchAllUserIds,
  fetchOrgConfiguration: OrgActions.fetchOrgConfiguration,
  fetchOrgTrackingInfo: OrgActions.fetchOrgTrackingInfo,
  fetchTestPixelJobUrls: OrgActions.fetchTestPixelJobUrls,
  fetchCareerPortalClient: OrgActions.fetchCareerPortalClient,
  fetchClickDetails: OrgActions.fetchClickDetails,
  //create/update api calls
  updateOrg: OrgActions.updateOrg,
  updateAuthenticatedCountries: OrgActions.updateAuthenticatedCountries,
  updateOrgJobStatusMappings: OrgActions.updateOrgJobStatusMappings,
  updateOrgAryaFeatures: OrgActions.updateOrgAryaFeatures,
  updateOrgConnectFeatures: OrgActions.updateOrgConnectFeatures,
  createPlan: OrgActions.createPlan,
  configureAOD: OrgActions.configureAOD,
  updatePlan: OrgActions.updatePlan,
  addCredits: OrgActions.addCredits,
  addJobCredits: OrgActions.addJobCredits,
  //clear calls
  clearOrgDetails: OrgActions.clearOrgDetails,
  clearJobStatuses: OrgActions.clearJobStatuses,
  clearAuthenticatedCountries: OrgActions.clearAuthenticatedCountries,
  clearOrgList: OrgActions.clearOrgList,
  clearOrgStats: OrgActions.clearOrgStats,
  clearPlanList: OrgActions.clearPlanList,
  getPendingRequests: OrgActions.getPendingRequests,
  changePendingRequestStatus: OrgActions.changePendingRequestStatus,
};

class OrganizationDetails extends React.Component {
  constructor(props) {
    super(props);
    let activeKey;
    let connectFeatureApiInProgress;
    let planApiInProgress;
    const { match } = this.props;
    switch (match.params.tab) {
      case "users":
        activeKey = "users";
        break;
      case "clients":
        activeKey = "clients";
        break;
      case "ats":
        activeKey = "ats";
        break;
      case "portals":
        activeKey = "portals";
        break;
      case "connect":
        activeKey = "connect";
        break;
      default:
        activeKey = "orgInfo";
    }
    this.state = {
      activeKey,
      connectFeatureApiInProgress,
      planApiInProgress,
    };
    this.onTabChange = this.onTabChange.bind(this);
    this.updateOrg = this.updateOrg.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.createPlan = this.createPlan.bind(this);
    this.updatePlan = this.updatePlan.bind(this);
    this.addCredits = this.addCredits.bind(this);
    this.addJobCredits = this.addJobCredits.bind(this);
  }

  componentDidMount() {
    const {
      fetchOrgDetails,
      fetchCountries,
      fetchAuthenticatedCountries,
      fetchJobStatuses,
      fetchOrgAryaFeatures,
      fetchOrgConnectFeatures,
      fetchOrgStat,
      orgList,
      orgStats,
      match,
      planList,
      fetchOrgPlan,
      fetchAllUserIds,
      fetchOrgConfiguration,
      getPendingRequests,
    } = this.props;
    let orgGuid = null;
    const { orgId } = match.params;

    const sourceCofigurationQuery = { IsAuthorized: null, IsEnabled: null };
    if (orgList) {
      for (let i = 0; i < orgList.length; i++) {
        if (parseInt(orgId) === orgList[i].Id) {
          orgGuid = orgList[i].Guid;
          break;
        }
      }
    }
    fetchOrgDetails(orgId);
    fetchCountries();
    fetchAuthenticatedCountries(orgId);
    fetchJobStatuses(orgId);
    fetchOrgAryaFeatures(orgId);
    fetchAllUserIds(orgId);

    if (orgGuid !== null) {
      fetchOrgConnectFeatures(orgGuid);
      const productVariantId = getProductVariant("SMS");
      const orgGuidsCollection = {};
      let orgGuids = [];
      orgGuids.push(orgGuid);
      orgGuidsCollection.GroupIds = orgGuids;

      if (!planList) {
        fetchOrgPlan(orgGuidsCollection);
      }
      if (productVariantId) {
        orgGuidsCollection.ProductVariantIds = productVariantId;
        fetchOrgPlan(orgGuidsCollection);
      }
      getPendingRequests(orgGuid);
    }
    if (!orgStats) {
      const orgIdsCollection = {};
      let orgIds = [];
      orgIds.push(orgId);
      orgIdsCollection.OrganizationIds = orgIds;
      fetchOrgStat(orgIdsCollection);
    }
    fetchOrgConfiguration(orgId, sourceCofigurationQuery);
  }

  componentDidUpdate() {
    const {
      orgDetails,
      fetchOrgConnectFeatures,
      orgConnectFeatures,
      planList,
      // plan,
      fetchOrgPlan,
      getPendingRequests,
    } = this.props;
    const { connectFeatureApiInProgress, planApiInProgress } = this.state;
    if (orgDetails && !orgConnectFeatures && !connectFeatureApiInProgress) {
      this.setState({ connectFeatureApiInProgress: true });
      fetchOrgConnectFeatures(orgDetails.OrgGuid);
    }

    if (orgDetails && !planList && !planApiInProgress) {
      const orgGuidsCollection = {};
      const productVariantId = getProductVariant("SMS");
      if (!this.props.pendingRequests) getPendingRequests(orgDetails.OrgGuid);
      let orgGuids = [];
      orgGuids.push(orgDetails.OrgGuid);
      orgGuidsCollection.GroupIds = orgGuids;

      this.setState({ planApiInProgress: true });
      fetchOrgPlan(orgGuidsCollection);
      if (productVariantId) {
        let payloadForSmsPlan = _.cloneDeep(orgGuidsCollection);
        payloadForSmsPlan.ProductVariantIds = productVariantId;
        fetchOrgPlan(payloadForSmsPlan);
      }
    }
  }

  updateOrg(jobStatuses, aryaFeatures, connectFeatures, values) {
    const {
      updateOrg,
      orgDetails,
      allCountries,
      updateAuthenticatedCountries,
      updateOrgAryaFeatures,
      updateOrgConnectFeatures,
      updateOrgJobStatusMappings,
    } = this.props;
    const updatedOrg = orgDetails;
    let countryCodesArray = [];
    if (values["CountryRadioCheck"] === "All") {
      for (let i = 0; i < allCountries.length; i++) {
        countryCodesArray.push(allCountries[i].Iso2Code);
      }
    } else if (values["CountryRadioCheck"] === "Manual") {
      const countryNames = values["AuthenticatedCountries"]; // string values for countries

      for (let i = 0; i < countryNames.length; i++) {
        for (let j = 0; j < allCountries.length; j++) {
          if (countryNames[i] === allCountries[j].Name) {
            countryCodesArray.push(allCountries[i].Iso2Code);
            break;
          }
        }
      }
    }
    updatedOrg.ThirtyStackRankType = "MIX";
    const countryCodesCollection = {};
    countryCodesCollection.CountryCodes = countryCodesArray;
    const jobStatusesCollection = {};
    jobStatusesCollection.JobStatuses = jobStatuses;
    jobStatusesCollection.DefaultJobStatus = values.DefaultJobStatus;
    const organizationFeatureCollection = {};
    organizationFeatureCollection.AryaFeatures = aryaFeatures;
    const connectFeaturesCollection = {};
    connectFeaturesCollection.ConnectFeatures = connectFeatures;
    updateOrg(orgDetails.Id, updatedOrg);
    updateAuthenticatedCountries(orgDetails.Id, countryCodesCollection);
    updateOrgJobStatusMappings(orgDetails.Id, jobStatusesCollection);
    updateOrgAryaFeatures(orgDetails.Id, organizationFeatureCollection);
    updateOrgConnectFeatures(orgDetails.OrgGuid, connectFeaturesCollection);
  }

  createPlan(values, activeTab) {
    const { createPlan, orgDetails } = this.props;
    const productVariantId = getProductVariant("SMS");
    const planCommand = {
      Name: values[`Name${activeTab}`],
      Type: values[`Type${activeTab}`],
      StartDate: values[`StartDate${activeTab}`],
      EndDate: values[`EndDate${activeTab}`],
      Credits: values[`PlanCredits${activeTab}`],
    };
    let groups = [];
    groups.push(orgDetails.OrgGuid);
    planCommand.Groups = groups;
    if (activeTab === "Text" && productVariantId) {
      planCommand.ProductVariants = productVariantId;
    }
    createPlan(planCommand, orgDetails.OrgGuid);
  }

  updatePlan(values, plan, activeTab) {
    const { updatePlan, orgDetails } = this.props;
    const productVariantId = getProductVariant("SMS");
    const planCommand = {
      Name: values[`Name${activeTab}`],
      Type: values[`Type${activeTab}`],
      StartDate: values[`StartDate${activeTab}`],
      EndDate: values[`EndDate${activeTab}`],
      Credits: values[`PlanCredits${activeTab}`],
    };
    let groups = [];
    groups.push(orgDetails.OrgGuid);
    planCommand.Groups = groups;
    if (activeTab === "Text" && productVariantId) {
      planCommand.ProductVariants = productVariantId;
    }
    updatePlan(plan.Id, planCommand, orgDetails.OrgGuid);
  }

  addCredits(values, plan, activePanel) {
    const { addCredits, orgDetails } = this.props;
    const paymentCommand = {};
    paymentCommand.PlanId = plan.Id;
    paymentCommand.Credits = values.Credits;
    paymentCommand.OrgGuid = orgDetails.OrgGuid;
    paymentCommand.CreditType = activePanel;
    addCredits(paymentCommand, orgDetails.OrgGuid);
  }

  addJobCredits(values) {
    const { addJobCredits } = this.props;
    addJobCredits(values);
  }

  onTabChange(key) {
    const { match, history } = this.props;
    const keyToUrl = {
      orgInfo: `/orgs/${match.params.orgId}`,
      clients: `/orgs/${match.params.orgId}/clients`,
      users: `/orgs/${match.params.orgId}/users`,
      portals: `/orgs/${match.params.orgId}/portals`,
      ats: `/orgs/${match.params.orgId}/ats`,
      connect: `/orgs/${match.params.orgId}/connect`,
    };
    this.setState({ activeKey: key });
    history.replace(keyToUrl[key]);
  }

  onEditClick() {
    const { match, history } = this.props;
    history.replace(`/orgs/${match.params.orgId}/edit`);
  }

  componentWillUnmount() {
    const {
      clearOrgDetails,
      clearJobStatuses,
      clearAuthenticatedCountries,
      clearOrgList,
      clearOrgStats,
      clearPlanList,
    } = this.props;
    clearOrgDetails();
    clearJobStatuses();
    clearAuthenticatedCountries();
    clearOrgList();
    clearOrgStats();
    clearPlanList();
  }

  render() {
    const {
      orgDetails,
      allCountries,
      authenticatedCountries,
      jobStatuses,
      defaultJobStatus,
      orgAryaFeatures,
      orgConnectFeatures,
      orgStats,
      orgStat,
      match,
      planList,
      accountTypes,
      licenceTypes,
      orgConfiguration,
      changePendingRequestStatus,
      pendingRequests,
      orgTrackingInfoApiStatus,
      fetchOrgTrackingInfo,
      configureAOD,
      fetchTestPixelJobUrls,
      fetchCareerPortalClient,
      fetchClickDetails,
    } = this.props;
    let orgStatDetail = null;
    let orgPlanDetails = null;
    const { orgId } = match.params;
    if (orgStats) {
      orgStatDetail = orgStats[orgId];
    } else if (orgStat) {
      orgStatDetail = orgStat[orgId];
    }

    if (orgDetails) {
      if (planList) {
        orgPlanDetails = planList[orgDetails.OrgGuid.toLowerCase()];
      }
    }

    const { activeKey } = this.state;
    return (
      <div className="form-div-class">
        <Skeleton
          active
          paragraph={{ rows: 16 }}
          loading={
            orgDetails &&
              allCountries &&
              authenticatedCountries &&
              jobStatuses &&
              orgAryaFeatures &&
              orgConnectFeatures &&
              orgStatDetail &&
              defaultJobStatus
              ? false
              : true
          }
        >
          <OrgDetailHeader
            onEdit={this.onEditClick}
            onCreatePlan={this.createPlan}
            onUpdatePlan={this.updatePlan}
            onAddCredits={this.addCredits}
            onAddJobCredits={this.addJobCredits}
            plan={orgPlanDetails}
            orgDetails={orgDetails}
            accountTypes={accountTypes}
            smsPlan={this.props.smsPlan}
            changePendingRequestStatus={changePendingRequestStatus}
            pendingRequests={pendingRequests}
            orgTrackingInfoApiStatus={orgTrackingInfoApiStatus}
            fetchOrgTrackingInfo={fetchOrgTrackingInfo}
          />
          <Tabs
            defaultActiveKey="orgInfo"
            onTabClick={this.onTabChange}
            activeKey={activeKey}
          >
            <TabPane tab="Org Info" key="orgInfo">
              <OrgDetailsComponent
                allCountries={allCountries}
                orgDetails={orgDetails}
                defaultJobStatus={defaultJobStatus}
                authenticatedCountries={authenticatedCountries}
                handleSave={this.updateOrg}
                jobStatuses={jobStatuses}
                orgAryaFeatures={orgAryaFeatures}
                orgConnectFeatures={orgConnectFeatures}
                orgStat={orgStatDetail}
                licenceTypes={licenceTypes}
                orgConfiguration={orgConfiguration}
                configureAOD={configureAOD}
                fetchTestPixelJobUrls={fetchTestPixelJobUrls}
                fetchCareerPortalClient={fetchCareerPortalClient}
                fetchClickDetails={fetchClickDetails}
              />
            </TabPane>

            <TabPane
              tab={`Clients (${orgStatDetail &&
                (orgStatDetail.Clients || orgStatDetail.Clients === 0)
                ? orgStatDetail.Clients
                : 0
                })`}
              key="clients"
            >
              <ClientListContainer />
            </TabPane>

            <TabPane
              tab={`Users/Licenses (${orgStatDetail &&
                (orgStatDetail.Licenses || orgStatDetail.Licenses === 0)
                ? orgStatDetail.Licenses
                : 0
                })`}
              key="users"
            >
              <UserListContainer />
            </TabPane>

            <TabPane tab="Portals" key="portals">
              <PortalsDetailContainer
                match={match}
                orgDetails={orgDetails}
                accountTypes={accountTypes}
              />
            </TabPane>

            <TabPane tab="ATS" key="ats">
              ATS - Yet to be implemented
              {/* <AtsDetails/> */}
            </TabPane>

            <TabPane tab="Connect" key="connect">
              <ConnectDetailsContainer />
            </TabPane>
          </Tabs>
        </Skeleton>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationDetails)
);
