import React from "react";
import styles from './PortalSubTabHeader.module.scss';

export function PortalSubTabHeader(props) {

    const {title, subTitle, isLogoVisible, logoPath} = props;

  return (
    <div className={styles.portalSubTabHeader}>
        {isLogoVisible ? 
        <div>
            <img
                src={logoPath}
                alt="Arya"
                width={50}
                height={50}
                />
        </div> : null}
        <div>
            <div className={styles.portalSubTabTitle}>{title}</div>
            <div className={styles.portalSubTabSubTitle}>{subTitle}</div>
        </div>
    </div>
  );
}
