import React from "react";
import { Tabs } from "antd";
import JobsDashboard from "./JobsDashboard";
import ActivityDashboard from "./ActivityDashboard";
import QuickSearchDashboard from "./QuickSearchDashboard";
import ContactPullsDashboard from "./ContactPullsDashboard";
import EmailsDashboard from "./EmailsDashboard";
import SMSDashboard from "./SMSDashboard";
import ConnectDashboard from "./ConnectDashboard";
import "./Dashboard.scss";

const { TabPane } = Tabs;
const timeFormat = "YYYY-MM-DDTHH:mm:ss";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: "jobs"
    };
  }
  onTabChange = key => {
    const { history } = this.props;
    const keyToUrl = {
      jobs: `/dashboard/jobs`,
      activity: `/dashboard/activity`,
      quickSearch: `/dashboard/quick-search`,
      aryaConnect: `/dashboard/connect`,
      contactPulls: `/dashboard/contact-pulls`,
      sms: `/dashboard/sms`,
      emails: `/dashboard/emails`
    };
    this.setState({ activeKey: key });
    history.replace(keyToUrl[key]);
  };

  render() {
    const { activeKey } = this.state;
    return (
      <Tabs
        defaultActiveKey="aryaCore"
        onTabClick={this.onTabChange}
        activeKey={activeKey}
        className="dashboard-tabs"
      >
        <TabPane tab="Jobs" key="jobs">
          <JobsDashboard timeFormat={timeFormat} />
        </TabPane>
        <TabPane tab="Activity" key="activity">
          <ActivityDashboard timeFormat={timeFormat} />
        </TabPane>
        <TabPane tab="Quick Search" key="quickSearch">
          <QuickSearchDashboard timeFormat={timeFormat} />
        </TabPane>
        <TabPane tab="Emails" key="emails">
          <EmailsDashboard timeFormat={timeFormat} />
        </TabPane>
        <TabPane tab="SMS" key="sms">
          <SMSDashboard timeFormat={timeFormat} />
        </TabPane>
        <TabPane tab="Contact Pulls" key="contactPulls">
          <ContactPullsDashboard timeFormat={timeFormat} />
        </TabPane>
        <TabPane tab="Arya Connect" key="aryaConnect">
          <ConnectDashboard timeFormat={timeFormat} />
        </TabPane>
      </Tabs>
    );
  }
}

export default Dashboard;
