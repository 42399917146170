import React from "react";
import { connect } from "react-redux";
import { Skeleton } from "antd";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import OrganizationListContainer from "./containers/OrganizationList/OrganizationList";
import OrganizationCreateContainer from "./containers/OrganizationCreation/OrganizationCreation";
import OrganizationEditContainer from "./containers/OrganizationEdit/OrganizationEdit";
import NavbarContainer from "./containers/Navbar/Navbar";
import OrganizationDetails from "./containers/OrganizationDetails/OrganizationDetails";
import Dashboard from "./components/Dashboard/Dashboard";
import UnauthorizedScreen from "./components/UnauthorizedScreen/UnauthorizedScreen";
import NetworkErrorComponent from "./components/NetworkErrorComponent/NetworkErrorComponent";
import NetworkIssueErrorBoundary from "./components/NetworkIssueErrorBoundary/NetworkIssueErrorBoundary";
import * as ConfigActions from "./actions/ConfigActions";
import { getConfig } from "./reducers/ConfigReducer";
import ScrollToTop from "./ScrollTop";
import { getApiStatus } from "./reducers/ApiStatusReducer";
import config from "./config/config";
import { Auth, Analytics } from "aws-amplify";
import * as OrgActions from "./actions/orgActions";

const oauth = {
  domain: config.auth.cognito.domain,
  region: config.auth.cognito.region,
  scope: [
    "phone",
    "email",
    "profile",
    "openid",
    "aws.cognito.signin.user.admin",
  ],
  redirectSignIn: config.auth.cognito.redirectUri,
  redirectSignOut: config.auth.cognito.redirectUri,
  responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
};

Analytics.configure({ disabled: true });

Auth.configure({
  oauth,
  userPoolId: config.auth.cognito.poolId,
  userPoolWebClientId: config.auth.cognito.clientId,
});

const mapDispatchToProps = {
  fetchConfig: ConfigActions.fetchConfig,
  fetchAccountTypes : OrgActions.fetchAccountTypes,
  fetchLicenceTypes : OrgActions.fetchLicenceTypes,
};

const mapStateToProps = (state) => ({
  userConfig: getConfig(state),
  configApiStatus: getApiStatus(state, "configApiStatus"),
});

class AppRouter extends React.Component {
  componentDidMount() {
    const { 
      fetchConfig, 
      fetchAccountTypes, 
      fetchLicenceTypes 
    } = this.props;
    
    fetchConfig();
    fetchAccountTypes();
    fetchLicenceTypes();
  }
  render() {
    const { configApiStatus, userConfig } = this.props;
    return (
      <Router>
        <div>
          <Route path="*" component={NavbarContainer} />
          <Route path="/unauthorized" component={UnauthorizedScreen} />
          {configApiStatus === "PENDING" ? (
            <Skeleton loading active paragraph={{ rows: 16, width: "80" }} />
          ) : null}
          {configApiStatus === "FAILED" ? <NetworkErrorComponent /> : null}
          {configApiStatus === "SUCCESS" && userConfig.CanAccessAccMgmtApp ? (
            <NetworkIssueErrorBoundary>
              <ScrollToTop>
                <Switch>
                  <Route exact path="/" component={OrganizationListContainer} />
                  <Route
                    path="/orgs/"
                    exact
                    component={OrganizationListContainer}
                  />
                  <Route
                    path="/orgs/:orgId/edit"
                    component={OrganizationEditContainer}
                  />
                  <Route
                    path="/orgs/create"
                    component={OrganizationCreateContainer}
                  />
                  <Route
                    path="/orgs/:orgId/:tab"
                    component={OrganizationDetails}
                  />
                  <Route path="/orgs/:orgId" component={OrganizationDetails} />
                  <Route path="/dashboard" component={Dashboard} />
                  <Route path="/dashboard/:tab" component={Dashboard} />
                </Switch>
              </ScrollToTop>
            </NetworkIssueErrorBoundary>
          ) : null}
        </div>
      </Router>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
