export const SET_API_STATUS = "SET_API_STATUS";

export const setApiStatus = (apiName,status) => ({
  type: SET_API_STATUS,
  payload : {
    apiName,
    status
  },
});

