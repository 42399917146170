import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as UserActions from "../../actions/userActions";
import * as OrgActions from "../../actions/orgActions";
import {
  getUserDetails,
  getAryaUserFeatures,
  getConnectUserFeatures,
  getUserUpdateStatus,
  getUserList,
  getUserCallerIds,
  getUserCreationStatus,
  getUserUtilities,
} from "../../reducers/userReducer";
import { getApiStatus } from "../../reducers/ApiStatusReducer";
import {
  getAvailableAryaUserFeatures,
  getAvailableConnectUserFeatures,
  getAuthenticatedCountries,
} from "../../reducers/orgReducer";
import UserFormComponent from "../../components/UserForm/UserForm";
import { Drawer, Skeleton } from "antd";
import "./UserDetail.scss";
import { getAccountTypes } from '../../reducers/orgReducer';

const mapStateToProps = (state) => ({
  userDetails: getUserDetails(state),
  userList: getUserList(state),
  userCreationStatus: getUserCreationStatus(state),
  userUpdateStatus: getUserUpdateStatus(state),
  aryaUserFeatures: getAryaUserFeatures(state),
  connectUserFeatures: getConnectUserFeatures(state),
  availableAryaUserFeatures: getAvailableAryaUserFeatures(state),
  availableConnectUserFeatures: getAvailableConnectUserFeatures(state),
  userCallerIds: getUserCallerIds(state),
  authenticatedCountries: getAuthenticatedCountries(state),
  locationApiStatus: getApiStatus(state, "locationApiStatus"),
  userUtilities: getUserUtilities(state),
  accountTypes : getAccountTypes(state),
  fetchUserDetailsApiStatus : getApiStatus(state, "fetchUserDetailsApiStatus"),
  fetchConnectUserFeaturesApiStatus : getApiStatus(state, "fetchConnectUserFeaturesApiStatus"),
  fetchAryaUserFeaturesApiStatus : getApiStatus(state, "fetchAryaUserFeaturesApiStatus")
});

const mapDispatchToProps = {
  fetchUserDetails: UserActions.fetchUserDetails,
  fetchAryaUserFeatures: UserActions.fetchAryaUserFeatures,
  fetchAvailableAryaUserFeatures: OrgActions.fetchOrgAryaFeatures,
  fetchConnectUserFeatures: UserActions.fetchConnectUserFeatures,
  fetchAvailableConnectUserFeatures: OrgActions.fetchOrgConnectFeatures,
  fetchUserCallerIds: UserActions.fetchUserCallerIds,

  createUser: UserActions.createUser,

  updateUser: UserActions.updateUser,
  updateUserAryaFeatures: UserActions.updateUserAryaFeatures,
  updateUserConnectFeatures: UserActions.updateUserConnectFeatures,
  updateUserCallerIds: UserActions.updateUserCallerIds,

  clearUserDetails: UserActions.clearUserDetails,
  clearAryaUserFeatures: UserActions.clearAryaUserFeatures,
  clearConnectUserFeatures: UserActions.clearConnectUserFeatures,
  clearUserCallerIds: UserActions.clearUserCallerIds,
  clearUserCreationStatus: UserActions.clearUserCreationStatus,
  clearUserUpdateStatus: UserActions.clearUserUpdateStatus,

  fetchLocations: UserActions.fetchLocations,
  fetchZipCodes: UserActions.fetchZipCodes,
};

class UserDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connectFeatureApiInProgress: false,
      callerIdApiInProgress: false,
      isResetPasswordClicked: false      
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(
    oldUserDetails,
    connectUserFeatures,
    aryaUserFeatures,
    audioCallerIdUpdateCommands,
    values
  ) {
    const { createUser, updateUser, orgDetails, userId, mode } = this.props;
    let userDetails = {};
    if (oldUserDetails) {
      userDetails = oldUserDetails;
    }
    const userCallerIds = [];
    Object.keys(values).forEach((key) => {
      if (typeof values[key] !== "undefined") {
        if (key.includes("CountryCode")) {
          const userCallerId = {};
          const keyArr = key.split("-");
          const index = keyArr[1];
          userCallerId.CountryCode = values[key];
          userCallerId.CallerId = values[`CallerId-${index}`];
          userCallerIds.push(userCallerId);
        } else {
          userDetails[key] = values[key];
        }
      }
    });
    if (userDetails.RoleName && orgDetails.Roles) {
      for (let i = 0; i < orgDetails.Roles.length; i++) {
        if (orgDetails.Roles[i].RoleName === userDetails.RoleName) {
          userDetails.RoleId = orgDetails.Roles[i].RoleId;
          break;
        }
      }
      delete userDetails.RoleName;
    }
    userDetails.OrgGuid = orgDetails.OrgGuid;
    userDetails.OrgId = orgDetails.Id;
    userDetails.OrgName = orgDetails.Name;
    userDetails.VendorId = null;
    userDetails.Email = values["EmailId"];
    delete userDetails.EmailId;
    delete userDetails.ConfirmPassword;
    const audioCallerIdCollection = {};
    audioCallerIdCollection.CallerIds = audioCallerIdUpdateCommands;
    const connectFeaturesCollection = {};
    connectFeaturesCollection.ConnectFeatures = connectUserFeatures;
    const aryaFeaturesCollection = {};
    aryaFeaturesCollection.AryaFeatures = aryaUserFeatures;
    userDetails.audioCallerIdCollection = audioCallerIdCollection;
    userDetails.connectFeaturesCollection = connectFeaturesCollection;
    userDetails.aryaFeaturesCollection = aryaFeaturesCollection;
    if (mode === "create") {
      userDetails.UserId = null;
      var userCreateRequest = {};
      userCreateRequest.User = userDetails;
      userCreateRequest.Password = userDetails.Password;
      createUser(userCreateRequest);
    } else {
      updateUser(userId, userDetails);
    }
  }

  componentDidMount() {
    const { mode } = this.props;
    if (mode === "edit") {
      const {
        fetchUserDetails,
        fetchAryaUserFeatures,
        userList,
        fetchConnectUserFeatures,
        fetchUserCallerIds,
        userId,
        clearUserDetails,
        clearUserCallerIds,
        clearAryaUserFeatures,
        clearConnectUserFeatures
      } = this.props;
      let userGuid = null;
      if (userList) {
        for (let i = 0; i < userList.length; i++) {
          if (userId === userList[i].Id) {
            userGuid = userList[i].UserGuid;
            break;
          }
        }
      }
      clearUserDetails();
      clearUserCallerIds();
      clearAryaUserFeatures();
      clearConnectUserFeatures();
      fetchUserDetails(userId);
      fetchAryaUserFeatures(userId);
      fetchConnectUserFeatures(userGuid);
      fetchUserCallerIds(userGuid);
    }
  }

  componentDidUpdate() {
    const {
      userDetails,
      clearUserCreationStatus,
      clearUserUpdateStatus,
      connectUserFeatures,
      userCallerIds,
      fetchConnectUserFeatures,
      fetchUserCallerIds,
      userCreationStatus,
      userUpdateStatus,
    } = this.props;
    const { connectFeatureApiInProgress, callerIdApiInProgress } = this.state;
    if (userDetails && !connectUserFeatures && !connectFeatureApiInProgress) {
      this.setState({ connectFeatureApiInProgress: true });
      fetchConnectUserFeatures(userDetails.UserGuid);
    }
    if (userDetails && !userCallerIds && !callerIdApiInProgress) {
      this.setState({ callerIdApiInProgress: true });
      fetchUserCallerIds(userDetails.UserGuid);
    }
    if (userCreationStatus && userCreationStatus === "success") {
      clearUserCreationStatus();
      this.handleClose();
    }
    if (userUpdateStatus && userUpdateStatus === "success") {
      clearUserUpdateStatus();
      this.handleClose();
    }
  }

  handleClose() {
    const {
      handleClose,
      clearUserDetails,
      clearUserCallerIds,
      clearAryaUserFeatures,
      clearConnectUserFeatures,
    } = this.props;
    clearUserDetails();
    clearUserCallerIds();
    clearAryaUserFeatures();
    clearConnectUserFeatures();
    handleClose();
  }

  render() {
    const {
      visible,
      orgDetails,
      mode,
      userDetails,
      aryaUserFeatures,
      connectUserFeatures,
      availableAryaUserFeatures,
      availableConnectUserFeatures,
      userCallerIds,
      authenticatedCountries,
      fetchLocations,
      fetchZipCodes,
      locationApiStatus,
      userUtilities,
      accountTypes,
      fetchUserDetailsApiStatus,
      fetchConnectUserFeaturesApiStatus,
      fetchAryaUserFeaturesApiStatus
    } = this.props;
    const { isResetPasswordClicked} = this.state;
    if (!visible) {
      return null;
    }
  
    let audioCallerIdCollection = [];
    if (userCallerIds) {
      for (let i = 0; i < userCallerIds.length; i++) {
        const tmpAudioCallerIdUpdateQuery = {};
        tmpAudioCallerIdUpdateQuery.CallerId = userCallerIds[i];
        tmpAudioCallerIdUpdateQuery.Action = "";
        audioCallerIdCollection.push(tmpAudioCallerIdUpdateQuery);
      }
    }
    let drawerContent = null;
    if (!isResetPasswordClicked) {
      drawerContent = (
        <UserFormComponent
          userDetails={userDetails}
          aryaUserFeatures={aryaUserFeatures}
          connectUserFeatures={connectUserFeatures}
          availableAryaUserFeatures={availableAryaUserFeatures}
          availableConnectUserFeatures={availableConnectUserFeatures}
          audioCallerIdCollection={audioCallerIdCollection}
          handleClose={this.handleClose}
          handleSubmit={this.handleSubmit}
          orgDetails={orgDetails}
          authenticatedCountries={authenticatedCountries}
          mode={mode}
          accountTypes={accountTypes}
        />
      );
    }
    return (
      <Drawer
        onClose={this.handleClose}
        visible={true}
        className="client-form-drawer"
        title={mode === "create" ? "New User" : "User"}
      >
        <Skeleton active paragraph={{ rows: 16 }} loading={
             mode === "edit" ? 
             !(fetchUserDetailsApiStatus === "SUCCESS"  &&
             fetchConnectUserFeaturesApiStatus === "SUCCESS" &&
             fetchAryaUserFeaturesApiStatus === "SUCCESS")
              : false         
        }>
          <UserFormComponent
            userDetails={userDetails}
            aryaUserFeatures={aryaUserFeatures}
            connectUserFeatures={connectUserFeatures}
            availableAryaUserFeatures={availableAryaUserFeatures}
            availableConnectUserFeatures={availableConnectUserFeatures}
            audioCallerIdCollection={audioCallerIdCollection}
            handleClose={this.handleClose}
            handleSubmit={this.handleSubmit}
            orgDetails={orgDetails}
            authenticatedCountries={authenticatedCountries}
            mode={mode}
            fetchZipCodes={fetchZipCodes}
            fetchLocations={fetchLocations}
            locationApiStatus={locationApiStatus}
            userUtilities={userUtilities}
            accountTypes={accountTypes}
          />
        </Skeleton>
      </Drawer>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserDetail)
);
