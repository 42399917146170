import React from "react";
import TimeFilter from "./TimeFilter";
import "./Dashboard.scss";

class QuickSearchDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromTime: new Array(1).fill("now-1w"),
      toTime: new Array(1).fill("now")
    };
  }

  onTimeFilter = (from, to, index) => {
    let { fromTime, toTime } = this.state;
    fromTime[index] = from;
    toTime[index] = to;
    this.setState({
      fromTime,
      toTime
    });
  };

  render() {
    const { fromTime, toTime } = this.state;
    const { timeFormat } = this.props;
    return (
      <div className="dashboard">
        <div className="kibana-aryacore-iframe">
          <div className="date-filter-wrapper">
            <div className="heading">Quick search usage per organization</div>
            <TimeFilter
              onTimeFilter={(from, to) => this.onTimeFilter(from, to, 0)}
              timeFormat={timeFormat}
            />
          </div>
          <iframe
            src={`https://search-logs-slp74umkdlzbe4unt7y6jzp2yu.us-east-1.es.amazonaws.com/_plugin/kibana/app/kibana#/visualize/edit/9049ed70-24c6-11ea-95e6-b518dc8b5851?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3A'${fromTime[0]}'%2Cmode%3Aabsolute%2Cto%3A'${toTime[0]}'))`}
            height="800"
            width="100%"
            title="quick-search-usage-by-org"
          ></iframe>
        </div>
      </div>
    );
  }
}

export default QuickSearchDashboard;
