import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";

import { OrgReducer } from "./reducers/orgReducer";
import { AdminReducer } from "./reducers/adminReducer";
import { UserReducer } from "./reducers/userReducer";
import { ClientReducer } from "./reducers/clientReducer";
import { PortalReducer } from "./reducers/portalReducer";
import { ApiStatusReducer } from "./reducers/ApiStatusReducer";
import { ConfigReducer } from "./reducers/ConfigReducer";

const combinedReducer = combineReducers({
  OrgReducer,
  AdminReducer,
  UserReducer,
  ClientReducer,
  PortalReducer,
  ApiStatusReducer,
  ConfigReducer,
});
let composeEnhancers = compose;
let middlewares = [thunk, logger];

const store = createStore(
  combinedReducer,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
);

export default store;
