import * as userRepository from "../repository/userRepository";
import { message } from "antd";
import {
  setApiStatus
} from './ActionsCreators/ApiStatusActionCreator';

message.config({
  top: 100,
  duration: 10,
  maxCount: 1,
});

function createUser(userCreateRequest) {
  const userDetails = userCreateRequest.User;
  const orgId = userDetails.OrgId;
  return (dispatch) => {
    userRepository
      .createUser(orgId, userCreateRequest)
      .then(() => {
          dispatch({
            type: "SET_USER_CREATION_STATUS",
            payload: "success",
          });
          message.success("User created successfully");
          dispatch(fetchUserList(1, orgId));        
      })
      .catch((err) => {
        dispatch({
          type: "SET_USER_CREATION_STATUS",
          payload: "failed",
        });
        if (err?.response?.status === 400) {
          if (err.response.data.Error.Code === "INVALID_PASSWORD") {
            message.error("Passowrd does not conform with the password policy");
          }
        }
        if (err.response?.status === 409) {
          message.error("A user with the same Email already exists");
        } else if (err?.response?.status === 403) {
          message.error("You are not authorized to create a user.");
        } else {
          message.error("Failed to create User. Please try again");
        }
      });
  };
}

function updateUser(userId, userDetails) {
  let connectUserUpdated = false;
  let payUserUpdated = false;
  let userCallerIdsUpdated = false;
  let connectFeaturesUpdated = false;
  let aryaFeaturesUpdated = false;
  const orgId = userDetails.OrgId;
  return (dispatch) => {
    userRepository
      .updateUser(userId, userDetails)
      .then((response) => {
        const userGuid = userDetails.UserGuid;
        userRepository
          .upsertConnectUser(userGuid, userDetails)
          .then((response) => {
            connectUserUpdated = true;
            userRepository
              .updateUserConnectFeatures(
                userGuid,
                userDetails.connectFeaturesCollection
              )
              .then((response) => {
                connectFeaturesUpdated = true;
                if (
                  payUserUpdated &&
                  connectUserUpdated &&
                  aryaFeaturesUpdated &&
                  connectFeaturesUpdated &&
                  userCallerIdsUpdated
                ) {
                  dispatch({
                    type: "SET_USER_UPDATE_STATUS",
                    payload: "success",
                  });
                  message.success("User updated successfully");
                  dispatch(fetchUserList(1, orgId));
                }
              })
              .catch((err) => {
                dispatch({
                  type: "SET_USER_UPDATE_STATUS",
                  payload: "failed",
                });
                message.error("Failed to update, please try again");
              });

            userRepository
              .updateUserCallerIds(
                userGuid,
                userDetails.audioCallerIdCollection
              )
              .then((response) => {
                userCallerIdsUpdated = true;
                if (
                  payUserUpdated &&
                  connectUserUpdated &&
                  aryaFeaturesUpdated &&
                  connectFeaturesUpdated &&
                  userCallerIdsUpdated
                ) {
                  dispatch({
                    type: "SET_USER_UPDATE_STATUS",
                    payload: "success",
                  });
                  message.success("User updated successfully");
                  dispatch(fetchUserList(1, orgId));
                }
              })
              .catch((err) => {
                dispatch({
                  type: "SET_USER_UPDATE_STATUS",
                  payload: "failed",
                });
                message.error("Failed to update, please try again");
              });
          })
          .catch((err) => {
            dispatch({
              type: "SET_USER_UPDATE_STATUS",
              payload: "failed",
            });
            message.error("Failed to update, please try again");
          });

        userRepository
          .upsertAryaPayUser(userDetails.UserGuid, userDetails)
          .then((response) => {
            payUserUpdated = true;
            if (
              payUserUpdated &&
              connectUserUpdated &&
              aryaFeaturesUpdated &&
              connectFeaturesUpdated &&
              userCallerIdsUpdated
            ) {
              dispatch({
                type: "SET_USER_UPDATE_STATUS",
                payload: "success",
              });
              message.success("User updated successfully");
              dispatch(fetchUserList(1, orgId));
            }
          })
          .catch((err) => {
            dispatch({
              type: "SET_USER_UPDATE_STATUS",
              payload: "failed",
            });
            message.error("Failed to update, please try again");
          });

        userRepository
          .updateUserAryaFeatures(userId, userDetails.aryaFeaturesCollection)
          .then((response) => {
            aryaFeaturesUpdated = true;
            if (
              payUserUpdated &&
              connectUserUpdated &&
              aryaFeaturesUpdated &&
              connectFeaturesUpdated &&
              userCallerIdsUpdated
            ) {
              dispatch({
                type: "SET_USER_UPDATE_STATUS",
                payload: "success",
              });
              message.success("User updated successfully");
              dispatch(fetchUserList(1, orgId));
            }
          })
          .catch((err) => {
            dispatch({
              type: "SET_USER_UPDATE_STATUS",
              payload: "failed",
            });
            message.error("Failed to update, please try again");
          });
      })
      .catch(() => {
        dispatch({
          type: "SET_USER_UPDATE_STATUS",
          payload: "failed",
        });
        message.error("Failed to update, please try again");
      });
  };
}

function clearUserCreationStatus() {
  return (dispatch) => {
    dispatch({
      type: "SET_USER_CREATION_STATUS",
      payload: null,
    });
  };
}

function clearUserUpdateStatus() {
  return (dispatch) => {
    dispatch({
      type: "SET_USER_UPDATE_STATUS",
      payload: null,
    });
  };
}

function clearUserDetails() {
  return (dispatch) => {
    dispatch({
      type: "SET_USER_DETAILS",
      payload: null,
    });
  };
}

function clearAryaUserFeatures() {
  return (dispatch) => {
    dispatch({
      type: "SET_ARYA_USER_FEATURES",
      payload: null,
    });
  };
}

function clearConnectUserFeatures() {
  return (dispatch) => {
    dispatch({
      type: "SET_CONNECT_USER_FEATURES",
      payload: null,
    });
  };
}

function clearUserCallerIds() {
  return (dispatch) => {
    dispatch({
      type: "SET_USER_CALLER_IDS",
      payload: null,
    });
  };
}

const setBulkUserConfigAPiStatus = (status) => ({
  type: "SET_BULK_USER_CONFIG_API_STATUS",
  payload: { status },
});

const updateBulkUserConfig = (configuration) => ({
  type: "UPDATE_BULK_USER_CONFIG",
  payload: { userConfigByIds: configuration },
});

const setBulkUserConfig = (configuration) => ({
  type: "SET_BULK_USER_CONFIG",
  payload: { userConfigByIds: configuration },
});

const setTotalUsersCount = (totalCount) => ({
  type: "SET_TOTAL_USERS_COUNT",
  payload: { totalCount },
});

const setMaxCount = (maxCount) => ({
  type: "SET_MAX_COUNT",
  payload: { maxCount },
});

const setUsersListForPortalConfig = (users) => ({
  type: "SET_USER_LIST_FOR_PORTAL_CONFIG",
  payload: { users },
});

const setUsersListForPortalConfigModal = (users, page) => ({
  type: "SET_USER_LIST_FOR_PORTAL_CONFIG_MODAL",
  payload: { users, page },
});

const setAllUserIds = (userIds) => ({
  type: "SET_ALL_USER_IDS",
  payload: { userIds },
});

function fetchUserListForPortalConfig(page, orgId, searchTerm) {
  return (dispatch) => {
    dispatch(setBulkUserConfigAPiStatus("INPROGRESS"));
    return userRepository
      .fetchUserListForPortalConfig(
        {
          from: (page - 1) * 10,
          size: 1000,
          searchTerm,
        },
        orgId
      )
      .then((response) => {
        const userIds = response.data.Users.map((user) => user.Id);
        return userRepository
          .fetchBulkUserSourceConfiguration(userIds)
          .then((configurationResponse) => {
            dispatch(setUsersListForPortalConfig(response.data.Users));
            dispatch(
              setUsersListForPortalConfigModal(response.data.Users, page)
            );
            if (!searchTerm) dispatch(setTotalUsersCount(response.data.Total));
            dispatch(updateBulkUserConfig(configurationResponse.data));
            dispatch(setBulkUserConfigAPiStatus("COMPLETED"));
          });
      })
      .catch(() => {
        dispatch(setBulkUserConfigAPiStatus("FAILED"));
      });
  };
}

function fetchBulkUserSourceConfiguration(userIdsCollection) {
  return (dispatch) => {
    userRepository
      .fetchBulkUserSourceConfiguration(userIdsCollection)
      .then((response) => {
        dispatch({
          type: "SET_BULK_USER_CONFIG",
          payload: response.data,
        });
      });
  };
}

function updateBulkUserConfiguration(userConfigurationsCollection) {
  return (dispatch) => {
    return userRepository
      .updateBulkUserConfiguration(userConfigurationsCollection)
      .then(() => {
        message.success("Updated successfully");
      })
      .catch((error) => {
        message.error("Failed to update user configuration");
        throw error;
      });
  };
}

const setUserList = (users) => ({
  type: "SET_USER_LIST",
  payload: { users },
});

const setUsersForPortalAccount = (users) => ({
  type: "SET_USERS_FOR_PORTAL_ACCOUNT",
  payload: { users },
});

function fetchUserList(page, orgId, searchTerm) {
  return (dispatch) => {
    userRepository
      .fetchUserList(
        {
          from: (page - 1) * 10,
          size: 10,
          searchTerm,
        },
        orgId
      )
      .then((response) => {
        dispatch(setUserList(response.data.Users));
        dispatch(setTotalUsersCount(response.data.Total));
      })
      .catch(() => {
        dispatch(setUserList(null));
      });
  };
}

function fetchUserDetails(userId) {
  return (dispatch) => {
    dispatch(setApiStatus('fetchUserDetailsApiStatus','PENDING'));
    userRepository
      .fetchUserDetails(userId)
      .then((response) => {
        dispatch({
          type: "SET_USER_DETAILS",
          payload: response.data,
        });
        dispatch(setApiStatus('fetchUserDetailsApiStatus','SUCCESS'));
      })
      .catch((err) => {
        dispatch({
          type: "SET_USER_DETAILS",
          payload: null,
        });
        dispatch(setApiStatus('fetchUserDetailsApiStatus','FAILED'));
      });
  };
}

function fetchAryaUserFeatures(userId) {
  return (dispatch) => {
    dispatch(setApiStatus('fetchAryaUserFeaturesApiStatus',"PENDING"));
    userRepository
      .fetchAryaUserFeatures(userId)
      .then((response) => {
        dispatch({
          type: "SET_ARYA_USER_FEATURES",
          payload: response.data,
        });
        dispatch(setApiStatus('fetchAryaUserFeaturesApiStatus',"SUCCESS"));
      })
      .catch((err) => {
        dispatch({
          type: "SET_ARYA_USER_FEATURES",
          payload: null,
        });
        dispatch(setApiStatus('fetchAryaUserFeaturesApiStatus',"FAILED"));
      });
  };
}

function fetchConnectUserFeatures(userGuid) {
  return (dispatch) => {
    dispatch(setApiStatus('fetchConnectUserFeaturesApiStatus',"PENDING"));
    userRepository
      .fetchConnectUserFeatures(userGuid)
      .then((response) => {
        dispatch({
          type: "SET_CONNECT_USER_FEATURES",
          payload: response.data,
        });
        dispatch(setApiStatus('fetchConnectUserFeaturesApiStatus',"SUCCESS"));
      })
      .catch((err) => {
        dispatch({
          type: "SET_CONNECT_USER_FEATURES",
          payload: null,
        });
        dispatch(setApiStatus('fetchConnectUserFeaturesApiStatus',"FAILED"));
      });
  };
}

function fetchUserCallerIds(userGuid) {
  return (dispatch) => {
    userRepository
      .fetchUserCallerIds(userGuid)
      .then((response) => {
        dispatch({
          type: "SET_USER_CALLER_IDS",
          payload: response.data.CallerIds,
        });
      })
      .catch((err) => {
        dispatch({
          type: "SET_USER_CALLER_IDS",
          payload: null,
        });
      });
  };
}

function updateUserAryaFeatures(userId, aryaFeaturesCollection) {
  return (dispatch) => {
    userRepository
      .updateUserAryaFeatures(userId, aryaFeaturesCollection)
      .then((response) => {})
      .catch((err) => {
        dispatch({
          type: "SET_USER_DETAILS",
          payload: null,
        });
        message.error("Failed to update arya features, please try again");
      });
  };
}

function updateUserConnectFeatures(userGuid, connectFeaturesCollection) {
  return (dispatch) => {
    userRepository
      .updateUserConnectFeatures(userGuid, connectFeaturesCollection)
      .then((response) => {})
      .catch((err) => {
        dispatch({
          type: "SET_USER_DETAILS",
          payload: null,
        });
        message.error("Failed to update connect features, please try again");
      });
  };
}

function updateUserCallerIds(userGuid, audioCallerIdCollection) {
  return (dispatch) => {
    userRepository
      .updateUserCallerIds(userGuid, audioCallerIdCollection)
      .then((response) => {})
      .catch((err) => {
        dispatch({
          type: "SET_USER_DETAILS",
          payload: null,
        });
        message.error("Failed to update audio caller ids, please try again");
      });
  };
}

function resetUserPassword(userId, resetPassowrdPayload) {
  return (dispatch) => {
    userRepository
      .resetUserPassword(userId, resetPassowrdPayload)
      .then((response) => {
        message.success("Password is successfully changed");
      })
      .catch((err) => {
        if (err.response.status === 400) {
          if (err.response.data.Error.Code === "INVALID_PASSWORD") {
            message.error("Password does not confirm with the password policy");
          }
        } else if (err.response.status === 403) {
          message.error("You are not authorized to change the password");
        } else if (err.response.status === 404) {
          message.error("User does not exist");
        } else {
          message.error("Failed to update the user password, please try again");
        }
      });
  };
}

function fetchUsersForPortalAccount(page, orgId, searchTerm) {
  return (dispatch) => {
    return userRepository
      .fetchUserList(
        {
          from: (page - 1) * 10,
          size: 10,
          searchTerm,
        },
        orgId
      )
      .then((response) => {
        dispatch(setUsersForPortalAccount(response.data.Users));
        dispatch(setMaxCount(response.data.Total));
      });
  };
}

function fetchAllUserIds(orgId) {
  return (dispatch) => {
    return userRepository.fetchAllUserIds(orgId).then((response) => {
      dispatch(setAllUserIds(response.data));
    });
  };
}

function fetchLocations(filter, requireAuthentication = true) {
  return async (dispatch) => {
    let response;
    let payload;
    dispatch({
      type: "FETCH_LOCATION_API_STATUS",
      payload: "INPROGRESS",
    });
    try {
      if (requireAuthentication) {
        response = await userRepository.getLocations(filter);
      } else {
        response = await userRepository.getSmartjobLocations(filter);
      }
      payload = response.data;
      dispatch({
        type: "SET_LOCATIONS",
        payload,
      });
      dispatch({
        type: "FETCH_LOCATION_API_STATUS",
        payload: "COMPLETED",
      });
    } catch {
      dispatch({
        type: "FETCH_LOCATION_API_STATUS",
        payload: "FAILED",
      });
    }
  };
}

function fetchZipCodes(filter, requireAuthentication = true) {
  return async (dispatch) => {
    let response;
    let payload;
    try {
      if (requireAuthentication) {
        response = await userRepository.getZipCodes(filter);
      } else {
        response = await userRepository.getSmartjobZipCodes(filter);
      }
      payload = response.data;
      dispatch({
        type: "SET_ZIP_CODES",
        payload,
      });
    } catch {}
  };
}
export {
  createUser,
  fetchUserListForPortalConfig,
  fetchBulkUserSourceConfiguration,
  updateBulkUserConfiguration,
  setUserList,
  fetchUserList,
  fetchUserDetails,
  updateUser,
  fetchAryaUserFeatures,
  fetchConnectUserFeatures,
  fetchUserCallerIds,
  updateUserAryaFeatures,
  updateUserConnectFeatures,
  updateUserCallerIds,
  clearUserDetails,
  clearAryaUserFeatures,
  clearConnectUserFeatures,
  clearUserCallerIds,
  resetUserPassword,
  clearUserCreationStatus,
  clearUserUpdateStatus,
  setUsersListForPortalConfigModal,
  setBulkUserConfig,
  fetchUsersForPortalAccount,
  fetchAllUserIds,
  fetchLocations,
  fetchZipCodes,
};
