import React from "react";
import OrgFormComponent from "../../components/OrgForm/OrgForm";
import * as OrgActions from "../../actions/orgActions";
import {
  getAvailableAryaFeatures,
  getAvailableConnectFeatures,
  getAllCountries,
  getLicenceTypes,
  getAccountTypes
} from "../../reducers/orgReducer";
import { Skeleton } from "antd";
import { connect } from "react-redux";

const mapStateToProps = state => ({
  availableAryaFeatures: getAvailableAryaFeatures(state),
  availableConnectFeatures: getAvailableConnectFeatures(state),
  allCountries: getAllCountries(state),
  accountTypes: getAccountTypes(state),
  licenceTypes: getLicenceTypes(state),
});

const mapDispatchToProps = {
  createOrg: OrgActions.createOrg,
  fetchCountries: OrgActions.fetchCountries,
  clearOrgList: OrgActions.clearOrgList,
  clearOrgStats: OrgActions.clearOrgStats
};

class OrganizationCreation extends React.Component {
  constructor(props) {
    super(props);
    this.createNewOrg = this.createNewOrg.bind(this);
  }

  componentDidMount() {
    const {
      clearOrgList,
      clearOrgStats,
      fetchCountries
    } = this.props;
    
    fetchCountries();
    clearOrgList();
    clearOrgStats();
  }

  createNewOrg(JobStatuses, values) {
    const { createOrg, allCountries, history } = this.props;
    const newOrg = {};
    Object.keys(values).forEach(key => {
      if (
        typeof values[key] !== "undefined" &&
        key !== "AryaOpenStatuses" &&
        key !== "AryaCloseStatuses" &&
        key !== "CountryRadioCheck" &&
        key !== "JobStatuses" &&
        key !== "OrgStatus"
      ) {
        newOrg[key] = values[key];
      }
    });
    newOrg.ThirtyStackRankType = "MIX";
    if (values["OrgStatus"] === "Active") {
      newOrg.IsActive = true;
    } else {
      newOrg.IsActive = false;
    }

    let countryCodesArray = [];
    allCountries.forEach(function(country) {
      countryCodesArray.push(country.Iso2Code);
    });
    const countryCodesCollection = {};
    countryCodesCollection.CountryCodes = countryCodesArray;
    newOrg.countryCodesCollection = countryCodesCollection;
    const jobStatusesCollection = {};
    jobStatusesCollection.JobStatuses = JobStatuses;
    jobStatusesCollection.DefaultJobStatus = values.DefaultJobStatus;
    newOrg.JobStatusesCollection = jobStatusesCollection;
    createOrg(newOrg, { history, redirectUrl: "/orgs" });
  }

  render() {
    const {
      availableAryaFeatures,
      availableConnectFeatures,
      allCountries,
      licenceTypes,
      accountTypes
    } = this.props;
    return (
      <Skeleton
        active
        paragraph={{ rows: 16 }}
        loading={
          allCountries
            ? false
            : true
        }
      >
        <OrgFormComponent
          type="create"
          onCreate={this.createNewOrg}
          availableAryaFeatures={availableAryaFeatures}
          availableConnectFeatures={availableConnectFeatures}
          accountTypes={accountTypes}
          licenceTypes={licenceTypes}
        />
      </Skeleton>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationCreation);
