import React from "react";
import { Modal, Form, InputNumber, Input, message } from "antd";
import _ from "lodash";
import { validateNumberForPulse } from "../../utils/FieldValidators";
import "./AddJobCredits.scss";
const FormItem = Form.Item;

message.config({
  top: 100,
  duration: 10,
  maxCount: 1,
});
class AddJobCredits extends React.Component {
  constructor(props) {
    super(props);
    this.handleOk = this.handleOk.bind(this);
  }

  handleOk() {
    const { form, toggleAddJobCreditsVisibility, onAddJobCredits } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const formValues = _.cloneDeep(values);
        const { Email, BasicCredits, PremiumCredits } = formValues;
        var reqPayload = {
          Email,
          SubscriptionTypesAndCredits: {
            Basic: BasicCredits,
            Premium: PremiumCredits,
          },
        };
        if (BasicCredits <= 0 && PremiumCredits <= 0) {
          message.error("Please add credits");
          return;
        }
        onAddJobCredits(reqPayload);
        toggleAddJobCreditsVisibility();
      }
    });
  }

  render() {
    const {
      form,
      isVisible,
      toggleAddJobCreditsVisibility,
      orgDetails,
    } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Modal
        title={`Add Job Credits`}
        visible={isVisible}
        okText="Add"
        destroyOnClose={true}
        onOk={this.handleOk}
        onCancel={toggleAddJobCreditsVisibility}
      >
        <div className="modal-content">
          <FormItem label="Email" required={true}>
            {getFieldDecorator("Email", {
              rules: [{ required: true, message: "Email is required" }],
              initialValue: orgDetails.ContactEmail,
            })(<Input disabled={true} />)}
          </FormItem>
          <FormItem
            label={`Add more credits for Basic Plan`}
            colon={true}
            required={true}
          >
            {getFieldDecorator("BasicCredits", {
              rules: [
                {
                  required: true,
                  message: "Credits are required",
                },
                {
                  validator: validateNumberForPulse,
                },
              ],
              initialValue: 0,
            })(
              <InputNumber
                className="input-number"
                placeholder="Enter Credits"
              />
            )}
          </FormItem>
          <FormItem
            label={`Add more credits for Premium Plan`}
            colon={true}
            required={true}
          >
            {getFieldDecorator("PremiumCredits", {
              rules: [
                {
                  required: true,
                  message: "Credits are required",
                },
                {
                  validator: validateNumberForPulse,
                },
              ],
              initialValue: 0,
            })(
              <InputNumber
                className="input-number"
                placeholder="Enter Credits"
              />
            )}
          </FormItem>
        </div>
      </Modal>
    );
  }
}

const AddJobCreditsComponent = Form.create()(AddJobCredits);

export default AddJobCreditsComponent;
