import React from "react";
import { Input, Form, Modal } from "antd";
import { validatePassword } from "../../utils/FieldValidators";
import _ from "lodash";

class UserPasswordResetForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmDirty: false,
        };
        this.resetPassword = this.resetPassword.bind(this);
    }

    resetPassword() {
        const { resetPassword, form } = this.props;
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const formValues = _.cloneDeep(values);
                if (resetPassword) {
                    resetPassword(formValues["Password"]);
                }
            }
        });
    }

    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('Password')) {
          callback('Password and Confirm Password mismatch');
        } else {
          callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
          form.validateFields(['ConfirmPassword'], { force: true });
        }
        callback();
    };

    render() {
        const { form, visible, handleClose, currentUserDetails } = this.props;
        const { getFieldDecorator } = form;
        return (
            <div>
                <Modal
                    title={`Reset Password for ${currentUserDetails.FirstName}`}
                    visible={visible}
                    okText={"Reset"}
                    onOk={this.resetPassword}
                    onCancel={handleClose}
                >
                    <p className="creitlbl">Reset Password</p>

                    <Form.Item label="Password" hasFeedback className="company-email">
                        {getFieldDecorator("Password", {
                            rules: [
                                { required: true },
                                { validator: this.validateToNextPassword},
                                { validator: validatePassword }
                            ],
                            initialValue: ""
                        })(
                            <Input
                                type="password"
                                placeholder="Enter Password"
                            />
                        )}
                    </Form.Item>

                    <Form.Item label="Confirm password" hasFeedback className="company-email">
                        {getFieldDecorator("ConfirmPassword", {
                            rules: [
                                { required: true },
                                { validator: this.compareToFirstPassword }
                            ],
                            initialValue: ""
                        })(
                            <Input
                                type="password"
                                placeholder="Confirm Password"
                                onBlur={this.handleConfirmBlur}
                            />
                        )}
                    </Form.Item>
                </Modal>
            </div>
        )
    }
}

const UserPasswordResetFormComponent = Form.create()(UserPasswordResetForm);

export default UserPasswordResetFormComponent;