import React from "react";
import moment from "moment";
import { Switch, Icon, Col, Dropdown, Menu, Button, Tooltip } from "antd";
import "./UserListElement.scss";

export default class UserListElement extends React.Component {
  handleClick(mode) {
    const { showUserDetail, userDetails } = this.props;
    showUserDetail(mode, userDetails.Id);
  }

  showModal = () => {
    const { showPasswordResetModal, userDetails } = this.props;
    if (showPasswordResetModal) {
      showPasswordResetModal(userDetails);
    }
  };

  render() {
    const moreMenu = () => (
      <Menu onClick={() => null}>
        <Menu.Item key="copy" onClick={this.showModal}>
          Reset Password
        </Menu.Item>
      </Menu>
    );
    const { userDetails } = this.props;
    return (
      <div className="client-list-row">
        <div
          className="client-column-item user-list"
          role="presentation"
          onClick={() => this.handleClick("edit")}
        >
          <div className="client-name">
            <Tooltip title={`${userDetails.FirstName} ${userDetails.LastName}`}>
              <div className="full-name">
                {`${userDetails.FirstName} ${userDetails.LastName}`}{" "}
              </div>
            </Tooltip>
            <div>
              <span className="pilotact">{userDetails.RoleName}</span>
            </div>
          </div>
          <div className="job-id-time">
            <div className="job-id" display="inline">
              {userDetails.Id}
            </div>
            <div className="job-id-time-dot" />
            <div className="job-time">
              {moment
                .utc(userDetails.ModifiedDate)
                .local()
                .fromNow()}
            </div>
          </div>
        </div>
        <div className="contact-column-item user-list">
          <div className="client-email">{userDetails.Email}</div>
          <div className="contact-number">{userDetails.OfficePhone}</div>
        </div>
        {/* <div className="updated-column-item">
          <span className="candidate-card-info-icon">
            {/* <Icon type="setting" theme="filled" /> */}
            {/* NA
          </span>
        </div>
        <div className="updated-column-item">NA</div>
        <div className="updated-column-item">NA</div>  */}
        <div className="status-column-item user-list">
          <span className="status-text">
            {userDetails.IsActive ? "Active" : "InActive"}{" "}
          </span>
          <Switch checked={userDetails.IsActive} />
        </div>

        <Col xs={24} sm={3} className="user-list-actions">
          <Dropdown overlay={() => moreMenu()} placement="bottomRight">
            <Button className="more">
              <Icon style={{ transform: "rotate(90deg)" }} type="ellipsis" />
            </Button>
          </Dropdown>
        </Col>
      </div>
    );
  }
}
