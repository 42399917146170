import * as clientRepository from "../repository/clientRepository";
import { message } from "antd";
import _ from "lodash";

message.config({
    top: 100,
    duration: 1,
    maxCount: 1
});

function fetchClientDetails(clientId) {
    return dispatch => {
        clientRepository
            .fetchClientDetails(clientId)
            .then(response => {
                const payload = response.data;
                dispatch({
                    type: "CLIENT_DETAILS_FETCH_SUCCESS",
                    payload
                });
            })
            .catch(() => {
                dispatch({
                    type: "CLIENT_DETAILS_FETCH_FAILED",
                    payload: null
                });
            });
    };
}

function createClient(clientDetails) {
    return dispatch => {
        clientRepository
            .createClient(clientDetails)
            .then(response => {
                const payload = response.data;
                dispatch({
                    type: "CLIENT_CREATE_SUCCESS",
                    payload
                });
            })
            .catch(() => {
                dispatch({
                    type: "CLIENT_CREATE_FAILED",
                    payload: null
                });
            });
    };
}

function updateClient(clientId, clientDetails) {
    return dispatch => {
        clientRepository
            .updateClient(clientId, clientDetails)
            .then(response => {
                const payload = response.data;
                dispatch({
                    type: "CLIENT_CREATE_SUCCESS",
                    payload
                });
            })
            .catch(() => {
                dispatch({
                    type: "CLIENT_CREATE_FAILED",
                    payload: null
                });
            });
    };
}

function fetchClientList(orgId) {
    return dispatch => {
        clientRepository
            .fetchClientList(orgId)
            .then(response => {
                const payload = _.get(response, ["data", "Clients"], null);
                dispatch({
                    type: "CLIENT_LIST_FETCH_SUCCESS",
                    payload
                });
            })
            .catch(() => {
                dispatch({
                    type: "CLIENT_LIST_FETCH_FAILED",
                    payload: null
                });
            });
    };
}

function clearClientList() {
    return dispatch => {
        dispatch({
            type: "CLEAR_CLIENT_LIST",
            payload: null
        });
    }
}

function fetchSubsdiaryCompanies() {
    return dispatch => {
        clientRepository
            .fetchSubsdiaryCompanies()
            .then(response => {
                const payload = response.data;
                dispatch({
                    type: "SET_SUBSDIARIES",
                    payload
                });
            })
            .catch(() => {
                dispatch({
                    type: "SET_SUBSDIARIES",
                    payload: null
                });
            });
    };
}

function fetchCompetitorCompanies() {
    return dispatch => {
        clientRepository
            .fetchCompetitorCompanies()
            .then(response => {
                const payload = response.data;
                dispatch({
                    type: "SET_COMPETITOR_COMPANIES",
                    payload
                });
            })
            .catch(() => {
                dispatch({
                    type: "SET_COMPETITOR_COMPANIES",
                    payload: null
                });
            });
    };
}

function fetchIndustries() {
    return dispatch => {
        clientRepository
            .fetchIndustries()
            .then(response => {
                const payload = response.data;
                dispatch({
                    type: "SET_INDUSTRIES",
                    payload
                });
            })
            .catch(() => {
                dispatch({
                    type: "SET_INDUSTRIES",
                    payload: null
                });
            });
    };
}

export { createClient, updateClient, fetchClientList, fetchClientDetails, clearClientList, fetchSubsdiaryCompanies, fetchCompetitorCompanies, fetchIndustries };