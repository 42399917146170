import React from "react";
import TimeFilter from "./TimeFilter";
import "./Dashboard.scss";

class EmailsDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromTime: "now-1w",
      toTime: "now"
    };
  }
  onTimeFilter = (fromTime, toTime) => {
    this.setState({
      fromTime,
      toTime
    });
  };
  render() {
    const { timeFormat } = this.props;
    const { fromTime, toTime } = this.state;
    return (
      <div className="dashboard">
        <div className="kibana-aryacore-iframe">
          <div className="date-filter-wrapper">
            <div className="heading">Emails sent per organization</div>
            <TimeFilter
              onTimeFilter={this.onTimeFilter}
              timeFormat={timeFormat}
            />
          </div>
          <iframe
            src={`https://search-logs-slp74umkdlzbe4unt7y6jzp2yu.us-east-1.es.amazonaws.com/_plugin/kibana/app/kibana#/visualize/edit/3e3c20b0-2d46-11ea-a03a-55cad581f776?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3A'${fromTime}'%2Cmode%3Aabsolute%2Cto%3A'${toTime}'))`}
            height="600"
            width="100%"
            title="emails"
          ></iframe>
        </div>

        <div className="kibana-aryacore-iframe">
          <div className="date-filter-wrapper">
            <div className="heading">Email Errors</div>
            <TimeFilter
              onTimeFilter={(from, to) => this.onTimeFilter(from, to, 1)}
              timeFormat={timeFormat}
            />
          </div>
          <iframe
            src={`https://search-logs-slp74umkdlzbe4unt7y6jzp2yu.us-east-1.es.amazonaws.com/_plugin/kibana/app/kibana#/visualize/edit/1dd26fc0-3dca-11ea-95e6-b518dc8b5851?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3A'${fromTime}'%2Cmode%3Aabsolute%2Cto%3A'${toTime}'))`}
            height="1500px"
            width="100%"
            title="email-errors"
          ></iframe>
        </div>

      </div>
    );
  }
}

export default EmailsDashboard;
