import React from 'react';
import { PortalSubTabHeader } from './PortalSubTabHeader/PortalSubTabHeader';
import { PortalCardView } from './PortalCardView/PortalCardView';
import { PortalListView } from './PortalListView/PortalListView';
import styles from './PassiveChannel.module.scss';

export function PassiveChannel(props){
    const {
        title,
        subTitle,
        portalsUnderChannel,
        updateSourceConfig,
        orgId,
        tabkey,
        createSourceConfig,
        sourceConfig
    } = props;

    return(
    <div className={styles.passiveChannelComponent}>
        <PortalSubTabHeader 
            title={title} 
            subTitle={subTitle} 
            isLogoVisible
            logoPath='/static/Images/Passive.svg'
        />
        {portalsUnderChannel?.map(source =>
            <PortalCardView 
                key={source?.Id}
                source={source} 
                updateSourceConfig={updateSourceConfig}
                orgId={orgId}
                tabkey={tabkey}
            />
        )}
        <PortalListView 
            portals={sourceConfig} 
            addPortal={createSourceConfig}
            orgId={orgId}
            tabkey={tabkey}
        />
    </div>);
}