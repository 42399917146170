import React from "react";
import { Row, Col, Empty, Affix } from "antd";
import "./OrgList.scss";
import OrgListElement from "./../OrgListElement/OrgListElement";

export default class OrgList extends React.Component {
  getOrgPortals = orgId => {
    const { orgConfigByIds } = this.props;
    if (!orgConfigByIds || !orgConfigByIds[orgId]) return [];
    const enabledPortals = orgConfigByIds[orgId]
      ?.filter(portal => portal.IsEnabled)
      ?.map(item => item.Source.Portal);
    return enabledPortals;
  };
  render() {
    const { 
      orgs, 
      orgStats, 
      planList,
      accountTypes
    } = this.props;

    return (
      <div id="job-table">
        <Affix offsetTop={64}>
          <Row id="table-heading">
            <Col xs={24} sm={4}>
              Name
            </Col>
            <Col xs={24} sm={2} className="org-textcenter">
              Licenses
            </Col>
            <Col xs={24} sm={2} className="org-textcenter">
              Portals
            </Col>
            <Col xs={24} sm={2} className="org-textcenter">
              ATS
            </Col>
            <Col xs={24} sm={2} className="org-textcenter">
              Clients
            </Col>
            <Col xs={24} sm={3} style={{ textAlign: "center" }}>
              Credit
            </Col>
            <Col xs={24} sm={2}>
              Expiry
            </Col>
            <Col xs={24} sm={3}>
              Activated Jobs
            </Col>
            <Col xs={24} sm={2}>
              Status
            </Col>
            <Col xs={24} sm={2}>
              Actions
            </Col>
          </Row>
        </Affix>
        {orgs?.length ? (
          orgs?.map(org => (
            <OrgListElement
              orgStat={orgStats[org.Id]}
              org={org}
              key={org.Id}
              plan={planList[org.Guid]}
              enabledPortals={this.getOrgPortals(org.Id)}
              accountTypes={accountTypes}
            />
          ))
        ) : (
          <Empty
            image={
              <img
                src={`${process.env.PUBLIC_URL}/static/Images/empty-jobs.svg`}
                alt="orgStatus"
                className="job-list-empty"
              />
            }
            description={
              <span>
                <span className="job-list-no-job-found">
                  {" "}
                  No Organizations found
                </span>
                <br />
                <span className="job-list-no-job-found-message">
                  All the organizations appear here
                </span>
              </span>
            }
          />
        )}
      </div>
    );
  }
}
