import React from "react";
import moment from "moment";
import _ from "lodash";
import { Switch, Tooltip } from "antd";
import "./ClientListElement.scss";

export default class ClientListElement extends React.Component {
  handleClick(mode) {
    const { showClientDetail, clientDetails } = this.props;
    showClientDetail(mode, clientDetails.Id);
  }

  render() {
    const { clientDetails } = this.props;
    const email = _.get(clientDetails, "Email", "NULL").trim();
    const phone = _.get(clientDetails, "Phone", "NULL").trim();
    const primaryContact = _.get(clientDetails, "PrimaryContact", "NULL").trim();
    const isEmailAvailable = !(email === "NULL" || email === "");
    const isPhoneAvailable =  !(phone === "NULL" || phone === "");
    return (
      <div className="client-list-row">
        <div
          className="client-column-item"
          role="presentation"
          onClick={() => this.handleClick("edit")}
        >
          <Tooltip title={`${clientDetails.Name}`} placement="topLeft">
            <div className="client-column-name">{`${clientDetails.Name}`}</div>
          </Tooltip>
          <div className="job-id-time">
            <div className="job-id" display="inline">{clientDetails.Id}</div>
            <div className="job-id-time-dot" />
            <div className="job-time">{moment
                .utc(clientDetails.ModifiedDate)
                .local()
                .fromNow()}</div>
          </div>
        </div>

        {!isEmailAvailable && !isPhoneAvailable ? (
          <div className="contact-column-item">NA</div>
        ) : (
          <div className="contact-column-item">
            <div className="client-email">{isEmailAvailable ? email : ""}</div>
            <div className="contact-number">{isPhoneAvailable ? phone : ""}</div>
          </div>
        )}

        {primaryContact === "NULL" || primaryContact === "" ? (
          <div className="contact-column-item">NA</div>
        ) : (
          <div className="contact-column-item">
            <div className="client-email">{primaryContact}</div>
          </div>
        )}
        {/* <div className="updated-column-item">
                    <div className="add-new-bubble">
                        <Button
                            className="share-button"
                            type="dashed"
                            icon="plus"
                        />
                    </div>
                </div> */}
        <div className="updated-column-item">{clientDetails.SourceCount}</div>

        <div className="status-column-item">
          <span className="status-text"></span>
          <Switch checked={clientDetails.IsActive} />
        </div>
      </div>
    );
  }
}
