import { apiV3Client } from "./baseRepository";

export function createClient(clientDetails) {
    return apiV3Client.post("clients", clientDetails);
}

export function updateClient(clientId, clientDetails) {
    return apiV3Client.put(`users/${clientId}`, clientDetails);
}

export function fetchClientList(orgId) {
    return apiV3Client.get(`clients?orgId=${orgId}`);
}

export function fetchClientDetails(clientId) {
    return apiV3Client.get(`clients/${clientId}`);
}

export function fetchSubsdiaryCompanies() {
    return apiV3Client.get(`clients`);
}

export function fetchCompetitorCompanies() {
    return apiV3Client.get(`clients`);
}
export function fetchIndustries() {
    return apiV3Client.get(`clients`);
}