import React from "react";
import { Modal, Table, Button, Empty } from "antd";

const PendingCreditRequestModel = (props) => {
  const {
    isRequestedCreditModelVisible,
    columns,
    currentPendingRequest,
    toggleRequestedCreditModelVisibility,
  } = props;

  const hasPendingRequests = currentPendingRequest?.length > 0;

  return (
    <Modal
      title={"Requested Credits"}
      onCancel={toggleRequestedCreditModelVisibility}
      zIndex={2050}
      width={hasPendingRequests ? 1000 : 600}
      visible={isRequestedCreditModelVisible}
      okText="Save"
      destroyOnClose
      centered
      footer={[
        <Button key="back" onClick={toggleRequestedCreditModelVisibility}>
          Close
        </Button>,
      ]}
    >
      {hasPendingRequests ? (
        <Table
          pagination={false}
          dataSource={currentPendingRequest}
          columns={columns}
          scroll={{ y: 240 }}
        />
      ) : (
        <div>
          <Empty description={<div>No request available</div>} />
        </div>
      )}
    </Modal>
  );
};

export default PendingCreditRequestModel;
