import React from "react";
import { Button } from "antd";
import * as ReactRedux from "react-redux";
import signOutAction from "../../actions/authActions";
import styles from "./UnauthorizedScreen.module.scss";

function UnauthorizedScreen() {
  const dispatch = ReactRedux.useDispatch();

  const signout = () => {
    dispatch(signOutAction());
  };

  return (
    <div className={styles.notAuthorizedWrapper}>
      <div className={styles.notAuthorizedLogo}>
        <div className={styles.notAuthorized}>
          <div className={styles.title}>
            You are unauthorized to access this Application
          </div>
          <div className={styles.subtitle}>
            Please contact your administrator or{" "}
            <a href="mailto:support@leoforce.com">support@leoforce.com</a> for
            more information.
          </div>

          <Button
            type="primary"
            shape="round"
            className={styles.unauthorizedLogoutButton}
            onClick={signout}
          >
            Back To Login
          </Button>
        </div>
        <div className={styles.logo}>
          <img
            src={`${process.env.PUBLIC_URL}/static/Images/aryalogo-dark.svg`}
            alt="arya logo"
          />
        </div>
      </div>
    </div>
  );
}

export default UnauthorizedScreen;
