import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as ClientActions from "../../actions/clientActions";
import { getClientList } from "../../reducers/clientReducer";
import { getOrgDetails } from "../../reducers/orgReducer";
import "./ClientList.scss";
import ClientListHeader from "../../components/ClientListHeader/ClientListHeader";
import ClientListComponent from "../../components/ClientList/ClientList";
import ClientDetailContainer from "../ClientDetail/ClientDetail";
import { Skeleton } from "antd";

const mapStateToProps = state => ({
  clientList: getClientList(state),
  orgDetails: getOrgDetails(state)
});

const mapDispatchToProps = {
  fetchClientList: ClientActions.fetchClientList,
  clearClientList: ClientActions.clearClientList
};
class ClientList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientDrawerVisibility: false,
      drawerMode: "",
      currentClientId: null
    };
    this.onClickOpenClientDrawer = this.onClickOpenClientDrawer.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
  }

  componentDidMount() {
    const { fetchClientList, match } = this.props;
    const { orgId } = match.params;
    fetchClientList(orgId);
  }

  onClickOpenClientDrawer(drawerMode, clientId) {
    this.setState({ clientDrawerVisibility: true });
    this.setState({ drawerMode: drawerMode });
    if (clientId) {
      this.setState({ currentClientId: clientId });
    }
  }

  handleDrawerClose() {
    this.setState({ clientDrawerVisibility: false });
    this.setState({ drawerMode: "" });
    this.setState({ currentClientId: null });
  }


  showClientDetailsConatiner() {
    if (!this.state.clientDrawerVisibility) {
      return null;
    }
    const { orgDetails } = this.props;
    return (
      <ClientDetailContainer
        handleClose={this.handleDrawerClose}
        mode={this.state.drawerMode}
        visible={this.state.clientDrawerVisibility}
        orgDetails={orgDetails}
        clientId={this.state.currentClientId} />
    );
  }

  componentWillUnmount() {
    const { clearClientList } = this.props;
    clearClientList();
  }

  render() {
    const { clientList } = this.props;
    return (
      <div className="client-list">
        <ClientListHeader openClientDrawer={this.onClickOpenClientDrawer} />
        <Skeleton
          active
          paragraph={{ rows: 8 }}
          loading={clientList ? false : true}>
          <ClientListComponent
            clientList={clientList}
            showClientDetail={this.onClickOpenClientDrawer} />
          {this.showClientDetailsConatiner()}
        </Skeleton>
      </div>

    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ClientList)
);
