import React from "react";
import {
  Col,
  Button,
  Radio,
  Row,
  Select,
  Input,
  Form,
  message,
  Tooltip,
  Divider,
} from "antd";
import FeaturesSlider from "../FeaturesSlider/FeaturesSlider";
import "./OrganizationDetails.scss";
import _ from "lodash";
import moment from "moment";
import { getLicenceName } from "../../utils/PortalUtils";

const FormItem = Form.Item;
const { Option } = Select;

message.config({
  top: 100,
  duration: 10,
  maxCount: 1,
});

class OrganizationDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countryRadioValue: "All",
      jobStatuses: [],
      uniqueJobStatusValues: [],
      orgAryaFeatures: [],
      orgConnectFeatures: [],
      orgApplicationType: props.orgDetails?.OrgJobViewURL?.includes("{interested_link_job_guid}") ? "Interest" : "NewRedirect",
    };
  }

  onSave() {
    const { form, handleSave } = this.props;
    const { jobStatuses, orgAryaFeatures, orgConnectFeatures } = this.state;
    let errMessage = null;
    jobStatuses.forEach((status) => {
      if (!status.AryaStatus) {
        errMessage =
          "Please map all the Job Statuses to either of the Arya Open or Close Status";
      }
    });
    form.validateFieldsAndScroll((err, values) => {
      if (!err && !errMessage) {
        const formValues = _.cloneDeep(values);
        if (
          !formValues.DefaultJobStatus &&
          jobStatuses &&
          jobStatuses.length > 0
        ) {
          errMessage = "Please select a default Job Status";
        }
        if (!errMessage) {
          handleSave(
            jobStatuses,
            orgAryaFeatures,
            orgConnectFeatures,
            formValues
          );
        }
      } else if (errMessage) {
        message.error(errMessage);
      }
    });
  }

  handleCountryRadioChange(e) {
    this.setState({ countryRadioValue: e.target.value });
  }

  enableAllConnectFeatures() {
    const { orgConnectFeatures } = _.cloneDeep(this.state);
    for (let i = 0; i < orgConnectFeatures.length; i++) {
      orgConnectFeatures[i].IsEnabled = true;
    }
    this.setState({ orgConnectFeatures });
  }

  disableAllConnectFeatures() {
    const { orgConnectFeatures } = _.cloneDeep(this.state);
    for (let i = 0; i < orgConnectFeatures.length; i++) {
      orgConnectFeatures[i].IsEnabled = false;
    }
    this.setState({ orgConnectFeatures });
  }

  changeOrgApplicationType = (value) => {
    this.setState({ orgApplicationType: value });
  }

  configureAODInfo = async () => {
    const { configureAOD, orgDetails } = this.props;
    const { orgApplicationType } = this.state;
    const payload = {
      OrgApplicationType: orgApplicationType,
    };
    await configureAOD(orgDetails.Id, payload);
  };

  validatePixel = async () => {
    const { fetchClickDetails } = this.props;
    const { aryacid } = this.state;

    const response = await fetchClickDetails(aryacid);

    if (response && response?.IsApplied) {
      message.success("Pixel is valid");
    } else {
      message.error("Pixel is invalid");
    }

  };

  generateAryaCIDAndRedirect = async () => {
    const { fetchTestPixelJobUrls, fetchCareerPortalClient, orgDetails } = this.props;

    const client = await fetchCareerPortalClient(orgDetails.Id);
    if (!client) {
      message.error("CareerPortal Client not configured for this organization");
      return;
    }
    const jobUrls = await fetchTestPixelJobUrls(client.ClientId);

    if (jobUrls?.ViewUrl) {
      const aryacidMatch = jobUrls.ViewUrl.match(/aryacid=([^&]+)/);
      const aryacid = aryacidMatch ? aryacidMatch[1] : null;

      if (aryacid) {
        this.setState({ aryacid });
      }
      const url = new URL(jobUrls.ViewUrl);

      const internalDomains = ["goarya.com", "mycareers.net", "mycareers-qa.net"];
      // Check if the hostname ends with any of the internal domains
      if (internalDomains.some(domain => url.hostname.endsWith(domain))) {
        message.error("Pixel testing is not allowed on internal domain : " + url.hostname);
        return;
      }

      url.searchParams.set("source", "TestPixelURL");
      this.setState({ isPixelValidationEnabled: true });
      window.open(url, "_blank");
    } else {
      message.error("External Job Posting URL not configured for this organization");
    }
  };

  handleAryaFeatureChange(access, featureName) {
    const { orgAryaFeatures } = _.cloneDeep(this.state);
    for (let i = 0; i < orgAryaFeatures.length; i++) {
      if (orgAryaFeatures[i].Name === featureName) {
        orgAryaFeatures[i].IsEnabled = access.IsEnabled;
        orgAryaFeatures[i].IsAllowed = access.IsAllowed;
        if (featureName === "Connect" && !access.IsEnabled) {
          this.disableAllConnectFeatures();
        } else if (featureName === "Connect" && access.IsEnabled) {
          this.enableAllConnectFeatures();
        }
      }
    }
    this.setState({ orgAryaFeatures });
  }

  handleConnectFeatureChange(access, featureName) {
    const { orgConnectFeatures } = _.cloneDeep(this.state);
    for (let i = 0; i < orgConnectFeatures.length; i++) {
      if (orgConnectFeatures[i].Name === featureName) {
        orgConnectFeatures[i].IsEnabled = access.IsEnabled;
        orgConnectFeatures[i].IsAllowed = access.IsAllowed;
      }
    }
    this.setState({ orgConnectFeatures });
  }

  componentWillMount() {
    const {
      jobStatuses,
      authenticatedCountries,
      orgAryaFeatures,
      orgConnectFeatures,
    } = this.props;
    let uniqueJobStatuses = [];
    if (jobStatuses && jobStatuses.length > 0) {
      for (let i = 0; i < jobStatuses.length; i++) {
        jobStatuses[i].IsActive = true;
        uniqueJobStatuses.push(jobStatuses[i].Name.toLowerCase());
      }
      this.setState({
        jobStatuses: jobStatuses,
        uniqueJobStatusValues: uniqueJobStatuses,
      });
    }
    if (authenticatedCountries && authenticatedCountries.length > 0) {
      this.setState({ countryRadioValue: "Manual" });
    }
    if (orgAryaFeatures && orgAryaFeatures.length > 0) {
      this.setState({ orgAryaFeatures: orgAryaFeatures });
    }
    if (orgConnectFeatures && orgConnectFeatures.length > 0) {
      this.setState({ orgConnectFeatures: orgConnectFeatures });
    }
  }

  onJobStatusSelect(value, aryaStatus = "") {
    const { jobStatuses } = this.state;
    const { uniqueJobStatusValues } = this.state;
    let isNew = true;

    if (jobStatuses && jobStatuses.length > 0) {
      if (uniqueJobStatusValues.indexOf(value.toLowerCase()) + 1) {
        let index = uniqueJobStatusValues.indexOf(value.toLowerCase());
        isNew = false;
        jobStatuses[index].IsActive = true;
        jobStatuses[index].AryaStatus = aryaStatus;
        jobStatuses.splice(index, 1, jobStatuses[index]);
        uniqueJobStatusValues.splice(index, 1, value.toLowerCase());
      }
    }

    if (isNew) {
      const newJobStatus = {};
      const newUniqueJobStatusValues = value.toLowerCase();
      newJobStatus.Name = value;
      newJobStatus.IsActive = true;
      newJobStatus.AryaStatus = aryaStatus;
      jobStatuses.push(newJobStatus);
      uniqueJobStatusValues.push(newUniqueJobStatusValues);
    }

    this.setState({ jobStatuses: jobStatuses }, () => {
      const { jobStatuses } = this.state;
      const FieldValues = [];
      const openFieldValues = [];
      const closedFieldValues = [];

      jobStatuses.forEach((status) => {
        if (status.IsActive) {
          FieldValues.push(status.Name);
          if (status.AryaStatus === "Open") {
            openFieldValues.push(status.Name);
          } else if (status.AryaStatus === "Closed") {
            closedFieldValues.push(status.Name);
          }
        }
      });
      this.props.form.setFieldsValue({
        JobStatuses: FieldValues,
        AryaOpenStatuses: openFieldValues,
        AryaCloseStatuses: closedFieldValues,
      });
      if (aryaStatus === "Closed") {
        this.props.form.setFieldsValue({
          DefaultJobStatus: _.difference(FieldValues, closedFieldValues)[0],
        });
      }
    });
    this.setState({ uniqueJobStatusValues: uniqueJobStatusValues });
  }

  onJobStatusDeSelect(value) {
    const { jobStatuses } = this.state;
    const { uniqueJobStatusValues } = this.state;
    for (let i = 0; i < jobStatuses.length; i++) {
      if (jobStatuses[i].Name === value) {
        jobStatuses[i].IsActive = false;
        jobStatuses.splice(i, 1, jobStatuses[i]);
        uniqueJobStatusValues.splice(i, 1, value.toLowerCase());
      }
    }
    this.setState({ jobStatuses: jobStatuses }, () => {
      const { jobStatuses } = this.state;
      const FieldValues = [];
      const openFieldValues = [];
      const closedFieldValues = [];

      jobStatuses.forEach((status) => {
        if (status.IsActive) {
          FieldValues.push(status.Name);
          if (status.AryaStatus === "Open") {
            openFieldValues.push(status.Name);
          } else if (status.AryaStatus === "Closed") {
            closedFieldValues.push(status.Name);
          }
        }
      });
      this.props.form.setFieldsValue({
        JobStatuses: FieldValues,
        AryaOpenStatuses: openFieldValues,
        AryaCloseStatuses: closedFieldValues,
        DefaultJobStatus: _.difference(FieldValues, closedFieldValues)[0],
      });
    });
    this.setState({ uniqueJobStatusValues: uniqueJobStatusValues });
  }

  onOpenStatusSelect(value) {
    this.onJobStatusSelect(value, "Open");
  }

  onAryaJobStatusDeSelect(value) {
    const { jobStatuses } = this.state;
    for (let i = 0; i < jobStatuses.length; i++) {
      if (jobStatuses[i].Name === value) {
        jobStatuses[i].AryaStatus = "";
        jobStatuses.splice(i, 1, jobStatuses[i]);
      }
    }
    this.setState({ jobStatus: jobStatuses });
  }

  onCloseStatusSelect(value) {
    this.onJobStatusSelect(value, "Closed");
  }

  displayOrgAryaFeatures() {
    const { orgConfiguration } = this.props;
    const { orgAryaFeatures } = this.state;
    const candidateMatchingJobsMaxRecencyInMonths =
      orgConfiguration?.CandidateMatchingJobsMaxRecencyInMonths !== undefined
        ? `${orgConfiguration.CandidateMatchingJobsMaxRecencyInMonths} ${orgConfiguration.CandidateMatchingJobsMaxRecencyInMonths === 1
          ? "month"
          : "months"
        }`
        : "Enter months";

    let configSwitches = [];
    configSwitches = orgAryaFeatures.map((feature, index) => (
      <div
        className={
          feature.Name === "CandidateMatchingJobs"
            ? "candidate-matching-job-feature"
            : "feature"
        }
        key={index}
      >
        <Col xs={24} sm={5}>
          {feature.Name}
        </Col>
        <FeaturesSlider
          onSliderChange={(access) =>
            this.handleAryaFeatureChange(access, feature.Name)
          }
          feature={feature}
          isSliderDisabled
        />
        {feature.Name === "CandidateMatchingJobs" && (
          <Input
            disabled={true}
            className="input-candidate-matching-job-feature"
            placeholder={candidateMatchingJobsMaxRecencyInMonths}
          />
        )}
      </div>
    ));
    return (
      <div>
        <div>
          <Divider />
          <Row gutter={24}>
            <Col span={5}>
              <div className="feature-label">Arya Features</div>
            </Col>
          </Row>
          <Divider />
        </div>
        {configSwitches}
      </div>
    );
  }

  displayOrgConnectFeatures() {
    const { orgConnectFeatures, orgAryaFeatures } = this.state;
    const orgAryaConnectFeature = orgAryaFeatures.find(
      (feature) => feature.Name === "Connect"
    );
    let configSwitches = [];
    configSwitches = orgConnectFeatures.map((feature, index) => (
      <Tooltip
        placement="right"
        title={orgAryaConnectFeature.IsEnabled ? "" : "Connect is disabled"}
        key={feature.Name}
      >
        <div className="feature">
          <Col xs={24} sm={5}>
            {feature.Name}
          </Col>

          <FeaturesSlider
            onSliderChange={(access) =>
              this.handleConnectFeatureChange(access, feature.Name)
            }
            feature={feature}
            isSliderDisabled
          />
        </div>
      </Tooltip>
    ));
    return (
      <div>
        <div>
          <Divider />
          <Row gutter={24}>
            <Col span={8}>
              <div className="feature-label">Arya Connect Features</div>
            </Col>
          </Row>
          <Divider />
        </div>
        {configSwitches}
      </div>
    );
  }

  render() {
    const {
      orgDetails,
      orgStat,
      allCountries,
      defaultJobStatus,
      authenticatedCountries,
      form,
      licenceTypes,
    } = this.props;
    const { getFieldDecorator } = form;
    const { jobStatuses, countryRadioValue, orgApplicationType } = this.state;

    const displayedAryaOpenStatusNames = [];
    const displayedAryaCloseStatusNames = [];
    const jobStatusesNames = [];

    const licenceType = getLicenceName(orgDetails?.LicenceTypeId, licenceTypes);

    const allCountryNames = [];
    if (allCountries && allCountries.length > 0) {
      for (let i = 0; i < allCountries.length; i++) {
        allCountryNames.push(allCountries[i].Name);
      }
    }
    const authCountryNames = [];
    if (authenticatedCountries && authenticatedCountries.length > 0) {
      for (let i = 0; i < authenticatedCountries.length; i++) {
        authCountryNames.push(authenticatedCountries[i].Name);
      }
    }

    let countryMenuList = null;
    let jobStatusMenuList = [];
    let aryaOpenStatusesOptions = [];
    let aryaCloseStatusesOptions = [];
    let aryaDefaultStatusesOptions = [];
    if (jobStatuses && jobStatuses.length > 0) {
      for (let i = 0; i < jobStatuses.length; i++) {
        if (jobStatuses[i].IsActive === true) {
          const jobStatus = jobStatuses[i];

          if (jobStatus.AryaStatus !== "Closed") {
            aryaDefaultStatusesOptions.push(
              <Option
                value={jobStatus.Name}
                key={`defaultJobStatus${i.toString()}`}
              >
                {jobStatus.Name}
              </Option>
            );
          }
          if (jobStatus.AryaStatus !== "Closed") {
            aryaOpenStatusesOptions.push(
              <Option
                value={jobStatus.Name}
                key={`openJobStatus${i.toString()}`}
              >
                {jobStatus.Name}
              </Option>
            );
            if (jobStatus.AryaStatus === "Open") {
              displayedAryaOpenStatusNames.push(jobStatus.Name);
            }
          }
          if (jobStatus.AryaStatus !== "Open") {
            aryaCloseStatusesOptions.push(
              <Option
                value={jobStatus.Name}
                key={`closeJobStatus${i.toString()}`}
              >
                {jobStatus.Name}
              </Option>
            );
            if (jobStatus.AryaStatus === "Closed") {
              displayedAryaCloseStatusNames.push(jobStatus.Name);
            }
          }
          if (jobStatus.IsActive) {
            jobStatusMenuList.push(
              <Option value={jobStatus.Name} key={`jobStatus${i.toString()}`}>
                {jobStatus.Name}
              </Option>
            );
          }
          jobStatusesNames.push(jobStatuses[i].Name);
        }
      }
    }

    if (allCountries) {
      countryMenuList = allCountries.map((countryOption, index) => (
        <Option value={countryOption.Name} key={`country${index.toString()}`}>
          {countryOption.Name}
        </Option>
      ));
    }

    const countrySelect = (
      <Select
        mode="multiple"
        disabled={countryRadioValue === "All" ? true : false}
        allowClear={countryRadioValue === "All" ? true : false}
        key="Industry"
        placeholder={
          countryRadioValue === "All"
            ? "Select Countries to exclude"
            : "Add Countries"
        }
        style={{ width: "100%" }}
      >
        {countryMenuList}
      </Select>
    );

    const jobStatusesInput = (
      <Select
        onSelect={(value) => this.onJobStatusSelect(value)}
        onDeselect={(value) => this.onJobStatusDeSelect(value)}
        mode="tags"
        style={{ width: "100%" }}
        placeholder="Add status"
        key="jobstatusMenuList"
      >
        {jobStatusMenuList}
      </Select>
    );

    const aryaOpenStatuses = (
      <Select
        onSelect={(value) => this.onOpenStatusSelect(value)}
        onDeselect={(value) => this.onAryaJobStatusDeSelect(value)}
        mode="tags"
        style={{ width: "100%" }}
        placeholder="Select Status"
        key="aryaOpenStatusesOptions"
      >
        {aryaOpenStatusesOptions}
      </Select>
    );

    const aryaCloseStatuses = (
      <Select
        onSelect={(value) => this.onCloseStatusSelect(value)}
        onDeselect={(value) => this.onAryaJobStatusDeSelect(value)}
        mode="tags"
        style={{ width: "100%" }}
        placeholder="Select Status"
        key="aryaCloseStatusesOptions"
      >
        {aryaCloseStatusesOptions}
      </Select>
    );

    const aryaDefaultStatuses = (
      <Select style={{ width: "100%" }} placeholder="Select Status">
        {aryaDefaultStatusesOptions}
      </Select>
    );

    return (
      <div className="profile-settings">
        <div className="orginfo-wrapper">
          <div className="org-deatils-div">
            <div className="licensecount">
              <div className="licensecounthead">{orgStat.Licenses}</div>
              <div className="licensecountitle">License Created</div>
            </div>
            <div className="licensecount">
              <div className="licensecounthead">{orgStat.Portals}</div>
              <div className="licensecountitle">Portal Integrated</div>
            </div>
            <div className="licensecount">
              <div className="licensecounthead">{orgStat.Vaults}</div>
              <div className="licensecountitle">ATS Integrated</div>
            </div>
            <div className="licensecount">
              <div className="licensecounthead">
                {moment.utc(orgDetails.SubscriptionExpiry).local().fromNow()}
              </div>
              <div className="licensecountitle">Expires in</div>
            </div>
          </div>

          <div className="orgeneralinfo">
            <div className="orginfohead">
              <span>General</span>
            </div>
            <div>
              <span>General information about this Organization</span>
            </div>
            <div>
              <Row>
                <Col xs={24} sm={7}>
                  <div className="orgmetatitle">Primary contacts name</div>
                  {orgDetails.ContactPerson}
                </Col>
                <Col xs={24} sm={7}>
                  <div className="orgmetatitle">Primary email </div>
                  {orgDetails.ContactEmail}
                </Col>
                <Col xs={24} sm={7}>
                  <div className="orgmetatitle">
                    Organization office address
                  </div>
                  {orgDetails.Address}
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={7}>
                  <div className="orgmetatitle">Organization Website</div>
                  {orgDetails.HomePageLink}
                </Col>
                <Col xs={24} sm={7}>
                  <div className="orgmetatitle">Job Limit</div>
                  {orgDetails.JobsLimit}
                </Col>
                <Col xs={24} sm={7}>
                  <div className="orgmetatitle">Default Miles</div>
                  {orgDetails.OrgLevelMiles}
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={7}>
                  <div className="orgmetatitle">Source Limit</div>
                  {orgDetails.SourceLimit}
                </Col>
                <Col xs={24} sm={7}>
                  <div className="orgmetatitle">Licence Type</div>
                  {licenceType}
                </Col>
              </Row>
            </div>
          </div>

          <div className="orgeneralinfo">
            <div className="orginfohead">
              <span>Country</span>
            </div>
            <div>
              <span>
                Add countries in which the organization wants to create Jobs.
                These countries will appear when creating a job
              </span>
            </div>

            <div style={{ margin: "20px 0px" }}>
              <FormItem colon={false}>
                {getFieldDecorator("CountryRadioCheck", {
                  initialValue: authCountryNames.length > 0 ? "Manual" : "All",
                })(
                  <Radio.Group
                    onChange={(e) => this.handleCountryRadioChange(e)}
                  >
                    <Radio value="All">Add All</Radio>
                    <Radio value="Manual">Add Manually</Radio>
                  </Radio.Group>
                )}
              </FormItem>
            </div>

            <FormItem colon={false}>
              {getFieldDecorator("AuthenticatedCountries", {
                initialValue:
                  countryRadioValue === "All"
                    ? allCountryNames
                    : authCountryNames,
              })(countrySelect)}
            </FormItem>
          </div>

          <div className="orgeneralinfo" style={{ border: "none" }}>
            <div className="Configure-Job-Status">
              <div className=" ">
                <div className="Configure-Job-title">Configure Job Status</div>
                <div className="contact-settings-description">
                  Add or remove job status and map them with the Arya status
                </div>
              </div>
              <Button onClick={() => this.onSave()} className="app-save">
                Save Changes
              </Button>
            </div>

            <div className="Configure-Job-Status-title">Add Status</div>

            <FormItem colon={false}>
              {getFieldDecorator("JobStatuses", {
                rules: [
                  { required: true, message: "Please enter Job Status!" },
                ],
                initialValue:
                  jobStatusesNames.length > 0 ? jobStatusesNames : [],
              })(jobStatusesInput)}
            </FormItem>

            <div className="Configure-Job-Status-title">Status Mapping</div>
            <Row gutter={24}>
              <Col span={3}>
                <Input disabled={true} placeholder="Open (Arya)" />
              </Col>
              <Col span={1} style={{ paddingTop: "8px" }}>
                =
              </Col>

              <Col span={8}>
                <FormItem colon={false}>
                  {getFieldDecorator("AryaOpenStatuses", {
                    initialValue:
                      displayedAryaOpenStatusNames.length > 0
                        ? displayedAryaOpenStatusNames
                        : [],
                  })(aryaOpenStatuses)}
                </FormItem>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={3}>
                <Input disabled={true} placeholder="Close (Arya)" />
              </Col>
              <Col span={1} style={{ paddingTop: "8px" }}>
                =
              </Col>

              <Col span={8}>
                <FormItem colon={false}>
                  {getFieldDecorator("AryaCloseStatuses", {
                    initialValue:
                      displayedAryaCloseStatusNames.length > 0
                        ? displayedAryaCloseStatusNames
                        : [],
                  })(aryaCloseStatuses)}
                </FormItem>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={3}>
                <Input disabled={true} placeholder="Default (Arya)" />
              </Col>
              <Col span={1}>=</Col>

              <Col span={8}>
                <FormItem colon={false}>
                  {getFieldDecorator("DefaultJobStatus", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter Default Job Status!",
                      },
                    ],
                    initialValue: defaultJobStatus ? defaultJobStatus : "",
                  })(aryaDefaultStatuses)}
                </FormItem>
              </Col>
            </Row>
            <Divider />
            <div className="Configure-Job-Status">
              <div className=" ">
                <div className="Configure-Job-title">Configure AOD</div>
                <div className="contact-settings-description">
                  Enable AOD configuration with Client Redirect / Arya Apply
                </div>
                <br />
                <Select
                  id="orgApplicationInfoType"
                  onChange={this.changeOrgApplicationType}
                  value={orgApplicationType}
                  style={{ marginRight: "10px", width: "200px" }}
                  disabled={orgDetails?.IsAODEnabled}
                  placeholder="Select Application Type"
                >
                  <Option value="Interest">Arya Apply</Option>
                  <Option value="NewRedirect">Client Redirect Apply</Option>
                </Select>
                <Button
                  type="primary"
                  className="download-tracking-info-btn"
                  onClick={this.configureAODInfo}
                  disabled={orgDetails?.IsAODEnabled}
                >
                  Configure AOD
                </Button>
              </div>

              <div className=" ">
                <div className="Configure-Job-title">Testing Pixel</div>
                <div className="contact-settings-description">
                  Test pixel configuration - (Please make sure the latest job is configured with an External Job Posting URL)
                </div>
                <br />
                <Button
                  onClick={this.generateAryaCIDAndRedirect}
                  type="primary"
                  style={{ marginRight: '10px', marginBottom: '4px' }}>
                  Test Pixel
                </Button>
                <Button
                  onClick={this.validatePixel}
                  disabled={!this.state?.isPixelValidationEnabled}
                  type="primary">
                  Validate Pixel
                </Button>
                <Input
                  disabled={true}
                  value={this.state.aryacid}
                  placeholder="Click Id generated via pixel"
                />
              </div>
            </div>
            <div>
              {this.displayOrgAryaFeatures()}
              {this.displayOrgConnectFeatures()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const OrgDetailsComponent = Form.create()(OrganizationDetails);

export default OrgDetailsComponent;
