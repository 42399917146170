import React from 'react';
import {ActiveChannel} from "../ActiveChannel";
import {PassiveChannel} from "../PassiveChannel";
import {DedicatedChannel} from "../DedicatedChannel";
import {filterSources} from '../../../utils/PortalUtils';

function getChannel(key,props){

    const {
        orgConfiguration,
        updateSourceConfig,
        match,
        createSourceConfig,
        sourceConfig
    } = props;

    const sources = orgConfiguration?.Sources;
    const {
      activeChannelSources,
      passiveChannelSource,
      defaultChannelSource
    } = filterSources(sources,['Source','Group']);

    const {
      activeChannelSources : activeChannelAllowedSources,
      passiveChannelSource : passiveChannelAllowedSources,
      defaultChannelSource : defaultChannelAllowedSources
    } = filterSources(sourceConfig,['Group']);

    const { orgId } = match.params;

    switch (key){
        case "active":
          return <ActiveChannel 
                    title="Arya Active Channel" 
                    subTitle="Configure portals for Active channel"
                    portalsUnderChannel={activeChannelSources}
                    updateSourceConfig={updateSourceConfig}
                    orgId={orgId}
                    tabkey="Active"
                    createSourceConfig={createSourceConfig}
                    sourceConfig={activeChannelAllowedSources}
                  />;
        case "passive":
          return <PassiveChannel 
                    title="Arya Passive Channel" 
                    subTitle="Configure portals for Passive channel"
                    portalsUnderChannel={passiveChannelSource}
                    updateSourceConfig={updateSourceConfig}
                    orgId={orgId}
                    tabkey="Passive"
                    createSourceConfig={createSourceConfig}
                    sourceConfig={passiveChannelAllowedSources}
                  />;
        case "default":
          return <DedicatedChannel 
                    title="Dedicated Portals Integrations" 
                    subTitle="Add Portals to link them to Arya"
                    portalsUnderChannel={defaultChannelSource}
                    updateSourceConfig={updateSourceConfig}
                    orgId={orgId}
                    tabkey="Default"
                    createSourceConfig={createSourceConfig}
                    sourceConfig={defaultChannelAllowedSources}
                  />;
        default:
          return <ActiveChannel/>;
      }
}

export function PortalView(props){
    const {tabKey} = props;
    return getChannel(tabKey,props);
}