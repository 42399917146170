import React from "react";
import { Button, Tooltip, Col } from "antd";
import FeaturesSlider from "../FeaturesSlider/FeaturesSlider";
import _ from "lodash";

class ConnectFeatures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orgConnectFeatures: [],
      saveButtonVisibility: false
    };
    this.onSave = this.onSave.bind(this);
    this.handleConnectFeatureChange = this.handleConnectFeatureChange.bind(
      this
    );
  }

  componentDidMount() {
    const { orgConnectFeatures } = this.props;
    this.setState({
      orgConnectFeatures
    });
  }

  handleConnectFeatureChange(access, featureName) {
    const { orgConnectFeatures } = _.cloneDeep(this.state);
    const index = orgConnectFeatures.findIndex(
      feature => feature.Name === featureName
    );
    orgConnectFeatures[index].IsEnabled = access.IsEnabled;
    orgConnectFeatures[index].IsAllowed = access.IsAllowed;
    this.setState({
      orgConnectFeatures,
      saveButtonVisibility: true
    });
  }

  onSave() {
    const { updateOrgConnectFeatures, orgDetails } = this.props;
    const { orgConnectFeatures } = this.state;
    const connectFeaturesCollection = { ConnectFeatures: orgConnectFeatures };
    updateOrgConnectFeatures(orgDetails.OrgGuid, connectFeaturesCollection);
    this.setState({
      saveButtonVisibility: false
    });
  }

  render() {
    const { orgAryaFeatures } = this.props;
    const { orgConnectFeatures, saveButtonVisibility } = this.state;
    const connectFeature = orgAryaFeatures.find(
      feature => feature.Name === "Connect"
    );
    const isConnectAllowed = _.get(connectFeature, "IsAllowed", false);
    return (
      <div className="contact-settings">
        <div className="contact-settings-admin">
          <div className="contact-settings-header">
            <div className="contact-settings-title">Connect</div>
            <div className="contact-settings-description">
              Enable and disable connect features for this org
            </div>
          </div>
          <Button
            className="app-save"
            disabled={!saveButtonVisibility}
            onClick={this.onSave}
          >
            Save Changes
          </Button>
        </div>
        <div className="consent-body">
          {orgConnectFeatures.map((feature, index) => (
            <Tooltip
              placement="right"
              title={isConnectAllowed ? "" : "Connect is disabled"}
              key={feature.Name}
            >
              <div className="consent-body-feature">
                <Col xs={24} sm={5}>
                  {feature.Name}
                </Col>
                <FeaturesSlider
                  onSliderChange={access =>
                    this.handleConnectFeatureChange(access, feature.Name)
                  }
                  feature={feature}
                  isSliderDisabled={!connectFeature.IsEnabled}
                />
              </div>
            </Tooltip>
          ))}
        </div>
      </div>
    );
  }
}

export default ConnectFeatures;
