import _ from "lodash";
import {
  SET_ELIGIBLE_SOURCES,
  UPDATE_SOURCE_CONFIG_ROW,
  UPDATE_SOURCE_CONFIG,
  SET_ACCOUNT_TYPES,
  SET_LICENCE_TYPES,
} from "../../src/actions/ActionsCreators/OrgActionCreator";

export default function OrgReducer(state = {}, action) {
  let newState;
  switch (action.type) {
    case "SET_ORG_DETAILS": {
      newState = _.cloneDeep(state);
      newState.orgDetails = action.payload;
      return newState;
    }

    case "SET_ORG_LIST": {
      newState = _.cloneDeep(state);
      newState.orgList = action.payload;
      return newState;
    }

    case "SET_ORG_COUNT": {
      newState = _.cloneDeep(state);
      newState.totalOrgCount = action.payload;
      return newState;
    }

    case "SET_ALL_COUNTRIES": {
      newState = _.cloneDeep(state);
      newState.allCountries = action.payload;
      return newState;
    }

    case "SET_AUTH_COUNTRIES": {
      newState = _.cloneDeep(state);
      newState.authenticatedCountries = action.payload;
      return newState;
    }

    case "SET_JOB_STATUSES": {
      newState = _.cloneDeep(state);
      newState.jobStatuses = action.payload;
      return newState;
    }

    case "SET_DEFAULT_JOB_STATUS": {
      newState = _.cloneDeep(state);
      newState.defaultJobStatus = action.payload;
      return newState;
    }

    case "SET_ORG_ARYA_FEATURES": {
      newState = _.cloneDeep(state);
      newState.orgAryaFeatures = action.payload;
      return newState;
    }

    case "SET_ORG_CONNECT_FEATURES": {
      newState = _.cloneDeep(state);
      newState.orgConnectFeatures = action.payload;
      return newState;
    }

    case "SET_AVAILABLE_ARYA_FEATURES": {
      newState = _.cloneDeep(state);
      newState.availableAryaFeatures = action.payload;
      return newState;
    }

    case "SET_BULK_ORG_CONFIG": {
      const { orgConfigByIds } = action.payload;
      return {
        ...state,
        orgConfigByIds,
      };
    }

    case "SET_ORG_STATS": {
      newState = _.cloneDeep(state);
      newState.orgStats = action.payload;
      return newState;
    }

    case "SET_ORG_STAT": {
      newState = _.cloneDeep(state);
      newState.orgStat = action.payload;
      return newState;
    }

    case "SET_PLAN_LIST": {
      newState = _.cloneDeep(state);
      newState.planList = action.payload;
      return newState;
    }

    case "SET_PLAN_DETAIL": {
      newState = _.cloneDeep(state);
      newState.plan = action.payload;
      return newState;
    }

    case "SET_AVAILABLE_CONNECT_FEATURES": {
      newState = _.cloneDeep(state);
      newState.availableConnectFeatures = action.payload;
      return newState;
    }

    case "SET_ORG_CONFIG": {
      newState = _.cloneDeep(state);
      newState.orgConfiguration = action.payload;
      return newState;
    }
    case "SET_AUDIO_SERVICE_PROVIDERS": {
      newState = _.cloneDeep(state);
      newState.audioServiceProviders = action.payload;
      return newState;
    }

    case "SET_MESSAGE_SERVICE_PROVIDERS": {
      newState = _.cloneDeep(state);
      newState.messageServiceProviders = action.payload;
      return newState;
    }

    case UPDATE_SOURCE_CONFIG: {
      newState = _.cloneDeep(state);
      const source = state?.orgConfiguration?.Sources ?? [];
      const index = _.findIndex(source, ["Id", action.payload.Id]);
      newState.orgConfiguration.Sources[index].IsEnabled = action?.payload
        ?.IsEnabled
        ? true
        : false;
      return newState;
    }

    case UPDATE_SOURCE_CONFIG_ROW: {
      newState = _.cloneDeep(state);
      const source = state?.orgConfiguration?.Sources ?? [];
      const index = _.findIndex(source, ["Id", action.payload.Id]);
      newState.orgConfiguration.Sources[index].IsMutating =
        action?.payload?.status;

      return newState;
    }

    case SET_ELIGIBLE_SOURCES: {
      newState = _.cloneDeep(state);
      newState.sourceConfiguration = action.payload;
      return newState;
    }

    case SET_ACCOUNT_TYPES: {
      newState = _.cloneDeep(state);
      newState.accountTypes = action.payload;
      return newState;
    }

    case SET_LICENCE_TYPES: {
      newState = _.cloneDeep(state);
      newState.licenceTypes = action.payload;
      return newState;
    }

    case "SET_PRODUCTVARIANT_DETAILS": {
      newState = _.cloneDeep(state);
      newState.CurrentOrgProductVariantDetails = action.payload;
      return newState;
    }
    case "SET_PENDING_REQUESTS": {
      newState = _.cloneDeep(state);
      newState.PendingRequests = action.payload;
      return newState;
    }

    default:
      return state;
  }
}

function getAllCountries(state) {
  return state.OrgReducer.allCountries;
}

function getOrgDetails(state) {
  return state.OrgReducer.orgDetails;
}

function getOrgList(state) {
  return state.OrgReducer.orgList;
}

function getBulkOrgConfiguration(state) {
  return state.OrgReducer.orgConfigByIds;
}

function getOrgStats(state) {
  return state.OrgReducer.orgStats;
}

function getOrgStat(state) {
  return state.OrgReducer.orgStat;
}

function getTotalOrgCount(state) {
  return state.OrgReducer.totalOrgCount;
}

function getAuthenticatedCountries(state) {
  return state.OrgReducer.authenticatedCountries;
}

function getJobStatuses(state) {
  return state.OrgReducer.jobStatuses;
}

function getOrgAryaFeatures(state) {
  return state.OrgReducer.orgAryaFeatures;
}

function getOrgConnectFeatures(state) {
  return state.OrgReducer.orgConnectFeatures;
}

function getAvailableAryaFeatures(state) {
  return state.OrgReducer.availableAryaFeatures;
}

function getAvailableConnectFeatures(state) {
  return state.OrgReducer.availableConnectFeatures;
}

function getAvailableAryaUserFeatures(state) {
  return state.OrgReducer.orgAryaFeatures;
}

function getAvailableConnectUserFeatures(state) {
  return state.OrgReducer.orgConnectFeatures;
}

function getOrgConfiguration(state) {
  return state.OrgReducer.orgConfiguration;
}
function getDefaultJobStatus(state) {
  return state.OrgReducer.defaultJobStatus;
}

function getPlanList(state) {
  return state.OrgReducer.planList;
}

function getPlan(state) {
  return state.OrgReducer.plan;
}

function getAudioServiceProviders(state) {
  return state.OrgReducer.audioServiceProviders;
}

function getMessageServiceProviders(state) {
  return state.OrgReducer.messageServiceProviders;
}

function getSourceConfiguration(state) {
  return state.OrgReducer.sourceConfiguration;
}

function getAccountTypes(state) {
  return state.OrgReducer.accountTypes;
}

function getLicenceTypes(state) {
  return state.OrgReducer.licenceTypes;
}

function getCurrentOrgProductVariantDetails(state) {
  return state.OrgReducer.CurrentOrgProductVariantDetails;
}

function getOrgGuid(state) {
  return state.OrgReducer?.orgDetails?.OrgGuid;
}
function getPendingRequests1(state) {
  return state.OrgReducer?.PendingRequests;
}

export {
  OrgReducer,
  getAllCountries,
  getOrgDetails,
  getOrgList,
  getBulkOrgConfiguration,
  getOrgAryaFeatures,
  getAuthenticatedCountries,
  getJobStatuses,
  getAvailableAryaFeatures,
  getOrgConnectFeatures,
  getAvailableConnectFeatures,
  getAvailableAryaUserFeatures,
  getAvailableConnectUserFeatures,
  getOrgConfiguration,
  getTotalOrgCount,
  getOrgStats,
  getOrgStat,
  getDefaultJobStatus,
  getPlanList,
  getPlan,
  getAudioServiceProviders,
  getMessageServiceProviders,
  getSourceConfiguration,
  getAccountTypes,
  getLicenceTypes,
  getCurrentOrgProductVariantDetails,
  getOrgGuid,
  getPendingRequests1,
};
