export const UPDATE_SOURCE_CONFIG = "UPDATE_SOURCE_CONFIG";
export const SET_FETCH_SOURCE_CONFIG_API_STATUS = "SET_FETCH_SOURCE_CONFIG_API_STATUS";
export const UPDATE_SOURCE_CONFIG_ROW = "UPDATE_SOURCE_CONFIG_ROW";
export const SET_ELIGIBLE_SOURCES = "SET_ELIGIBLE_SOURCES";
export const SET_ELIGIBLE_SOURCES_API_STATUS = "SET_ELIGIBLE_SOURCES_API_STATUS";
export const SET_FETCH_ORG_TRACKING_INFO_API_STATUS = "SET_FETCH_ORG_TRACKING_INFO_API_STATUS";
export const SET_ACCOUNT_TYPES = "SET_ACCOUNT_TYPES";
export const SET_LICENCE_TYPES = "SET_LICENCE_TYPES";

export const setSourceConfig = (payload) => ({
    type: UPDATE_SOURCE_CONFIG,
    payload,
  });

export const updateSourceConfigRow = (payload) => ({
  type: UPDATE_SOURCE_CONFIG_ROW,
  payload,
});

export const setFetchOrgConfigStatus = (payload) => ({
  type: SET_FETCH_SOURCE_CONFIG_API_STATUS,
  payload,
});


export const setEligibleSources = (payload) => ({
  type: SET_ELIGIBLE_SOURCES,
  payload,
});

export const setEligibleSourcesStatus = (payload) => ({
  type: SET_ELIGIBLE_SOURCES_API_STATUS,
  payload,
});

export const setOrgTrackingInfoStatus = (payload) => ({
  type: SET_FETCH_ORG_TRACKING_INFO_API_STATUS,
  payload,
});

export const setAccountTypes = (payload) => ({
  type: SET_ACCOUNT_TYPES,
  payload,
});

export const setLicenceTypes = (payload) => ({
  type: SET_LICENCE_TYPES,
  payload,
});

