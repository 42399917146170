import config from "../config/config";

function getProductVariant(variantName) {
  const productVariantDetails = config.Product.ProductVariants;
  const product = productVariantDetails.find(
    (variant) => variant.Name === variantName
  );
  return product ? product.Id : null;
}

export { getProductVariant };
