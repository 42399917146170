import React from "react";
import "./UserList.scss";
import UserListElement from "./../UserListElement/UserListElement";
import UserPasswordResetForm from "./../UserPasswordResetForm/UserPasswordResetForm";
import { Empty } from "antd";

export default class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      currentUserDetails: null,
    }
    this.showPasswordResetModal = this.showPasswordResetModal.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  resetPassword(passowrd) {
    const { resetPassword } = this.props;
    const { currentUserDetails } = this.state;
    if (resetPassword) {
      resetPassword(currentUserDetails, passowrd);
    }
    this.setState({ visible: false });
    this.setState({ currentUserDetails: null });
  }

  showPasswordResetModal(userDetails) {
    this.setState({ visible: true });
    this.setState({ currentUserDetails: userDetails })
  }

  handleCancel = e => {
    this.setState({ visible: false });
    this.setState({ currentUserDetails: null })
  };

  render() {
    const { showUserDetail, userList } = this.props;
    const { currentUserDetails } = this.state;
    let passowrdResetContent = null;
    if (this.state.visible) {
      passowrdResetContent = (
        <UserPasswordResetForm
          visible={this.state.visible}
          handleClose={this.handleCancel}
          resetPassword={this.resetPassword}
          currentUserDetails={currentUserDetails}
        />
      )
    }
    return (
      <div>
        <div className="client-list-column-headers">
          <div className="client-column-header user-list">User</div>
          <div className="contact-column-header user-list">Personal Contact</div>
          {/* <div className="updated-column-header">Connect</div>
          <div className="source-count-column-header">Caller Id</div>
          <div className="source-count-column-header">Text Message Number</div> */}
          <div className="status-column-header user-list">Status</div>
          <div className="actions-column-header">Actions</div>
        </div>
        {passowrdResetContent}

        {userList.length ? (
          userList.map(user => (
            <UserListElement
              userDetails={user}
              key={user.Id}
              showUserDetail={showUserDetail}
              showPasswordResetModal={this.showPasswordResetModal}
            />
          ))
        ) : (
            <Empty
              image={
                <img
                  src={`${
                    process.env.PUBLIC_URL
                    }/static/Images/empty-candidates.svg`}
                  alt=""
                  className="candidate-empty-list"
                />
              }
              description={
                <div>
                  <div className="candidate-list-no-candidates-found">
                    {" "}
                    No users Found
                </div>

                  <div className="candidate-list-no-candidates-found-message">
                    All users will appear here
                </div>
                </div>
              }
            />
          )}
      </div>
    );
  }
}
