import React from "react";
import { Input, Button, Icon } from "antd";
import { Link } from "react-router-dom";
import "./OrgListHeader.scss";

const { Search } = Input;
// const { Option } = Select;

export default class OrgListHeader extends React.Component {
  render() {
    const { onSearch, onChange } = this.props;
    return (
      <div className="job-list-header">
        {/* <div className="job-status">
          <Select
            size="large"
            className="job-status-filter"
            defaultValue={`Active`}
            dropdownClassName="jobstatus-dropdown floating-menu-popup"
          >
            <Option value={"Active"}>Active</Option>
            <Option value={"InActive"}>InActive</Option>
          </Select>
        </div> */}
        <div className="job-search">
          <Search 
            placeholder="Search" 
            size="default" 
            onSearch={onSearch} 
            onChange={onChange} 
            allowClear
          />
        </div>
        <Link to="/orgs/create">
          <div className="job-create">
            <Button className="add-job-button">
              <Icon
                type="plus-circle"
                theme="filled"
                className="add-job-icon"
              />
            </Button>
          </div>
        </Link>
      </div>
    );
  }
}
