import * as configRepository from "../repository/ConfigRepository";
import {
  setConfigApiStatus,
  setConfig,
} from "./ActionsCreators/ConfigActionsCreator";

export function fetchConfig() {
  return async (dispatch) => {
    try {
      dispatch(setConfigApiStatus("PENDING"));
      const response = await configRepository.fetchConfig();
      const payload = response.data;
      const configInfo = {
        CanAccessAccMgmtApp: payload.CanAccessAccMgmtApp,
        CanImpersonate: payload.CanImpersonate,
      };
      dispatch(setConfig(configInfo));
      dispatch(setConfigApiStatus("SUCCESS"));
    } catch (err) {
      dispatch(setConfigApiStatus("FAILED"));
      throw err;
    }
  };
}
