function validatePassword(rule, value, callback) {
  const regexCapital = /^(.*[A-Z].*)$/;
  const regexSmall = /^(.*[a-z].*)$/;
  const regexDigit = /^(.*[0-9].*)$/;
  const regexSpecial = /^(.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?].*)$/;
  const regexLength = /^(.*).{8,}$/;
  let callbackMessage = "";

  if (value.length > 20) {
    callbackMessage = "Length must be 8-20";
  } else if (!regexLength.test(value)) {
    callbackMessage = "Length must be 8-20";
  } else if (!regexCapital.test(value)) {
    callbackMessage = "Requires a uppercase letter";
  } else if (!regexSmall.test(value)) {
    callbackMessage = "Requires a lowercase letter";
  } else if (!regexDigit.test(value)) {
    callbackMessage = "Requires a digit";
  } else if (!regexSpecial.test(value)) {
    callbackMessage = "Requires a special character";
  }

  if (callbackMessage !== "") {
    callback(callbackMessage);
  } else callback();
}

function validateSourceLimit(rule, value, callback) {
  let valueToNumber = Number(value);
  if (value && (valueToNumber < 0 || !Number.isInteger(valueToNumber))) {
    callback("Enter valid source limit");
  } else {
    callback();
  }
}

function validateEmail(rule, value, callback) {
  const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
  if (!value || !value.trim().length || regex.test(value)) {
    callback();
  } else {
    callback("Please input valid Email");
  }
}

function validatePhone(rule, value, callback) {
  const regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
  if (!value || !value.trim().length || regex.test(value)) {
    callback();
  } else {
    callback("Invalid number. Please start with country code.");
  }
}

function validateUrl(rule, value, callback) {
  const regex =
    /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}[.]{0,1}/;
  if (!value || !value.trim().length || regex.test(value)) {
    callback();
  } else {
    callback("Input valid url");
  }
}

function validateNumber(rule, value, callback) {
  if (value <= 0) {
    callback("Enter valid number");
  } else {
    callback();
  }
}

function validateNumberForPulse(rule, value, callback) {
  if (value < 0) {
    callback("Enter valid number");
  } else {
    callback();
  }
}

function validatePhoneExcludingCountryCode(rule, value, callback) {
  const regex = /^\d{4,12}$/;
  const startWithRegex = /^\+/;
  if (!value.trim().length || regex.test(value)) {
    callback();
  } else if (startWithRegex.test(value)) {
    callback("Enter phone number excluding country code");
  } else {
    callback("Enter valid phone number");
  }
}

function validateNumberForMatchingJobs(rule, value, callback) {
  if (
    value !== "" &&
    (!/^\d+$/.test(value) || parseInt(value) <= 0 || parseInt(value) >= 1200)
  ) {
    callback("Enter a valid positive integer between 1 and 1199");
  } else {
    callback();
  }
}

export {
  validatePassword,
  validatePhone,
  validateSourceLimit,
  validateEmail,
  validateUrl,
  validateNumber,
  validatePhoneExcludingCountryCode,
  validateNumberForPulse,
  validateNumberForMatchingJobs,
};
