import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as UserActions from "../../actions/userActions";
import { getUserList, getTotalUsersCount } from "../../reducers/userReducer";
import { getOrgDetails } from "../../reducers/orgReducer";
import UserListHeader from "../../components/UserListHeader/UserListHeader";
import UserListComponent from "../../components/UserList/UserList";
import UserDetailContainer from "../UserDetail/UserDetail";
import { Skeleton, Pagination } from "antd";
import "./UserList.scss";

const mapStateToProps = state => ({
  userList: getUserList(state),
  totalUsersCount: getTotalUsersCount(state),
  orgDetails: getOrgDetails(state)
});

const mapDispatchToProps = {
  setUserList: UserActions.setUserList,
  fetchUserList: UserActions.fetchUserList,
  resetUserPassword: UserActions.resetUserPassword
};

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      userDrawerVisibility: false,
      drawerMode: "",
      currentUserId: null
    };
    this.onClickOpenUserDrawer = this.onClickOpenUserDrawer.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount() {
    const { fetchUserList, match } = this.props;
    const { orgId } = match.params;
    fetchUserList(1, orgId);
  }

  onPageChange(pageNumber) {
    const { setUserList, fetchUserList, match } = this.props;
    const { orgId } = match.params;
    const { searchTerm } = this.state;
    setUserList(null);
    fetchUserList(pageNumber, orgId, searchTerm);
    this.setState({ pageNumber });
  }

  onSearch() {
    const { searchTerm } = this.state;
    const { fetchUserList, setUserList, match } = this.props;
    const { orgId } = match.params;
    if (searchTerm.trim().length) {
      setUserList(null);
      fetchUserList(1, orgId, searchTerm);
    }
  }

  onSearchChange(event) {
    const { fetchUserList, setUserList, match } = this.props;
    const { orgId } = match.params;
    const searchTerm = event.target.value;
    this.setState({ searchTerm });
    if (!searchTerm) {
      setUserList(null);
      fetchUserList(1, orgId);
    }
  }

  onClickOpenUserDrawer(drawerMode, userId) {
    this.setState({ userDrawerVisibility: true });
    this.setState({ drawerMode: drawerMode });
    if (userId) {
      this.setState({ currentUserId: userId });
    }
  }

  handleDrawerClose() {
    this.setState({ userDrawerVisibility: false });
    this.setState({ drawerMode: "" });
    this.setState({ currentUserId: null });
  }

  showUserDetailsConatiner() {
    if (!this.state.userDrawerVisibility) {
      return null;
    }
    const { orgDetails } = this.props;
    return (
      <UserDetailContainer
        mode={this.state.drawerMode}
        handleClose={this.handleDrawerClose}
        visible={this.state.userDrawerVisibility}
        orgDetails={orgDetails}
        userId={this.state.currentUserId}
      />
    );
  }

  componentWillUnmount() {
    const { setUserList } = this.props;
    setUserList(null);
  }

  resetPassword(currentUserDetails, password) {
    const { resetUserPassword } = this.props;
    const userId = currentUserDetails.Id;
    const userPasswordRequestPaylod = {};
    userPasswordRequestPaylod.Username = currentUserDetails.Email;
    userPasswordRequestPaylod.Password = password;
    resetUserPassword(userId, userPasswordRequestPaylod);
  }

  render() {
    const { userList, totalUsersCount } = this.props;
    return (
      <div className="client-list">
        <UserListHeader
          openUserDrawer={this.onClickOpenUserDrawer}
          onSearch={this.onSearch}
          onChange={this.onSearchChange}
        />
        <Skeleton
          active
          paragraph={{ rows: 8 }}
          loading={userList ? false : true}
        >
          <UserListComponent
            userList={userList}
            showUserDetail={this.onClickOpenUserDrawer}
            resetPassword={this.resetPassword}
          />
          {this.showUserDetailsConatiner()}
        </Skeleton>
        <Pagination
          size="small"
          total={totalUsersCount ? totalUsersCount : 0}
          onChange={this.onPageChange}
          hideOnSinglePage={totalUsersCount ? totalUsersCount <= 15 : true}
          disabled={!userList}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserList)
);
