import React from 'react';
import { Select,Button } from 'antd';
import styles from './PortalListView.module.scss';

export function PortalListView(props){

    const { 
        portals,
        addPortal,
        orgId,
        tabkey
     } = props;

    let selectedPortal='';

    const { Option } = Select;
    const handleOnButtonClick = () =>{
        const payload ={
            sourceGroup: tabkey,
            portalIds: [selectedPortal],
            orgId : parseInt(orgId),
            userId: -1,
            IsEnabled: 1,
        };
        if(selectedPortal)
            addPortal(payload);
    }

    const onSelect =(value) =>{
        selectedPortal = value;
    }

    return (
        <div className={styles.addPortalPane}>
            <div className={styles.portalDropdown}>
                <Select
                    onSelect={onSelect}
                    placeholder="Add More Portals"
                    style={{ width: "100%" }}
                >
                {portals?.map( source => <Option key={source?.PortalId} value={source?.PortalId}>{source?.SourceName}</Option>)}
                </Select>
            </div>
            <div className={styles.portalAddBtn}>
                <Button onClick={handleOnButtonClick}>Add</Button>
            </div>
        </div>
    );
}