import _ from "lodash";

const QuickSearchConnectOptions = "QuickSearchConnectOptions";
const QuickSearchBulkConnect = "QuickSearchBulkConnect";
const AdvancedRefineIntel = "AdvancedRefineIntel";
const CandidateManualSearch = "CandidateManualSearch";
const QuickSearch = "QuickSearch";
const AdvanceSearchV2 = "AdvanceSearchV2";
const PrefetchAdvanceSearchResults = "PrefetchAdvanceSearchResults";
const MultiLocationSourcing = "MultiLocationSourcing";
const AryaAutoSourcing = "AryaAutoSourcing";
const AryaRanking = "AryaRanking";

const disableFeatureAccessConfig = { IsEnabled: false, IsAllowed: false };
const enableFeatureAccessConfig = { IsEnabled: true, IsAllowed: true };
const lockFeatureAccessConfig = { IsEnabled: true, IsAllowed: false };

const setFeatureAccess = ({ features, featureName, featureAccess }) => {
  const currentAccessFeature = features.find((x) => x.Name === featureName);
  currentAccessFeature.IsEnabled = featureAccess.IsEnabled;
  currentAccessFeature.IsAllowed = featureAccess.IsAllowed;
};

const handleFeatureToggles = ({ features, featureName, featureAccess }) => {
  const _features = _.cloneDeep(features);
  setFeatureAccess({ features: _features, featureName, featureAccess });
  switch (featureName) {
    case QuickSearchConnectOptions:
    case QuickSearchBulkConnect: {
      const quickSearchConnectOptions = _features.find(
        (feature) => feature.Name === QuickSearchConnectOptions
      );
      if (!quickSearchConnectOptions.IsEnabled)
        setFeatureAccess({
          features: _features,
          featureName: QuickSearchBulkConnect,
          featureAccess: disableFeatureAccessConfig,
        });
      break;
    }

    case AdvancedRefineIntel:
    case CandidateManualSearch: {
      const candidateManualSearch = _features.find(
        (feature) => feature.Name === CandidateManualSearch
      );
      if (candidateManualSearch.IsEnabled) {
        setFeatureAccess({
          features: _features,
          featureName: AdvancedRefineIntel,
          featureAccess: disableFeatureAccessConfig,
        });
        if (featureName === CandidateManualSearch) {
          setFeatureAccess({
            features: _features,
            featureName: QuickSearch,
            featureAccess: disableFeatureAccessConfig,
          });
        }
      }
      break;
    }
    case AdvanceSearchV2: {
      const advanceSearchV2 = _features.find(
        (feature) => feature.Name === AdvanceSearchV2
      );
      if (advanceSearchV2.IsEnabled) {
        setFeatureAccess({
          features: _features,
          featureName: PrefetchAdvanceSearchResults,
          featureAccess: disableFeatureAccessConfig,
        });
        setFeatureAccess({
          features: _features,
          featureName: AryaAutoSourcing,
          featureAccess: disableFeatureAccessConfig,
        });
        setFeatureAccess({
          features: _features,
          featureName: AryaRanking,
          featureAccess: lockFeatureAccessConfig,
        });
        setFeatureAccess({
          features: _features,
          featureName: MultiLocationSourcing,
          featureAccess: enableFeatureAccessConfig,
        });
      }
      break;
    }

    default:
      break;
  }
  return _features;
};

export { handleFeatureToggles };
