import React from 'react';
import { Icon } from 'antd';

const ClearanceJobsSvg = props => (
    <svg {...props} viewBox="0 0 264.7 169.58">
      <defs>
        <linearGradient id="prefix__a" y1={113.94} x2={264.41} y2={113.94} gradientUnits="userSpaceOnUse">
          <stop offset={0} stopColor="#ed3043" />
          <stop offset={0.15} stopColor="#c22127" />
          <stop offset={0.28} stopColor="#ed3043" />
          <stop offset={0.43} stopColor="#c22127" />
          <stop offset={0.58} stopColor="#ed3043" />
          <stop offset={0.71} stopColor="#c22127" />
          <stop offset={0.86} stopColor="#ed3043" />
          <stop offset={0.95} stopColor="#d5252e" />
          <stop offset={1} stopColor="#cc2127" />
          <stop offset={1} stopColor="#c22127" />
        </linearGradient>
        <linearGradient id="prefix__c" x1={132.5} y1={31.82} x2={264.55} y2={31.82} xlinkHref="#prefix__a" />
        <linearGradient
          id="prefix__b"
          x1={-663.05}
          y1={154.95}
          x2={-531.1}
          y2={154.95}
          gradientTransform="matrix(1 0 0 -1 663.5 186.67)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#00649c" />
          <stop offset={0.15} stopColor="#1f3d74" />
          <stop offset={0.28} stopColor="#00649c" />
          <stop offset={0.43} stopColor="#1f3d74" />
          <stop offset={0.58} stopColor="#00649c" />
          <stop offset={0.71} stopColor="#1f3d74" />
          <stop offset={0.86} stopColor="#00649c" />
          <stop offset={0.88} stopColor="#065d95" />
          <stop offset={0.95} stopColor="#18467d" />
          <stop offset={1} stopColor="#1f3d74" />
          <stop offset={1} stopColor="#c22127" />
        </linearGradient>
        <linearGradient id="prefix__d" x1={-663.05} y1={101.74} x2={-531.1} y2={101.74} xlinkHref="#prefix__b" />
      </defs>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <path
            d="M264.7 58.3L132.5 84.93v26.65a36.48 36.48 0 00-18.8-5c-15.9-.4-31.8 8.8-47.6 18.1-22 12.8-44.1 25.5-66.1 13.1v26.7c22 12.4 44.1-.4 66.1-13.1s44-25.85 66.4-13.15v26.25l132.2-26.58v-26.7l-132.1 26.61v-26.18L264.7 85z"
            fill="url(#prefix__a)"
          />
          <path fill="url(#prefix__c)" d="M132.5 32.03v26.2l132.2-26.12V5.42L132.5 32.03z" />
          <path
            d="M66.55 18.5c-22 13.2-44.1 25.9-66.1 13.6v26.2c22 12.8 44-.8 66-13.5s44.1-25.5 66.1-13.1V5a40.36 40.36 0 00-18.9-5c-15.8 0-31.3 9.3-47.1 18.5z"
            fill="url(#prefix__b)"
          />
          <path
            d="M132.55 84.7V58.4a36.16 36.16 0 00-18.9-5c-15.8 0-31.3 9.2-47.1 18.5-22 12.7-44.1 25.5-66.1 12.7v26.7c22 12.7 44.1-.4 66-13.5 22-12.7 44.1-25.5 66.1-13.1z"
            fill="url(#prefix__d)"
          />
        </g>
      </g>
    </svg>
  );

  export const ClearanceJobsIcon = props => <Icon component={ClearanceJobsSvg} {...props} />;