import React from "react";
import {
  InternalIcon,
  CareerBuilderIcon,
  DiceIcon,
  IndeedIcon,
  NexxtIcon,
  SocialIcon,
  MonsterIcon,
  CVLibraryIcon,
  ResumeLibraryIcon,
  EFinancialIcon,
  CWJobsIcon,
  TotalJobsIcon,
  JobsiteIcon
} from "../Icons/AryaIcons";
import { ClearanceJobsIcon } from '../Icons/ClearanceJobs';

const portalToIcon = (name, style) => {
  switch (name) {
    case "CAREERBUILDER":
      return <CareerBuilderIcon style={style} tag="Classic"/>;
    case "CAREERBUILDEREDGE":
      return <CareerBuilderIcon style={style} tag="Edge" />;
    case "CWJOBS":
      return <CWJobsIcon style={style} />;
    case "JOBSITE":
      return <JobsiteIcon style={style} />;
    case "TOTALJOBS":
      return <TotalJobsIcon style={style} />;
    case "DICE":
      return <DiceIcon style={style} />;
    case "INDEED":
      return <IndeedIcon style={style} />;
    case "NEXXT":
      return <NexxtIcon style={style} />;
    case "EFINANCIAL":
      return <EFinancialIcon style={style} />;
    case "SOCIAL":
      return <SocialIcon style={style} />;
    case "INTERNAL":
      return <InternalIcon style={style} />;
    case "MONSTER":
      return <MonsterIcon style={style} />;
    case "RESUMELIBRARY":
      return <ResumeLibraryIcon style={style} />;
    case "CVLIBRARY":
      return <CVLibraryIcon style={style} />;
    case "CLEARANCEJOBS":
      return <ClearanceJobsIcon/>;
    default:
      return <SocialIcon style={style} />;
  }
};

function getSourceImage(portal, iconStyle) {
  return portalToIcon(portal.toUpperCase(), iconStyle);
}

export { portalToIcon, getSourceImage };
