import _ from 'lodash';

export function filterSources(sources,path){
    
    const activeChannelSources =[];
    const passiveChannelSource =[];
    const defaultChannelSource =[];

    sources.forEach(source =>
    {
        const group = _.get(source,path);
        switch(group){
            case "Active":{
                activeChannelSources.push(source);
                break;
            }
            case "Passive":{
                passiveChannelSource.push(source);
                break;
            }
            default:{
                defaultChannelSource.push(source);
            }
        }
    });

    return {activeChannelSources, passiveChannelSource, defaultChannelSource};
}

export const portalToFilename = {
    CAREERBUILDER: "CareerBuilder-full.png",
    DICE: "dice-full.png",
    INDEED: "indeed-full.png",
    NEXXT: "next-full.png",
    EFINANCIAL: "efinancial-full.png",
    MONSTER: "monster-full.png",
    RESUMELIBRARY: "resume-full.png",
    CVLIBRARY: "cv-full.svg",
    CWJOBS: "cwjobs-full.svg",
    JOBSITE: "jobsite-full.svg",
    TOTALJOBS: "totaljobs-full.svg",
    INTERNAL: "internal.svg",
    SOCIAL: "social.svg"
  };

export function fetchSourceConfigPayload(payload){
    const {sourceGroup : SourceGroup, portalIds : PortalIds, orgId : OrgId,userId : UserId, id : Id,IsEnabled} = payload;

    const newPayload ={
        SourceGroup,
        PortalIds,
        OrgId,
        UserId,
        IsEnabled,
        Id
      }

    return newPayload;
}

export function getAccountName(accountTypeId,accountTypesList){
    const accountType = accountTypesList?.find((value) => value?.AccountTypeId === accountTypeId);
    return accountType?.AccountType;
}

export function getLicenceName(licenceTypeId,licenceTypesList){
    const licenceType = licenceTypesList?.find((value) => value?.LicenceTypeId === licenceTypeId);
    return licenceType?.LicenceType;
}