import React from "react";
import "./Navbar.scss";
import { Icon, Avatar, Badge, Popover } from "antd";
import signOutAction from "../../actions/authActions";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { getConfig } from "../../reducers/ConfigReducer";
import { getApiStatus } from "../../reducers/ApiStatusReducer";

const mapStateToProps = (state) => ({
  userConfig: getConfig(state),
  configApiStatus: getApiStatus(state, "configApiStatus"),
});

const mapDispatchToProps = {
  signOut: signOutAction,
};

const getActiveTab = (param) => {
  if (param.includes("/orgs")) {
    return "management";
  }
  if (param.includes("/dashboard")) {
    return "dashboard";
  }
  return "management";
};

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.signout = this.signout.bind(this);
  }

  signout() {
    const { signOut } = this.props;
    signOut();
  }
  render() {
    const { match, history, userConfig, configApiStatus } = this.props;
    const activeTab = getActiveTab(match.params[0]);
    if (match.url === "/unauthorized") {
      return null;
    }
    if (configApiStatus === "SUCCESS" && !userConfig.CanAccessAccMgmtApp) {
      history.push("/unauthorized");
      return null;
    }
    return (
      <div id="navbar-wrapper">
        <div className="navbar">
          <div className="logo" role="presentation">
            <Link to="/orgs">
              <img src="/static/Images/aryalogo.svg" alt="Arya" />
            </Link>
          </div>

          <div className="supportlogo">Support Management</div>
          <Link to="/orgs" role="presentation" className="navbar-jobs-tab">
            <div
              className={`navbar-menu-tab-item ${
                activeTab === "management" && "active"
              }`}
            >
              Management
            </div>
          </Link>
          <Link to="/dashboard" role="presentation">
            <div
              className={`navbar-menu-tab-item ${
                activeTab === "dashboard" && "active"
              }`}
            >
              Dashboard
            </div>
          </Link>
          <div>
            <span className="navbar-menu-item-icon">
              <span className="badged-icon">
                <Badge className="arya" />
              </span>
            </span>
          </div>
          <span className="navbar-menu-item-icon last">
            <Popover
              overlayClassName="navbar-popover"
              placement="bottomRight"
              content={
                <span onClick={this.signout} style={{ cursor: "pointer" }}>
                  LogOut
                </span>
              }
              trigger="click"
            >
              <Avatar style={{ color: "#ffffff" }}> </Avatar>
              <Icon type="down" className="arrow-icon" />
            </Popover>
          </span>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));

export { Navbar as NavbarWithoutRouter };
