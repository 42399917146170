import React from "react";
import { Slider } from "antd";

const checkPoints = {
  0: "Off",
  1: "Visible",
  2: "Unlock"
};
export default class FeaturesSlider extends React.Component {
  onSliderChange = value => {
    const { onSliderChange } = this.props;
    onSliderChange({
      IsEnabled: value === 1 || value === 2,
      IsAllowed: value === 2
    });
  };
  render() {
    const { feature, isSliderDisabled } = this.props;
    let currentCheckPoint = 0;
    if (feature && feature.IsEnabled) {
      currentCheckPoint = 1;
      if (feature.IsAllowed) {
        currentCheckPoint = 2;
      }
    }
    return (
      <Slider
        style={{ width: 200 }}
        defaultValue={0}
        value={currentCheckPoint}
        step={null}
        marks={checkPoints}
        tipFormatter={value => checkPoints[value]}
        onChange={this.onSliderChange}
        disabled={isSliderDisabled}
        min={0}
        max={2}
      />
    );
  }
}
