import React from "react";
import styles from "./NetworkErrorComponent.module.scss";

export default function NetworkErrorComponent() {
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div className={styles.networkError}>
      <div>
        <img
          src={`${process.env.PUBLIC_URL}/static/Images/network-error.svg`}
          alt="Network error"
          className={styles.networkErrorImg}
        />
      </div>
      <div className={styles.networkErrorTitle}>
        <span>Oops, something just went wrong</span>
      </div>
      <div className={styles.networkErrorMessage}>
        <span
          onClick={refreshPage}
          onKeyDown={refreshPage}
          tabIndex="-1"
          role="button"
        >
          Try refreshing the page
        </span>
      </div>
    </div>
  );
}
