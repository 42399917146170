import React from "react";
import { DatePicker, Form, Button } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

class TimeFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dates: []
    };
  }

  onChange = dates => {
    this.setState({
      dates
    });
  };

  handleClick = () => {
    const { onTimeFilter, form, timeFormat } = this.props;
    const { dates } = this.state;
    form.validateFieldsAndScroll(err => {
      if (!err) {
        const fromTime = moment(dates[0]._d).format(timeFormat);
        const toTime = moment(dates[1]._d).format(timeFormat);
        onTimeFilter(fromTime, toTime);
      }
    });
  };

  disabledDate = current => {
    // Can not select days before last two months
    return current && current < moment().subtract(2, "months");
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form className="date-filter">
        <Form.Item>
          {getFieldDecorator("RangePicker", {
            rules: [
              {
                required: true,
                message: "Please select a range"
              }
            ],
            initialValue: [moment().subtract(7, "days"), moment()]
          })(
            <RangePicker
              disabledDate={this.disabledDate}
              ranges={{
                Today: [moment().startOf("day"), moment()],
                "This Week": [moment().startOf("week"), moment()],
                "This Month": [moment().startOf("month"), moment()]
              }}
              showTime
              format="DD/MM/YYYY HH:mm:ss"
              onChange={this.onChange}
              // onOk={this.handleClick}
            />
          )}
        </Form.Item>

        <Button className="app-save date-filter-button" onClick={this.handleClick}>
          Filter
        </Button>
      </Form>
    );
  }
}

const TimeFilterComponent = Form.create()(TimeFilter);

export default TimeFilterComponent;
