import React from "react";
import "./OrganizationList.scss";
import OrgListHeader from "../../components/OrgListHeader/OrgListHeader";
import OrgList from "../../components/OrgList/OrgList";
import { Skeleton, Pagination } from "antd";
import * as OrgActions from "../../actions/orgActions";
import {
  getOrgList,
  getTotalOrgCount,
  getOrgStats,
  getPlanList,
  getBulkOrgConfiguration,
  getAccountTypes
} from "../../reducers/orgReducer";import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const mapStateToProps = state => ({
  orgList: getOrgList(state),
  totalOrgCount: getTotalOrgCount(state),
  orgStats: getOrgStats(state),
  planList: getPlanList(state),
  orgConfigByIds: getBulkOrgConfiguration(state),
  accountTypes: getAccountTypes(state),
});

const mapDispatchToProps = {
  fetchOrgList: OrgActions.fetchOrgList,
  clearOrgList: OrgActions.clearOrgList,
  clearOrgStats: OrgActions.clearOrgStats,
  clearPlanList: OrgActions.clearPlanList,
};

class OrganizationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10,
      pageNumber: 1,
    }
    this.onPageChange = this.onPageChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount() {
    const { 
      fetchOrgList
     } = this.props;
    const filter = { Size: 10, SortBy: "ModifiedDate", SortOrder: "Descending" };
    fetchOrgList(filter);
  }

  onPageChange(pageNumber, pageSize) {
    this.setState({ pageNumber, pageSize });
    const { fetchOrgList, clearOrgList, clearOrgStats, clearPlanList } = this.props;
    const { searchTerm } = this.state;
    const filter = {
      From: (pageNumber - 1) * pageSize,
      Size: pageSize,
      SortBy: "ModifiedDate",
      SortOrder: "Descending",
      SearchKeyword: searchTerm,
    };
    clearOrgList();
    clearOrgStats();
    clearPlanList();
    fetchOrgList(filter);
  }

  onSearch(){
    const { searchTerm } = this.state;
    const { fetchOrgList, clearOrgList, clearOrgStats, clearPlanList } = this.props;
    if (searchTerm.trim().length) {
      const filter = {
        Size: 10, 
        SortBy: "ModifiedDate", 
        SortOrder: "Descending",
        SearchKeyword: searchTerm,
      };
      clearOrgList();
      clearOrgStats();
      clearPlanList();
      fetchOrgList(filter);
    }
  }

  onSearchChange(event) {
    const { fetchOrgList, clearOrgList, clearOrgStats, clearPlanList } = this.props;
    const searchTerm = event.target.value;
    this.setState({ searchTerm });
    if (!event.target.value) {
      const filter = { Size: 10, SortBy: "ModifiedDate", SortOrder: "Descending" };
      clearOrgList();
      clearOrgStats();
      clearPlanList();
      fetchOrgList(filter);
    }
  }

  render() {
    const { 
      orgList, 
      totalOrgCount, 
      orgStats, 
      planList, 
      orgConfigByIds,
      accountTypes
    } = this.props;

    return (
      <div className="form-div-class">
        <OrgListHeader onSearch={this.onSearch} onChange={this.onSearchChange}/>
        <Skeleton
          active
          paragraph={{ rows: 16 }}
          loading={!(orgList && orgStats && planList)}
        >
          <OrgList
            orgStats={orgStats}
            orgs={orgList}
            planList={planList}
            orgConfigByIds={orgConfigByIds}
            accountTypes = {accountTypes}
          />        
        </Skeleton>
        <Pagination
          size="small"
          total={totalOrgCount ? totalOrgCount : 0}
          onChange={this.onPageChange}
          hideOnSinglePage={totalOrgCount ? totalOrgCount <= 15 : true}
          disabled={!orgList}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OrganizationList)
);

export { OrganizationList as OrganizationListWithoutRouter }