import React from "react";
import _ from "lodash";
import moment from "moment";
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Slider,
  DatePicker,
  Radio,
  Col,
  Icon,
  message,
  Divider,
} from "antd";
import { Link } from "react-router-dom";
import FeaturesSlider from "../FeaturesSlider/FeaturesSlider";
import "./OrgForm.scss";
import {
  validateEmail,
  validateUrl,
  validateNumber,
  validateNumberForMatchingJobs,
} from "../../utils/FieldValidators";
import { handleFeatureToggles } from "../../utils/FeatureUtils";
const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

message.config({
  top: 100,
  duration: 10,
  maxCount: 1,
});
class OrgForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countryRadioValue: "",
      jobStatuses: null,
      uniqueJobStatusValues: [],
      orgAryaFeatures: [],
      orgConnectFeatures: [],
      candidateMatchingJobsMaxRecencyInMonths:
        props.orgConfiguration?.CandidateMatchingJobsMaxRecencyInMonths ?? "",
    };
  }

  componentWillMount() {
    const { type } = this.props;
    if (type === "edit") {
      const { jobStatuses, orgAryaFeatures, orgConnectFeatures } = this.props;
      let uniqueJobStatuses = [];
      if (jobStatuses && jobStatuses.length > 0) {
        for (let i = 0; i < jobStatuses.length; i++) {
          jobStatuses[i].IsActive = true;
          uniqueJobStatuses.push(jobStatuses[i].Name.toLowerCase());
        }
        this.setState({
          jobStatuses: jobStatuses,
          uniqueJobStatusValues: uniqueJobStatuses,
        });
      }
      if (orgAryaFeatures && orgAryaFeatures.length > 0) {
        this.setState({ orgAryaFeatures: orgAryaFeatures });
      }
      if (orgConnectFeatures && orgConnectFeatures.length > 0) {
        this.setState({ orgConnectFeatures: orgConnectFeatures });
      }
    } else if (type === "create") {
      const { availableAryaFeatures, availableConnectFeatures } = this.props;
      this.setState({ jobStatuses: [] });
      this.setState({ orgAryaFeatures: availableAryaFeatures });
      this.setState({ orgConnectFeatures: availableConnectFeatures });
    }
  }

  handleCountryRadioChange(e) {
    this.setState({ countryRadioValue: e.target.value });
  }

  onJobStatusSelect(value, aryaStatus = "") {
    const { jobStatuses } = this.state;
    const { uniqueJobStatusValues } = this.state;
    let isNew = true;

    if (jobStatuses && jobStatuses.length > 0) {
      if (uniqueJobStatusValues.indexOf(value.toLowerCase()) + 1) {
        let index = uniqueJobStatusValues.indexOf(value.toLowerCase());
        isNew = false;
        jobStatuses[index].IsActive = true;
        jobStatuses[index].AryaStatus = aryaStatus;
        jobStatuses.splice(index, 1, jobStatuses[index]);
        uniqueJobStatusValues.splice(index, 1, value.toLowerCase());
      }
    }

    if (isNew) {
      const newJobStatus = {};
      const newUniqueJobStatusValues = value.toLowerCase();
      newJobStatus.Name = value;
      newJobStatus.IsActive = true;
      newJobStatus.AryaStatus = aryaStatus;
      jobStatuses.push(newJobStatus);
      uniqueJobStatusValues.push(newUniqueJobStatusValues);
    }

    this.setState({ jobStatuses: jobStatuses }, () => {
      const { jobStatuses } = this.state;
      const FieldValues = [];
      const openFieldValues = [];
      const closedFieldValues = [];

      jobStatuses.forEach((status) => {
        if (status.IsActive) {
          FieldValues.push(status.Name);
          if (status.AryaStatus === "Open") {
            openFieldValues.push(status.Name);
          } else if (status.AryaStatus === "Closed") {
            closedFieldValues.push(status.Name);
          }
        }
      });
      this.props.form.setFieldsValue({
        JobStatuses: FieldValues,
        AryaOpenStatuses: openFieldValues,
        AryaCloseStatuses: closedFieldValues,
      });
      if (aryaStatus === "Closed") {
        this.props.form.setFieldsValue({
          DefaultJobStatus: _.difference(FieldValues, closedFieldValues)[0],
        });
      }
    });
    this.setState({ uniqueJobStatusValues });
  }

  onJobStatusDeSelect(value) {
    const { jobStatuses } = this.state;
    const { uniqueJobStatusValues } = this.state;
    for (let i = 0; i < jobStatuses.length; i++) {
      if (jobStatuses[i].Name === value) {
        jobStatuses[i].IsActive = false;
        jobStatuses.splice(i, 1, jobStatuses[i]);
        uniqueJobStatusValues.splice(i, 1, value.toLowerCase());
      }
    }
    this.setState({ jobStatuses: jobStatuses }, () => {
      const { jobStatuses } = this.state;
      const FieldValues = [];
      const openFieldValues = [];
      const closedFieldValues = [];

      jobStatuses.forEach((status) => {
        if (status.IsActive) {
          FieldValues.push(status.Name);
          if (status.AryaStatus === "Open") {
            openFieldValues.push(status.Name);
          } else if (status.AryaStatus === "Closed") {
            closedFieldValues.push(status.Name);
          }
        }
      });
      this.props.form.setFieldsValue({
        JobStatuses: FieldValues,
        AryaOpenStatuses: openFieldValues,
        AryaCloseStatuses: closedFieldValues,
        DefaultJobStatus: _.difference(FieldValues, closedFieldValues)[0],
      });
    });
    this.setState({ uniqueJobStatusValues: uniqueJobStatusValues });
  }

  onOpenStatusSelect(value) {
    this.onJobStatusSelect(value, "Open");
  }

  onAryaJobStatusDeSelect(value) {
    const { jobStatuses } = this.state;
    for (let i = 0; i < jobStatuses.length; i++) {
      if (jobStatuses[i].Name === value) {
        jobStatuses[i].AryaStatus = "";
        jobStatuses.splice(i, 1, jobStatuses[i]);
      }
    }
    this.setState({ jobStatuses });
  }

  onCloseStatusSelect(value) {
    this.onJobStatusSelect(value, "Closed");
  }

  getOrgStatus(orgDetails) {
    return orgDetails?.IsActive ? "Active" : "InActive";
  }

  getOrgLevelMiles(orgDetails) {
    return orgDetails?.OrgLevelMiles ?? "50";
  }

  onSubmit() {
    const { form, onCreate, onEdit } = this.props;
    const { jobStatuses } = this.state;
    let errMessage = null;
    jobStatuses.forEach((status) => {
      if (!status.AryaStatus) {
        errMessage =
          "Please map all the Job Statuses to either of the Arya Open or Close Status";
      }
    });
    form.validateFieldsAndScroll((err, values) => {
      if (!err && !errMessage) {
        const formValues = _.cloneDeep(values);
        if (
          !formValues.DefaultJobStatus &&
          jobStatuses &&
          jobStatuses.length > 0
        ) {
          errMessage = "Please select a default Job Status";
        }
        if (!errMessage) {
          if (onCreate) {
            onCreate(jobStatuses, formValues);
          } else if (onEdit) {
            onEdit(jobStatuses, formValues);
          }
        }
      } else if (errMessage) {
        message.error(errMessage);
      }
    });
  }

  onClickSaveAryaFeatures = () => {
    const { form, updateAryaFeaturesUpdate, orgDetails } = this.props;
    const { orgAryaFeatures, candidateMatchingJobsMaxRecencyInMonths } =
      this.state;
    let errMessage = null;
    form.validateFieldsAndScroll((err) => {
      if (!err) {
        updateAryaFeaturesUpdate(
          orgDetails?.Id,
          orgAryaFeatures,
          candidateMatchingJobsMaxRecencyInMonths
        );
      } else if (errMessage) {
        message.error(errMessage);
      }
    });
  };

  enableAllConnectFeatures() {
    const { orgConnectFeatures } = _.cloneDeep(this.state);
    for (let i = 0; i < orgConnectFeatures.length; i++) {
      orgConnectFeatures[i].IsEnabled = true;
    }
    this.setState({ orgConnectFeatures });
  }

  disableAllConnectFeatures() {
    const { orgConnectFeatures } = _.cloneDeep(this.state);
    for (let i = 0; i < orgConnectFeatures.length; i++) {
      orgConnectFeatures[i].IsEnabled = false;
    }
    this.setState({ orgConnectFeatures });
  }

  handleAryaFeatureChange(access, featureName) {
    const { orgAryaFeatures } = this.state;
    if (featureName === "Connect" && !access.IsEnabled) {
      this.disableAllConnectFeatures();
    } else if (featureName === "Connect" && access.IsEnabled) {
      this.enableAllConnectFeatures();
    }
    const clonedOrgAryaFeatures = _.cloneDeep(orgAryaFeatures);
    const _orgAryaFeatures = handleFeatureToggles({
      features: clonedOrgAryaFeatures,
      featureName,
      featureAccess: access,
    });
    this.setState({ orgAryaFeatures: _orgAryaFeatures });
  }

  handleCandidateMatchingJobsMaxRecencyInMonthsChange(value) {
    this.setState({ candidateMatchingJobsMaxRecencyInMonths: value });
  }

  handleConnectFeatureChange(value, featureName) {
    const { orgConnectFeatures } = _.cloneDeep(this.state);
    for (let i = 0; i < orgConnectFeatures.length; i++) {
      if (orgConnectFeatures[i].Name === featureName) {
        orgConnectFeatures[i].IsEnabled = value.IsEnabled;
        orgConnectFeatures[i].IsAllowed = value.IsAllowed;
      }
    }
    this.setState({ orgConnectFeatures });
  }

  fetchUpdateOrgAryaFeaturesApiStatus = () => {
    const { updateOrgAryaFeaturesApiStatus } = this.props;
    return ["FAILED", "PENDING"].includes(updateOrgAryaFeaturesApiStatus);
  };

  displayOrgAryaFeatures() {
    const { orgAryaFeatures, candidateMatchingJobsMaxRecencyInMonths } =
      this.state;
    const { type, form } = this.props;
    const { getFieldDecorator } = form;

    let configSwitches = [];
    configSwitches = orgAryaFeatures.map((feature, index) => (
      <Form>
        <div
          className={
            feature.Name === "CandidateMatchingJobs"
              ? "candidate-matching-job-feature-edit"
              : "feature"
          }
        >
          <Col xs={24} sm={5}>
            {feature.Name}
          </Col>
          <FeaturesSlider
            onSliderChange={(access) =>
              this.handleAryaFeatureChange(access, feature.Name)
            }
            feature={feature}
          />
          {feature.Name === "CandidateMatchingJobs" && (
            <Form.Item>
              {getFieldDecorator("MatchingJobsMaxRecencyInMonths", {
                rules: [
                  {
                    validator: validateNumberForMatchingJobs,
                  },
                ],
                initialValue: candidateMatchingJobsMaxRecencyInMonths,
              })(
                <Input
                  onChange={(e) => {
                    this.handleCandidateMatchingJobsMaxRecencyInMonthsChange(
                      e.target.value
                    );
                  }}
                  className="input-matching-job-feature"
                  placeholder={
                    candidateMatchingJobsMaxRecencyInMonths !== ""
                      ? candidateMatchingJobsMaxRecencyInMonths
                      : "Enter months"
                  }
                />
              )}
            </Form.Item>
          )}
        </div>
      </Form>
    ));
    return (
      <div>
        {type === "edit" ? (
          <div>
            <Divider />
            <Row gutter={24}>
              <Col span={5}>
                <div className="feature-label">Arya Features</div>
              </Col>
              <Col span={5} offset={10}>
                <Button
                  type="primary"
                  onClick={this.onClickSaveAryaFeatures}
                  loading={this.fetchUpdateOrgAryaFeaturesApiStatus()}
                >
                  Save Arya Features
                </Button>
              </Col>
            </Row>
            <Divider />
          </div>
        ) : null}
        {configSwitches}
      </div>
    );
  }

  fetchUpdateOrgAryaConnectFeaturesApiStatus = () => {
    const { updateOrgAryaConnectFeaturesApiStatus } = this.props;
    return ["FAILED", "PENDING"].includes(
      updateOrgAryaConnectFeaturesApiStatus
    );
  };

  displayOrgConnectFeatures() {
    const { orgConnectFeatures } = this.state;
    const { type, orgDetails, updateAryaConnectFeaturesUpdate } = this.props;
    let configSwitches = [];
    configSwitches = orgConnectFeatures.map((feature, index) => (
      <Form.Item>
        <div style={{ display: "flex" }}>
          <Col xs={24} sm={5}>
            {feature.Name}
          </Col>
          <FeaturesSlider
            onSliderChange={(access) =>
              this.handleConnectFeatureChange(access, feature.Name)
            }
            feature={feature}
            isSliderDisabled={false}
          />
        </div>
      </Form.Item>
    ));
    return (
      <div>
        {type === "edit" ? (
          <div>
            <Divider />
            <Row gutter={24}>
              <Col span={8}>
                <div className="feature-label">Arya Connect Features</div>
              </Col>
              <Col span={5} offset={8}>
                <Button
                  type="primary"
                  className="submit-button"
                  onClick={() =>
                    updateAryaConnectFeaturesUpdate(
                      orgDetails?.OrgGuid,
                      orgConnectFeatures
                    )
                  }
                  loading={this.fetchUpdateOrgAryaConnectFeaturesApiStatus()}
                >
                  Save Connect Features
                </Button>
              </Col>
            </Row>
            <Divider />
          </div>
        ) : null}
        {configSwitches}
      </div>
    );
  }

  displayCountrySection() {
    const { type } = this.props;
    if (type === "create") {
      return null;
    }
    const { allCountries, authenticatedCountries, form } = this.props;
    const { getFieldDecorator } = form;
    const { countryRadioValue } = this.state;

    const allCountryNames = [];
    if (allCountries && allCountries.length > 0) {
      for (let i = 0; i < allCountries.length; i++) {
        allCountryNames.push(allCountries[i].Name);
      }
    }
    const authCountryNames = [];
    if (authenticatedCountries && authenticatedCountries.length > 0) {
      for (let i = 0; i < authenticatedCountries.length; i++) {
        authCountryNames.push(authenticatedCountries[i].Name);
      }
    }

    let countryMenuList = null;

    if (allCountries) {
      countryMenuList = allCountries.map((countryOption, index) => (
        <Option value={countryOption.Name} key={`country${index.toString()}`}>
          {countryOption.Name}
        </Option>
      ));
    }

    const countrySelect = (
      <Select
        mode="multiple"
        disabled={countryRadioValue === "All"}
        allowClear={countryRadioValue === "All"}
        key="Industry"
        placeholder={
          countryRadioValue === "All"
            ? "Select Countries to exclude"
            : "Add Countries"
        }
        style={{ width: "100%" }}
      >
        {countryMenuList}
      </Select>
    );
    return (
      <div className="orgeneralinfo">
        <div className="orginfohead">
          <span>Country</span>
        </div>
        <div>
          <span>
            Add countries in which the organization wants to create Jobs. These
            countries will appear when creating a job
          </span>
        </div>

        <div style={{ margin: "20px 0px" }}>
          <FormItem colon={false}>
            {getFieldDecorator("CountryRadioCheck", {
              initialValue: authCountryNames.length > 0 ? "Manual" : "All",
            })(
              <Radio.Group onChange={(e) => this.handleCountryRadioChange(e)}>
                <Radio value="All">Add All</Radio>
                <Radio value="Manual">Add Manually</Radio>
              </Radio.Group>
            )}
          </FormItem>
        </div>

        <FormItem colon={false}>
          {getFieldDecorator("AuthenticatedCountries", {
            initialValue:
              countryRadioValue === "All" ? allCountryNames : authCountryNames,
          })(countrySelect)}
        </FormItem>
      </div>
    );
  }

  render() {
    const {
      orgDetails,
      form,
      type,
      defaultJobStatus,
      accountTypes,
      licenceTypes,
      isReadOnly,
    } = this.props;

    const { getFieldDecorator } = form;
    const { jobStatuses } = this.state;

    const displayedAryaOpenStatusNames = [];
    const displayedAryaCloseStatusNames = [];
    const jobStatusesNames = [];
    let jobStatusMenuList = [];
    let aryaOpenStatusesOptions = [];
    let aryaCloseStatusesOptions = [];
    let aryaDefaultStatusesOptions = [];
    if (jobStatuses && jobStatuses.length > 0) {
      for (let i = 0; i < jobStatuses.length; i++) {
        if (jobStatuses[i].IsActive === true) {
          const jobStatus = jobStatuses[i];

          if (jobStatus.AryaStatus !== "Closed") {
            aryaDefaultStatusesOptions.push(
              <Option
                value={jobStatus.Name}
                key={`defaultJobStatus${i.toString()}`}
              >
                {jobStatus.Name}
              </Option>
            );
          }

          if (jobStatus.AryaStatus !== "Closed") {
            aryaOpenStatusesOptions.push(
              <Option
                value={jobStatus.Name}
                key={`openJobStatus${i.toString()}`}
              >
                {jobStatus.Name}
              </Option>
            );
            if (jobStatus.AryaStatus === "Open") {
              displayedAryaOpenStatusNames.push(jobStatus.Name);
            }
          }
          if (jobStatus.AryaStatus !== "Open") {
            aryaCloseStatusesOptions.push(
              <Option
                value={jobStatus.Name}
                key={`closeJobStatus${i.toString()}`}
              >
                {jobStatus.Name}
              </Option>
            );
            if (jobStatus.AryaStatus === "Closed") {
              displayedAryaCloseStatusNames.push(jobStatus.Name);
            }
          }
          if (jobStatus.IsActive) {
            jobStatusMenuList.push(
              <Option value={jobStatus.Name} key={`jobStatus${i.toString()}`}>
                {jobStatus.Name}
              </Option>
            );
          }

          jobStatusesNames.push(jobStatuses[i].Name);
        }
      }
    }

    const jobStatusesInput = (
      <Select
        onSelect={(value) => this.onJobStatusSelect(value)}
        onDeselect={(value) => this.onJobStatusDeSelect(value)}
        mode="tags"
        style={{ width: "100%" }}
        placeholder="Add status"
        key="jobstatusMenuList"
      >
        {jobStatusMenuList}
      </Select>
    );

    const aryaOpenStatuses = (
      <Select
        onSelect={(value) => this.onOpenStatusSelect(value)}
        onDeselect={(value) => this.onAryaJobStatusDeSelect(value)}
        mode="tags"
        style={{ width: "100%" }}
        placeholder="Select Status"
        key="aryaOpenStatusesOptions"
      >
        {aryaOpenStatusesOptions}
      </Select>
    );

    const aryaCloseStatuses = (
      <Select
        onSelect={(value) => this.onCloseStatusSelect(value)}
        onDeselect={(value) => this.onAryaJobStatusDeSelect(value)}
        mode="tags"
        style={{ width: "100%" }}
        placeholder="Select Status"
        key="aryaCloseStatusesOptions"
      >
        {aryaCloseStatusesOptions}
      </Select>
    );

    const aryaDefaultStatuses = (
      <Select style={{ width: "100%" }} placeholder="Select Status">
        {aryaDefaultStatusesOptions}
      </Select>
    );
    const orgStatusMenu = (
      <Select placeholder="Status">
        <Option value={"Active"}>Active</Option>
        <Option value={"InActive"}>InActive</Option>
      </Select>
    );

    const accountTypeMenu = (
      <Select placeholder="Account type" disabled={isReadOnly}>
        {accountTypes?.map((value) => (
          <Option
            key={value.AccountTypeId}
            title={value.AccountType}
            value={value.AccountTypeId}
          >
            {value.AccountType}
          </Option>
        ))}
      </Select>
    );

    const licenceTypeMenu = (
      <Select placeholder="Licence type" disabled={isReadOnly}>
        {licenceTypes?.map((value) => (
          <Option
            key={value.LicenceTypeId}
            title={value.LicenceType}
            value={value.LicenceTypeId}
          >
            {value.LicenceType}
          </Option>
        ))}
      </Select>
    );

    return (
      <div className="form-div-class">
        <div className="orgcreatesection">
          <Row gutter={24}>
            <Link to="/orgs">
              <Col span={1}>
                <span role="presentation">
                  <Icon
                    type="close-circle"
                    theme="outlined"
                    className="close-icon"
                  />
                </span>
              </Col>
            </Link>

            <Col span={15}>
              <FormItem colon={false} required="true">
                {getFieldDecorator("Name", {
                  rules: [{ required: true }],
                  initialValue: orgDetails?.Name,
                })(
                  <Input
                    placeholder="Organization Name"
                    id="JobTitle"
                    size="small"
                    className="title"
                    disabled={type === "edit"}
                  />
                )}
              </FormItem>
            </Col>

            <Col span={5}>
              <FormItem colon={false}>
                {getFieldDecorator("OrgStatus", {
                  initialValue:
                    type === "edit" ? this.getOrgStatus(orgDetails) : "Active",
                })(orgStatusMenu)}
              </FormItem>
            </Col>

            <Col span={2}>
              <Button
                type="primary"
                className="submit-button"
                onClick={() => this.onSubmit()}
              >
                {type === "create" ? "Create" : "Save Org"}
              </Button>
            </Col>
          </Row>
        </div>
        <div className="createdivshadow">
          <Row gutter={24}>
            <Col span={12}>
              <FormItem label="Primary contact person" colon={false}>
                {getFieldDecorator("ContactPerson", {
                  rules: [{ required: true }],
                  initialValue: orgDetails?.ContactPerson,
                })(
                  <Input
                    placeholder="Contact Person"
                    label="Primary contact person"
                  />
                )}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem label="Primary email" colon={false}>
                {getFieldDecorator("ContactEmail", {
                  rules: [
                    {
                      required: true,
                      message: "Email is required",
                    },
                    {
                      validator: validateEmail,
                    },
                  ],
                  initialValue: orgDetails?.ContactEmail,
                })(<Input placeholder="Contact Email" label="Primary email" />)}
              </FormItem>
            </Col>
          </Row>

          <FormItem
            label="Organization office address"
            className="bot-description-text"
            colon={false}
          >
            {getFieldDecorator("Address", {
              initialValue: orgDetails?.Address,
            })(
              <TextArea
                placeholder="Organization office address"
                className="bot-input-description"
              />
            )}
          </FormItem>

          <div>
            <FormItem label="Organization website" colon={false}>
              {getFieldDecorator("HomePageLink", {
                rules: [
                  {
                    required: true,
                    message: "Home page link is required",
                  },
                  {
                    validator: validateUrl,
                  },
                ],
                initialValue: orgDetails?.HomePageLink,
              })(<Input placeholder="Home Page Link" label="Primary email" />)}
            </FormItem>
          </div>
          <Row gutter={24}>
            <Col span={12}>
              <FormItem label="Account type" colon={false}>
                {getFieldDecorator("AccountTypeId", {
                  rules: [{ required: true }],
                  initialValue: orgDetails?.AccountTypeId,
                })(accountTypeMenu)}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem label="Licence type" colon={false}>
                {getFieldDecorator("LicenceTypeId", {
                  rules: [{ required: true }],
                  initialValue: orgDetails?.LicenceTypeId,
                })(licenceTypeMenu)}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem label="Subscription End Date" colon={false}>
                {getFieldDecorator("SubscriptionExpiry", {
                  rules: [{ required: true }],
                  initialValue: moment(orgDetails?.SubscriptionExpiry),
                })(<DatePicker />)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <FormItem label="Default Sourcing Limit" colon={false}>
                {getFieldDecorator("SourceLimit", {
                  rules: [
                    {
                      required: true,
                      message: "Sourcing limit is required",
                    },
                    {
                      validator: validateNumber,
                    },
                  ],
                  initialValue: orgDetails?.SourceLimit,
                })(<Input type="number" label="Sourcing Limit" />)}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Job limit" colon={false}>
                {getFieldDecorator("JobsLimit", {
                  initialValue: orgDetails?.JobsLimit,
                })(<Input type="number" label="Job limit" min={1} />)}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Miles" colon={false}>
                <Row gutter={20} className="miles-slider-row">
                  <Col span={10}>
                    {getFieldDecorator("OrgLevelMiles", {
                      initialValue: this.getOrgLevelMiles(orgDetails),
                    })(
                      <Slider disabled={false} min={50} tooltipVisible={true} />
                    )}
                  </Col>
                </Row>
              </FormItem>
            </Col>
          </Row>
          {this.displayCountrySection()}

          <div className="orgeneralinfo">
            <div className="Configure-Job-Status">
              <div className=" ">
                <div className="Configure-Job-title">Configure Job Status</div>
                <div className="contact-settings-description">
                  Add or remove job status and map them with the Arya status
                </div>
              </div>
            </div>

            <div className="Configure-Job-Status-title">Add Status</div>

            <FormItem colon={false}>
              {getFieldDecorator("JobStatuses", {
                rules: [
                  { required: true, message: "Please enter Job Status!" },
                ],
                initialValue:
                  jobStatusesNames.length > 0 ? jobStatusesNames : [],
              })(jobStatusesInput)}
            </FormItem>

            <div className="Configure-Job-Status-title">Status Mapping</div>
            <Row gutter={24}>
              <Col span={3}>
                <Input disabled={true} placeholder="Open (Arya)" />
              </Col>
              <Col span={1} style={{ paddingTop: "8px" }}>
                =
              </Col>
              <Col span={8}>
                <FormItem colon={false}>
                  {getFieldDecorator("AryaOpenStatuses", {
                    initialValue:
                      displayedAryaOpenStatusNames.length > 0
                        ? displayedAryaOpenStatusNames
                        : [],
                  })(aryaOpenStatuses)}
                </FormItem>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={3}>
                <Input disabled={true} placeholder="Close (Arya)" />
              </Col>
              <Col span={1} style={{ paddingTop: "8px" }}>
                =
              </Col>

              <Col span={8}>
                <FormItem colon={false}>
                  {getFieldDecorator("AryaCloseStatuses", {
                    initialValue:
                      displayedAryaCloseStatusNames.length > 0
                        ? displayedAryaCloseStatusNames
                        : [],
                  })(aryaCloseStatuses)}
                </FormItem>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={3}>
                <Input disabled={true} placeholder="Default (Arya)" />
              </Col>
              <Col span={1}>=</Col>

              <Col span={8}>
                <FormItem colon={false}>
                  {getFieldDecorator("DefaultJobStatus", {
                    rules: [
                      {
                        required: true,
                        message: "Please select Default Job Status",
                      },
                    ],
                    initialValue: defaultJobStatus ? defaultJobStatus : "",
                  })(aryaDefaultStatuses)}
                </FormItem>
              </Col>
            </Row>
          </div>
        </div>
        {type === "edit" ? (
          <div>
            <div className="createdivshadow">
              {this.displayOrgAryaFeatures()}
            </div>
            <div className="createdivshadow">
              {this.displayOrgConnectFeatures()}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const OrgFormComponent = Form.create()(OrgForm);

export default OrgFormComponent;
