import _ from "lodash";
export default function ClientReducer(state = {}, action) {
    let newState;
    switch (action.type) {
        case "CLIENT_CREATE_SUCCESS": {
            newState = _.cloneDeep(state);
            newState.clientDetails = action.payload;
            return newState;
        }
        case "CLIENT_CREATE_FAILED": {
            newState = _.cloneDeep(state);
            newState.clientDetails = action.payload;
            return newState;
        }

        case "CLIENT_LIST_FETCH_SUCCESS": {
            newState = _.cloneDeep(state);
            newState.clientList = action.payload;
            return newState;
        }

        case "CLIENT_LIST_FETCH_FAILED": {
            newState = _.cloneDeep(state);
            newState.clientList = action.payload;
            return newState;
        }

        case "CLIENT_DETAILS_FETCH_SUCCESS": {
            newState = _.cloneDeep(state);
            newState.clientDetails = action.payload;
            return newState;
        }

        case "CLIENT_DETAILS_FETCH_FAILED": {
            newState = _.cloneDeep(state);
            newState.clientDetails = action.payload;
            return newState;
        }

        case "CLEAR_CLIENT_LIST": {
            newState = _.cloneDeep(state);
            newState.clientList = action.payload;
            return newState;
        }

        case "SET_SUBSDIARIES": {
            newState = _.cloneDeep(state);
            newState.subsdiaryCompanies = action.payload;
            return newState;
        }

        case "SET_COMPETITOR_COMPANIES": {
            newState = _.cloneDeep(state);
            newState.competitorCompanies = action.payload;
            return newState;
        }
        case "SET_INDUSTRIES": {
            newState = _.cloneDeep(state);
            newState.industries = action.payload;
            return newState;
        }
        default:
            return state;
    }
}

function getClientDetails(state) {
    return state.ClientReducer.clientDetails;
}

function getClientList(state) {
    return state.ClientReducer.clientList;
}
function getSubsdiaryCompanies(state) {
    return state.ClientReducer.subsdiaryCompanies;
}

function getCompetitorCompanies(state) {
    return state.ClientReducer.competitorCompanies;
}

function getIndustries(state) {
    return state.ClientReducer.industries;
}

export { ClientReducer, getClientDetails, getClientList, getSubsdiaryCompanies, getCompetitorCompanies, getIndustries };