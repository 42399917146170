const config = {
  appId: "d31f44fd-11f3-43ba-a378-7b09df3bf900",
  apiV1Url: "https://staging.goarya.com/aryaapi/api",
  apiV2Url: "https://app-staging.goarya.com/api/v2",
  apiV3Url: "https://app-staging.goarya.com/api/v3",
  urls: {
    aryaPay: "https://pay-staging.goarya.com/api/v1",
    aryaConnect: "https://connectapi-staging.goarya.com/api/v1",
    aryaConnectCandidatePage: "https://connect-staging.goarya.com/chat",
    aryaCalendar: "https://connectapi-staging.goarya.com/calendar",
    aryaConnectNotifications:
      'https://connectapi-staging.goarya.com/hubs/notification',
    aryaConnectChat: 'https://connectapi-staging.goarya.com/hubs/connect',
    aryaNotifySocketEndpoint: 'https://notify-staging.goarya.com',
    aryaNotify: 'https://notify-staging.goarya.com/api/v1',
    smartjob: 'https://jobsapi-qa.goarya.com/api/v1',
    careerPortal: 'https://portal-staging.goarya.com',
  },
  auth: {
    cognito: {
      clientId: "7vtslg5jbkjt2dnec3saajv7dm",
      poolId: "us-east-1_OmTFZPYTB",
      region: "us-east-1",
      domain: "aryaidentity-staging.auth.us-east-1.amazoncognito.com",
      redirectUri: "https://assist-staging.goarya.com",
    },
  },
  sip: {
    uri: "1220@devproxy01.buniapp.com",
    wsServers: "wss://devproxy01.buniapp.com:443/ws",
    password: "Le0$)rCe@(*&%$#2018@sPeC",
    displayName: "Arya Connect",
    iceServers: [
      {
        urls: "stun:ice.buniapp.com:3478",
      },
      {
        urls: "turn:ice.buniapp.com:3478",
        username: "aryavoip",
        credential: "aryavoip@123",
      },
    ],
  },
  sso: {
    providers: [
      {
        path: "/lenovo",
        identifier: "Lenovo",
      },
      {
        path: "/samling",
        identifier: "Samling",
      },
    ],
  },
  charactersPerMessage: 152,
  supportEmail: "support@leoforce.com",
  getRequestCreditsEmailContent: (amount) => ({
    Subject: "P.S. Ignore, this is a test email",
    Body: `Requesting for ${amount} credits`,
    To: "support@leoforce.com",
  }),

  consentTemplateBody:
    "Hi {{Candidate.Name}}! Are you interested in exploring {{Job.Title}} opportunity at {{Organization.Name}}? Please reply YES if you consent, NO for not interested (or) STOP if you do not want to receive texts about {{Organization.Name}} Job opportunities. Thanks, {{Recruiter.FirstName}}, Recruiter",
  consentTemplateName: "Mandatory Consent Message",
  Product: {
    ProductVariants: [
      {
        Name: "SMS",
        Id: ["2A7FFE10-24BB-11EF-AE22-0EA2F1D5502F"],
      },
    ],
  },
};

export default config;
