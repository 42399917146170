import React from "react";
import NetworkErrorComponent from "../NetworkErrorComponent/NetworkErrorComponent";

class NetworkIssueErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    console.error(error, info);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (!hasError) {
      return children;
    }

    return <NetworkErrorComponent />;
  }
}

export default NetworkIssueErrorBoundary;
