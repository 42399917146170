import React from "react";
import _ from "lodash";
import { Input, Form, Select, DatePicker, InputNumber } from "antd";
import moment from "moment";
import { validateNumber } from "../../utils/FieldValidators";
import "./OrgDetailHeader.scss";

const FormItem = Form.Item;
const { Option } = Select;

const PlanDetails = (props) => {
  const { form, plan, planTypes, activePanel } = props;
  const { getFieldDecorator } = form;
  return (
    <div className="add-plan-model">
      <FormItem colon={false} required="true">
        {getFieldDecorator(`Name${activePanel}`, {
          rules: [
            {
              required: true,
              message: "Plan name is required",
            },
          ],
          initialValue: _.get(plan, "Name", ""),
        })(<Input placeholder="Plan Name" className="plan-name" />)}
      </FormItem>
      <div className="emails">
        <FormItem
          label="Plan Type"
          colon={false}
          required="true"
          className="plan-coloumn"
        >
          {getFieldDecorator(`Type${activePanel}`, {
            rules: [
              {
                required: true,
                message: "Please select Plan Type",
              },
            ],
            initialValue: _.get(plan, "Type", ""),
          })(
            <Select style={{ width: "100%" }} placeholder="Select Plan Type">
              {planTypes.map((planType) => (
                <Option value={planType} key={planType}>
                  {planType}
                </Option>
              ))}
            </Select>
          )}
        </FormItem>
        <FormItem
          label="PlanCredits"
          colon={false}
          required="true"
          className="plan-coloumn"
        >
          {getFieldDecorator(`PlanCredits${activePanel}`, {
            rules: [
              {
                required: true,
                message: "Credits are required",
              },
              {
                validator: validateNumber,
              },
            ],
            initialValue: _.get(plan, "Credits", 0),
          })(<InputNumber placeholder="Subscription Credits" />)}
        </FormItem>
      </div>

      <div className="emails">
        <FormItem
          label="Start Date"
          colon={false}
          required="true"
          className="plan-coloumn"
        >
          {getFieldDecorator(`StartDate${activePanel}`, {
            rules: [
              {
                required: true,
                message: "Please select Start Date",
              },
            ],
            initialValue:
              plan && plan.StartDate ? moment(plan.StartDate) : null,
          })(<DatePicker />)}
        </FormItem>

        <FormItem label="End Date" colon={false} className="plan-coloumn">
          {getFieldDecorator(`EndDate${activePanel}`, {
            initialValue: plan && plan.EndDate ? moment(plan.EndDate) : null,
          })(<DatePicker />)}
        </FormItem>
      </div>
    </div>
  );
};

export default PlanDetails;
