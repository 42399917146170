import React from "react";
import { Modal, Form, Tabs } from "antd";
import _ from "lodash";
import "./OrgDetailHeader.scss";
import PlanDetails from "./PlanDetails";
import { getProductVariant } from "../../utils/ProductVariantDetails";

const { TabPane } = Tabs;

class Plan extends React.Component {
  constructor(props) {
    super(props);
    this.handleOk = this.handleOk.bind(this);
    this.state = {
      activePanel: "Arya",
    };
  }

  componentDidUpdate(prevProps) {
    const { plan, form } = this.props;
    if (plan && prevProps.plan && prevProps.plan.Credits !== plan.Credits) {
      form.setFieldsValue({
        PlanCredits: plan.Credits,
      });
    }
  }

  handleOk() {
    const {
      form,
      onUpdatePlan,
      onCreatePlan,
      plan,
      smsPlan,
      togglePlanVisibility,
    } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const formValues = _.cloneDeep(values);
        const { activePanel } = this.state;
        const productVariant = getProductVariant("SMS");

        const planToUpdate = activePanel === "Arya" ? plan : smsPlan;
        if (productVariant && activePanel === "Text") {
          formValues.ProductVariants = productVariant;
        }
        if (planToUpdate) {
          onUpdatePlan(formValues, planToUpdate, activePanel);
        } else {
          onCreatePlan(formValues, activePanel);
        }
        togglePlanVisibility();
      }
    });
  }
  onChangePanel = (value) => {
    this.setState({
      activePanel: value,
    });
  };
  render() {
    const {
      form,
      plan,
      isVisible,
      orgDetails,
      togglePlanVisibility,
      planTypes,
      smsPlan,
    } = this.props;
    const { activePanel } = this.state;
    const orgLavelPlans =
      activePanel === "Arya" ? (
        <PlanDetails
          form={form}
          plan={plan}
          planTypes={planTypes}
          activePanel={activePanel}
        />
      ) : (
        <PlanDetails
          form={form}
          plan={smsPlan}
          planTypes={planTypes}
          activePanel={activePanel}
        />
      );

    return (
      <Modal
        title={`Plan- ${orgDetails.Name}`}
        visible={isVisible}
        okText="Save"
        destroyOnClose
        onOk={this.handleOk}
        onCancel={togglePlanVisibility}
      >
        <Tabs
          tabBarStyle={{
            marginBottom: "0px",
          }}
          onTabClick={this.onChangePanel}
          destroyInactiveTabPane
          animated={false}
          activeKey={this.state.activePanel}
        >
          <TabPane tab={<div>Arya</div>} key="Arya">
            {orgLavelPlans}
          </TabPane>

          <TabPane tab={<div>Text</div>} key="Text">
            {orgLavelPlans}
          </TabPane>
        </Tabs>
      </Modal>
    );
  }
}

const PlanComponent = Form.create()(Plan);

export default PlanComponent;
