import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as OrgActions from "../../actions/orgActions";
import { getApiStatus } from "../../reducers/ApiStatusReducer";
import { getOrgConfiguration, getSourceConfiguration } from "../../reducers/orgReducer";
import {
  getMaxCount,
  getAllUserIds
} from "../../reducers/userReducer.js";
import { Skeleton, Radio  } from "antd";
import "./PortalsDetail.scss";
import SourcingBehaviourType from "../../components/PortalDetails/SourcingBehaviourType";
import { PortalView } from '../../components/PortalsConfiguration/PortalView/PortalView';
import { getAccountName } from '../../utils/PortalUtils';

const mapStateToProps = state => ({
  orgConfiguration: getOrgConfiguration(state),
  maxCount: getMaxCount(state),
  allUserIds: getAllUserIds(state),
  updateSourceConfigStatus : getApiStatus(state,'updateSourceConfigApiStatus'),
  fetchEligibleSourcesApiStatus: getApiStatus(state,'fetchEligibleSourcesApiStatus'),
  fetchOrgSourceConfigApiStatus: getApiStatus(state,'fetchOrgSourceConfigApiStatus'),
  sourceConfig : getSourceConfiguration(state)
});

const mapDispatchToProps = {
  fetchOrgConfiguration: OrgActions.fetchOrgConfiguration,
  updateSourceConfig : OrgActions.updateSourceConfig,
  createSourceConfig : OrgActions.createSourceConfig,
  fetchAllowedSources : OrgActions.getEligibleSourceConfig
};

class PortalsDetail extends React.Component {

  constructor(props) {
    super(props);

    this.state={
      activePortalSubTab :"active"
    };
    this.onSubTabChange = this.onSubTabChange.bind(this);
  }

  componentDidMount() {
    const {
      fetchOrgConfiguration,
      match,
      fetchAllowedSources,
      orgDetails,
      accountTypes
    } = this.props;
    const { orgId } = match.params;

    const sourceCofigurationQuery = { IsAuthorized: null, IsEnabled: null };

    const accountType = getAccountName(orgDetails?.AccountTypeId,accountTypes)
    fetchOrgConfiguration(orgId, sourceCofigurationQuery);
    fetchAllowedSources(accountType);  
  }

  onSubTabChange(e){
    this.setState({ activePortalSubTab: e.target.value });
  }

  render() {
    const { 
      orgConfiguration,
      fetchEligibleSourcesApiStatus,
      fetchOrgSourceConfigApiStatus,
      sourceConfig 
    } = this.props;

    return (
      <Skeleton active paragraph={{ rows: 16 }} 
        loading={fetchEligibleSourcesApiStatus !== "SUCCESS" || fetchOrgSourceConfigApiStatus !== "SUCCESS"}
      >
        <div className="portals-container">
          <div className="portals-container-wrapper">
            <SourcingBehaviourType
              onSourcingTypeSave={this.onSourcingTypeSave}
              sourcingType={orgConfiguration?.StackRankType}
            />
            <div className="portal-sub-tab-selection">
              <Radio.Group value={this.state.activePortalSubTab}>
                <Radio.Button value="active" onChange={this.onSubTabChange}>Arya Active Channel</Radio.Button>
                <Radio.Button value="passive" onChange={this.onSubTabChange}>Arya Passive Channel</Radio.Button>
                <Radio.Button value="default" onChange={this.onSubTabChange}>Dedicated Portals</Radio.Button>
              </Radio.Group>
            </div>
            <div className="portal-sub-tab">
              <PortalView tabKey={this.state.activePortalSubTab} sourceConfig={sourceConfig} {...this.props}/>
            </div>
            </div>
          </div>
      </Skeleton>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PortalsDetail)
);

export { PortalsDetail as PortalsDetailWithoutRouter };
