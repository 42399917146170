import React from "react";
import TimeFilter from "./TimeFilter";
import "./Dashboard.scss";

class ConnectDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromTime: "now-1w",
      toTime: "now"
    };
  }
  onTimeFilter = (fromTime, toTime) => {
    this.setState({
      fromTime,
      toTime
    });
  };
  render() {
    const { timeFormat } = this.props;
    const { fromTime, toTime } = this.state;
    return (
      <div className="dashboard">
        <div className="kibana-aryacore-iframe">
          <div className="date-filter-wrapper">
            <div className="heading">Connect Activity</div>
            <TimeFilter
              onTimeFilter={this.onTimeFilter}
              timeFormat={timeFormat}
            />
          </div>
          <iframe
            src={`https://search-logs-slp74umkdlzbe4unt7y6jzp2yu.us-east-1.es.amazonaws.com/_plugin/kibana/app/kibana#/dashboard/1741d0a0-24f8-11ea-a03a-55cad581f776?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3A'${fromTime}'%2Cmode%3Aabsolute%2Cto%3A'${toTime}'))`}
            height="600"
            width="100%"
            title="connect"
          ></iframe>
        </div>
      </div>
    );
  }
}

export default ConnectDashboard;
