import { saveAs } from "file-saver";

export function saveBase64AsFile(base64Content, fileName = "Download", fileType = "application/pdf") {
  const byteCharacters = atob(base64Content);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: fileType });
  saveAs(blob, fileName);
}
