import React from "react";
import { Input, Button } from "antd";
import { SignIn } from "aws-amplify-react";
import { Auth } from "aws-amplify";
import "./Auth.scss";

export default class LoginForm extends SignIn {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      loading: false
    };

    this._validAuthStates = ["signIn", "signedOut"];

    this.onSubmit = this.onSubmit.bind(this);
    this.onLoginError = this.onLoginError.bind(this);
    this.onHandleChange = this.onHandleChange.bind(this);
  }

  onLoginError(err) {
    // eslint-disable-next-line no-console
    console.error(err);
    this.setState({
      loading: false
    });
  }

  onSubmit(e) {
    const { username, password, loading } = this.state;
    sessionStorage.removeItem("aryaidp");
    if (loading || !username || !password) {
      return;
    }
    this.inputs.username = username.trim().toLowerCase();
    this.setState({
      loading: true,
      username: this.inputs.username
    });
    super
      .signIn(e)
      .then(myObj => {
        console.log(myObj);
        // closeSocketConnection();
        // ? Cannot connect this component due to AWS Amplify
        // ? Hence dispaching using store
        // store.dispatch(setupSocketConnections());
        Auth.currentSession().then(session => {
          console.log(session);
          const { jwtToken: token } = session.getIdToken();
          if (!token) {
            return;
          }
        });
      })
      .catch(err => {
        console.log(err);
        this.onLoginError(err);
      });
  }

  onHandleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
    super.handleInputChange(e);
  }

  showComponent() {
    const { username, password, loading } = this.state;
    const isDisabled = !username || !password;
    const idp = sessionStorage.getItem("aryaidp");

    if (idp) {
      return <React.Fragment>Sit tight while we log you in!</React.Fragment>;
    }

    return (
      <div className="auth-container">
        <div className="arya-logo">
          <img
            src={`${process.env.PUBLIC_URL}/static/Images/arya-logo-green.svg`}
            alt="arya logo"
            style={{ height: "50px", width: "50px" }}
          />
          <img
            src={`${process.env.PUBLIC_URL}/static/Images/arya-text-dark.svg`}
            alt="arya logo"
            style={{ height: "42px", width: "100px" }}
          />
        </div>
        <div className="auth-form">
          <p className="auth-title">Welcome Back</p>
          <p className="auth-sub-title">Login to your account</p>
          <p className="auth-label">Email</p>
          <Input
            id="username"
            key="username"
            name="username"
            required
            onChange={this.onHandleChange}
            onPressEnter={this.onSubmit}
            className="auth-input"
            placeholder="Email address"
            value={username}
          />
          <p className="auth-label">Password</p>
          <Input
            id="password"
            key="password"
            name="password"
            required
            onChange={this.onHandleChange}
            onPressEnter={this.onSubmit}
            type="password"
            className="auth-input"
            placeholder="Enter Password"
            value={password}
          />
          <Button
            className="auth-primary-button"
            type="primary"
            disabled={isDisabled}
            loading={loading}
            onClick={e => this.onSubmit(e)}
          >
            Login
          </Button>
          <Button
            className="auth-secondary-button"
            onClick={() => super.changeState("forgotPassword")}
          >
            <span>Forgot Password</span>
          </Button>
        </div>
      </div>
    );
  }
}
