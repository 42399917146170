import React from "react";
import "./ClientList.scss";
import ClientListElement from "./../ClientListElement/ClientListElement";
import { Empty } from "antd";

export default class ClientList extends React.Component {
    render() {
        const { showClientDetail, clientList } = this.props;
        return (
            <div>
                <div className="client-list-column-headers">
                    <div className="client-column-header">Client</div>
                    <div className="client-column-header">CONTACT</div>
                    <div className="client-column-header">Primary Contact</div>
                    {/* <div className="source-count-column-header">Assign</div> */}
                    <div className="source-count-column-header">Source Count</div>
                    <div className="status-column-header">STATUS</div>
                </div>
                {clientList.length ? (
                    clientList.map(client => (
                        <ClientListElement
                            clientDetails={client}
                            showClientDetail={showClientDetail}
                        />
                    ))
                ) : (
                        <Empty
                            image={
                                <img
                                    src={`${
                                        process.env.PUBLIC_URL
                                        }/static/Images/empty-candidates.svg`}
                                    alt=""
                                    className="candidate-empty-list"
                                />
                            }
                            description={
                                <div>
                                    <div className="candidate-list-no-candidates-found">
                                        {" "}
                                        No clients Found
                </div>

                                    <div className="candidate-list-no-candidates-found-message">
                                        All clients will appear here
                </div>
                                </div>
                            }
                        />
                    )}
            </div>
        )
    }
}