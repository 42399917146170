import React from "react";
import { Switch, Input, Form, Button, Col, Radio, Tooltip, Select } from "antd";
import "./UserForm.scss";
import _ from "lodash";
import debounce from "lodash/debounce";
import FeaturesSlider from "../FeaturesSlider/FeaturesSlider";
import {
  validateEmail,
  validatePhone,
  validatePassword,
} from "../../utils/FieldValidators";
import { getAccountName } from "../../utils/PortalUtils";
import { handleFeatureToggles } from "../../utils/FeatureUtils";

const { Option } = Select;
class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connectUserFeatures: [],
      aryaUserFeatures: [],
      audioCallerIdCollection: [],
      confirmDirty: false,
      selectedCountry: "US",
      selectedLocation: undefined,
      locationInput: "",
    };
    this.filterLocation = debounce(this.filterLocation, 600);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onAddPhoneNumber = this.onAddPhoneNumber.bind(this);
    this.onDeletePhoneNumber = this.onDeletePhoneNumber.bind(this);
  }

  handleSubmit() {
    const { form, handleSubmit, userDetails, mode, orgDetails, accountTypes } =
      this.props;
    const { connectUserFeatures, aryaUserFeatures, audioCallerIdCollection } =
      this.state;

    const accountName = getAccountName(orgDetails.AccountTYpeId, accountTypes);

    form.validateFieldsAndScroll((err, values) => {
      if (!err || (mode === "edit" && (err.Passowrd || err.ConfirmPassword))) {
        let formValues = _.cloneDeep(values);
        if (accountName?.toLowerCase() === "aryapulse") {
          const location = formValues.selectLocation.split("@");
          formValues = { ...formValues, City: location[0], State: location[1] };
          delete formValues.selectLocation;
        }
        let usableAudioCallerIdCollection = [];
        for (let i = 0; i < audioCallerIdCollection.length; i++) {
          if (audioCallerIdCollection[i].Action !== "") {
            usableAudioCallerIdCollection.push(audioCallerIdCollection[i]);
          }
        }
        handleSubmit(
          userDetails,
          connectUserFeatures,
          aryaUserFeatures,
          usableAudioCallerIdCollection,
          formValues
        );
      }
    });
  }

  componentDidMount() {
    const { mode } = this.props;
    if (mode === "edit") {
      const { aryaUserFeatures, connectUserFeatures, audioCallerIdCollection } =
        this.props;
      this.setState({ audioCallerIdCollection: audioCallerIdCollection });
      this.setState({ aryaUserFeatures: aryaUserFeatures });
      this.setState({ connectUserFeatures: connectUserFeatures });
    }
  }

  getUserSecurityRoles() {
    const { orgDetails } = this.props;
    const allowedRoleObjects = orgDetails.Roles;
    let roleRadios = [];
    if (allowedRoleObjects) {
      allowedRoleObjects.forEach((role) => {
        roleRadios.push(
          <Radio value={role.RoleName} key={role.RoleName}>
            {role.RoleName}
          </Radio>
        );
      });
    }
    return roleRadios;
  }

  handleAryaFeatureChange(access, featureName) {
    const { aryaUserFeatures } = this.state;
    const _aryaUserFeatures = handleFeatureToggles({
      features: aryaUserFeatures,
      featureName,
      featureAccess: access,
    });
    this.setState({ aryaUserFeatures: _aryaUserFeatures });
  }

  handleConnectFeatureChange(access, featureName) {
    const { connectUserFeatures } = _.cloneDeep(this.state);
    for (let i = 0; i < connectUserFeatures.length; i++) {
      if (connectUserFeatures[i].Name === featureName) {
        connectUserFeatures[i].IsEnabled = access.IsEnabled;
        connectUserFeatures[i].IsAllowed = access.IsAllowed;
      }
    }
    this.setState({ connectUserFeatures });
  }

  displayAryaFeatures() {
    const { aryaUserFeatures } = this.state;
    let configSwitches = [];
    configSwitches = aryaUserFeatures?.map((feature, index) => (
      <Form.Item key={feature?.Id}>
        <div style={{ display: "flex" }}>
          <Col xs={24} sm={5}>
            {feature.Name}
          </Col>
          <FeaturesSlider
            onSliderChange={(access) =>
              this.handleAryaFeatureChange(access, feature?.Name)
            }
            feature={feature}
          />
        </div>
      </Form.Item>
    ));
    return configSwitches;
  }

  displayConnectFeatures() {
    const { connectUserFeatures } = this.state;

    let configSwitches = [];
    configSwitches = connectUserFeatures?.map((feature, index) => (
      <Form.Item key={feature?.Id}>
        <div style={{ display: "flex" }}>
          <Col xs={24} sm={5}>
            {feature?.Name}
          </Col>
          <FeaturesSlider
            onSliderChange={(access) =>
              this.handleConnectFeatureChange(access, feature.Name)
            }
            feature={feature}
            isSliderDisabled={false}
          />
        </div>
      </Form.Item>
    ));
    return configSwitches;
  }

  onAddPhoneNumber(callerId) {
    const audioCallerIdCollection = [...this.state.audioCallerIdCollection];
    const tmpAudioCallerIdQuery = {};
    tmpAudioCallerIdQuery.Action = "Add";
    tmpAudioCallerIdQuery.CallerId = callerId;
    audioCallerIdCollection.push(tmpAudioCallerIdQuery);
    this.setState({ audioCallerIdCollection: audioCallerIdCollection });
  }

  onDeletePhoneNumber(index) {
    const { audioCallerIdCollection } = this.state;
    const toBeDeletedAudioCallerIdQuery = audioCallerIdCollection[index];
    if (toBeDeletedAudioCallerIdQuery.Action === "Add") {
      audioCallerIdCollection.splice(index, 1);
    } else {
      const tmpAudioCallerIdQuery = {};
      tmpAudioCallerIdQuery.CallerId = audioCallerIdCollection[index].CallerId;
      tmpAudioCallerIdQuery.Action = "Remove";
      audioCallerIdCollection.splice(index, 1, tmpAudioCallerIdQuery);
    }
    this.setState({ audioCallerIdCollection: audioCallerIdCollection });
  }

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("Password")) {
      callback("Password and Confirm Password mismatch");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["ConfirmPassword"], { force: true });
    }
    callback();
  };

  handleLocationChange = (value) => {
    const { form } = this.props;
    if (!value || !value.length) {
      form.setFieldsValue({
        selectLocation: undefined,
        ZipCode: undefined,
      });
    }
  };

  handleCountryChange = (value) => {
    if (!value || !value.length) {
      const { form } = this.props;
      form.setFieldsValue({
        selectLocation: undefined,
        ZipCode: undefined,
      });
    }
  };

  onCountrySelect = (value) => {
    const { selectedCountry } = this.state;
    const { form } = this.props;
    if (selectedCountry !== value) {
      form.setFieldsValue({
        selectLocation: undefined,
        ZipCode: undefined,
      });
    }
    this.setState({
      selectedCountry: value,
    });
  };

  getCountrySelect = () => {
    const countries = [{ name: "USA", iso2Code: "US", iso3Code: "USA" }];
    const countryMenuList = countries?.map((country, index) => (
      <Option
        value={country.iso2Code}
        title={country.name}
        key={`country${index.toString()}`}
      >
        {country.name}
      </Option>
    ));
    const countrySelect = (
      <Select
        key="Country"
        placeholder="Select Country"
        className=""
        optionFilterProp="title"
        showSearch
        onSelect={(value) => this.onCountrySelect(value)}
        onChange={(value) => this.handleCountryChange(value)}
        allowClear
      >
        {countryMenuList}
      </Select>
    );
    return countrySelect;
  };

  getZipCodeSelect = () => {
    const { userUtilities, form } = this.props;
    const isCountrySelected = form.getFieldValue("Country");
    const isLocationSelected = form.getFieldValue("selectLocation");

    const zipCodes = _.get(userUtilities, "zipCodes", []);
    const zipCodeMenuList = zipCodes?.map((zipCode, index) => (
      <Option
        value={zipCode}
        title={zipCode}
        key={`zipCode${index.toString()}`}
      >
        {zipCode}
      </Option>
    ));
    const zipCodeSelect = (
      <Select
        key="ZipCode"
        placeholder="Select Zip Code"
        className=""
        optionFilterProp="title"
        disabled={!(isCountrySelected && isLocationSelected)}
        showSearch
        allowClear
      >
        {zipCodeMenuList}
      </Select>
    );
    return zipCodeSelect;
  };

  getLocationDropDownStatus = () => {
    const { locationApiStatus } = this.props;
    const { locationInput } = this.state;
    let locationDropDownStatus;
    if (locationInput && locationInput.length) {
      locationDropDownStatus =
        locationApiStatus === "INPROGRESS" ? "Loading..." : "No location found";
    } else {
      locationDropDownStatus = "Type to search";
    }
    return locationDropDownStatus;
  };

  getLocationSelect = () => {
    const { userUtilities, form } = this.props;
    const locationDropDownStatus = this.getLocationDropDownStatus();
    const isCountrySelected = form.getFieldValue("Country");
    let locations = _.get(userUtilities, "locations", []);
    locations = locations.filter((location) => location.city && location.state);
    const locationMenuList = locations?.map((location, index) => {
      let optionValueText = `${location.city}, `;
      optionValueText = optionValueText + location.state;
      optionValueText = _.trim(optionValueText, ", ");
      const optionValueArray = [
        location.city,
        location.stateCode || location.state,
        optionValueText,
      ];
      const optionValue = optionValueArray.join("@");
      return (
        <Option
          value={optionValue}
          key={`${optionValueText}${index.toString()}`}
        >
          {optionValueText}
        </Option>
      );
    });

    const locationSelect = (
      <Select
        allowClear
        key="Location"
        showSearch
        placeholder="Enter Location"
        className=""
        onSearch={(value) => this.filterLocation(value)}
        onSelect={(value) => this.callForZipCodes(value)}
        onChange={(value) => this.handleLocationChange(value)}
        disabled={!isCountrySelected}
        showArrow={false}
        notFoundContent={locationDropDownStatus}
      >
        {locationMenuList}
      </Select>
    );
    return locationSelect;
  };

  callForZipCodes(value) {
    const { form, fetchZipCodes } = this.props;
    const { selectedLocation } = this.state;
    if (!_.isEqual(selectedLocation, value)) {
      form.setFieldsValue({
        ZipCode: undefined,
      });
    }
    this.setState({
      selectedLocation: value,
    });
    const country = form.getFieldValue("Country");
    const location = value.split("@");
    fetchZipCodes(
      {
        country,
        location: location[2],
      },
      false
    );
  }

  filterLocation = (value) => {
    this.setState({
      locationInput: value,
    });
    const { form, fetchLocations } = this.props;
    const country = form.getFieldValue("Country");
    fetchLocations(
      {
        country,
        search: value,
      },
      false
    );
  };

  render() {
    const { userDetails, form, handleClose, mode, orgDetails, accountTypes } =
      this.props;
    const { getFieldDecorator } = form;

    const countrySelect = this.getCountrySelect();
    const locationSelect = this.getLocationSelect();
    const zipCodeSelect = this.getZipCodeSelect();
    const accountName = getAccountName(orgDetails.AccountTypeId, accountTypes);

    let passwordFieldContent = (
      <div className="emails">
        <Form.Item label="Password" className="company-email" hasFeedback>
          {getFieldDecorator("Password", {
            rules: [
              { required: true },
              { validator: this.validateToNextPassword },
              { validator: validatePassword },
            ],
            initialValue: "",
          })(<Input type="password" placeholder="Enter Password" />)}
        </Form.Item>

        <Form.Item
          label="Confirm password"
          className="company-email"
          hasFeedback
        >
          {getFieldDecorator("ConfirmPassword", {
            rules: [
              { required: true },
              { validator: this.compareToFirstPassword },
            ],
            initialValue: "",
          })(
            <Input
              type="password"
              placeholder="Confirm Password"
              onBlur={this.handleConfirmBlur}
            />
          )}
        </Form.Item>
      </div>
    );
    if (mode === "edit") {
      passwordFieldContent = null;
    }
    return (
      <div>
        <div className="client-form-body">
          <Form>
            <div className="client-name-form">
              <Form.Item>
                {getFieldDecorator("FirstName", {
                  rules: [{ required: true }],
                  initialValue: _.get(userDetails, "FirstName", ""),
                })(
                  <Input
                    placeholder="First Name"
                    className="client-name-input"
                  />
                )}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator("LastName", {
                  rules: [{ required: true }],
                  initialValue: _.get(userDetails, "LastName", ""),
                })(
                  <Input
                    placeholder="Last Name"
                    className="client-name-input"
                  />
                )}
              </Form.Item>
            </div>

            <div>
              <Form.Item label="Email">
                {getFieldDecorator("EmailId", {
                  rules: [
                    {
                      required: true,
                      message: "Email is required",
                    },
                    {
                      validator: validateEmail,
                    },
                  ],
                  initialValue: _.get(userDetails, "Email", ""),
                })(
                  <Input
                    placeholder="Enter Email"
                    className="company-email"
                    disabled={mode === "edit"}
                  />
                )}
              </Form.Item>
            </div>

            <div className="emails">
              <Form.Item label="Office phone">
                {getFieldDecorator("OfficePhone", {
                  rules: [
                    {
                      validator: validatePhone,
                    },
                  ],
                  initialValue: _.get(userDetails, "OfficePhone", ""),
                })(
                  <Input
                    placeholder="Enter Phone Number"
                    className="company-email"
                  />
                )}
              </Form.Item>

              <Form.Item label="Home phone">
                {getFieldDecorator("HomePhone", {
                  rules: [
                    {
                      validator: validatePhone,
                    },
                  ],
                  initialValue: _.get(userDetails, "HomePhone", ""),
                })(
                  <Input
                    placeholder="Enter Home Phone Number"
                    className="company-email"
                  />
                )}
              </Form.Item>
            </div>
            {passwordFieldContent}

            {/* <div className="phone-number-title">Caller IDs</div>
            <UserAudioCallerId audioCallerIdCollection={audioCallerIdCollection}
              authenticatedCountries={authenticatedCountries}
              onAddPhoneNumber={this.onAddPhoneNumber}
              onDeletePhoneNumber={this.onDeletePhoneNumber}
            /> */}
            {accountName?.toLowerCase() === "aryapulse" ? (
              <React.Fragment>
                <div className="select-country">
                  <Form.Item label="Country">
                    {getFieldDecorator("Country", {
                      rules: [
                        {
                          required: true,
                          message: "Please select a country.",
                        },
                      ],
                    })(countrySelect)}
                  </Form.Item>
                </div>

                <div className="location-input">
                  <div className="select-city-state-input">
                    <Form.Item label="City, State">
                      {getFieldDecorator("selectLocation", {
                        rules: [
                          {
                            required: true,
                            message: "Enter your current location.",
                          },
                        ],
                      })(locationSelect)}
                    </Form.Item>
                  </div>
                  <div className="select-zipcode-input">
                    <Form.Item label="Zip Code">
                      {getFieldDecorator("ZipCode", {
                        rules: [
                          {
                            required: true,
                            message: "Enter a valid Zip Code.",
                          },
                        ],
                      })(zipCodeSelect)}
                    </Form.Item>
                  </div>
                </div>
              </React.Fragment>
            ) : null}

            <div className="security-role">Security role</div>
            <div>
              <Form.Item>
                {getFieldDecorator("RoleName", {
                  rules: [{ required: true }],
                  initialValue: _.get(userDetails, "RoleName", ""),
                })(<Radio.Group>{this.getUserSecurityRoles()}</Radio.Group>)}
              </Form.Item>
            </div>
            {mode !== "create" ? (
              <div>
                <div className="phone-number-title">Arya features</div>
                <div className="connectradio">{this.displayAryaFeatures()}</div>

                <div className="phone-number-title">Connect features</div>
                <div className="connectradio">
                  {this.displayConnectFeatures()}
                </div>
              </div>
            ) : null}
          </Form>
        </div>
        <div className="client-form-footer">
          <div style={{ display: "inline-block" }}>
            <Form.Item>
              <span className="client-status-label">Active Status </span>
              <Tooltip
                overlayStyle={{ zIndex: "3000" }}
                placement="right"
                title={
                  orgDetails && !orgDetails.IsActive
                    ? "Organization is InActive"
                    : ""
                }
              >
                {getFieldDecorator("IsActive", {
                  initialValue:
                    _.get(orgDetails, "IsActive") &&
                    _.get(userDetails, "IsActive", true),
                })(
                  <Switch
                    defaultChecked={
                      _.get(orgDetails, "IsActive") &&
                      _.get(userDetails, "IsActive", true)
                    }
                    disabled={orgDetails ? !orgDetails.IsActive : false}
                  />
                )}
              </Tooltip>
            </Form.Item>
          </div>
          <div className="buttons">
            <Button className="cancel" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              className="create-client"
              onClick={() => this.handleSubmit()}
            >
              {userDetails ? (userDetails.Id ? "Save" : "Create") : "Create"}
            </Button>
          </div>
        </div>
        <div />
      </div>
    );
  }
}

const UserFormComponent = Form.create()(UserForm);

export default UserFormComponent;
