import React from 'react';
import { PortalSubTabHeader } from './PortalSubTabHeader/PortalSubTabHeader';
import { PortalCardView } from './PortalCardView/PortalCardView';
import { PortalListView } from './PortalListView/PortalListView';

export function DedicatedChannel(props){
    const {
        title,
        subTitle,
        portalsUnderChannel,
        updateSourceConfig,
        orgId,
        tabkey,
        createSourceConfig,
        sourceConfig
    } = props;

    return(
    <div>
        <PortalSubTabHeader title={title} subTitle={subTitle} isLogoVisible={false}/>
        {portalsUnderChannel?.map(source =>
                        <PortalCardView 
                            key={source?.Id}
                            source={source} 
                            updateSourceConfig={updateSourceConfig}
                            orgId={orgId}
                            tabkey={tabkey}
                        />
                    )}
        <PortalListView 
          portals={sourceConfig} 
          addPortal={createSourceConfig}
          orgId={orgId}
          tabkey={tabkey}
        />
    </div>);
}