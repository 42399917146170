import { apiV3Client, connectV1Client, payV1Client, careerPortalClient } from "./baseRepository";

export function createOrg(orgDetails) {
  return apiV3Client.post("organizations", orgDetails);
}

export function createBotContextForOrg(orgBotContextDetails) {
  return connectV1Client.post(`BotContexts`, orgBotContextDetails);
}

export function upsertConnectOrg(orgGuid, orgDetails) {
  const connectOrgDetails = extractConnectOrgDetails(orgGuid, orgDetails);
  return connectV1Client.post(`organizations`, connectOrgDetails);
}

export function fetchServiceProviders(orgId, serviceProviderSearchQuery) {
  return connectV1Client.post(
    `organizations/${orgId}/serviceproviders`,
    serviceProviderSearchQuery
  );
}

export function updateServiceProvider(orgId, subscribeServiceProviderQuery) {
  return connectV1Client.put(
    `organizations/${orgId}/serviceproviders/_subscribe`,
    subscribeServiceProviderQuery
  );
}

export function upsertPayOrg(orgGuid, orgDetails) {
  const payOrgDetails = extractPayOrgDetails(orgGuid, orgDetails);
  return payV1Client.post(`groups`, payOrgDetails);
}

export function updateOrg(orgId, orgDetails) {
  return apiV3Client.put(`organizations/${orgId}`, orgDetails);
}

export function getCountries() {
  return apiV3Client.get("countries?subscribed=false");
}

export function getAuthenticatedCountries(orgId) {
  return apiV3Client.get(`organizations/${orgId}/countries`);
}

export function fetchOrgList(filter) {
  return apiV3Client.post("organizations/_search", filter);
}

export function fetchOrgStats(orgIdsCollection) {
  return apiV3Client.post("organizations/_stats", orgIdsCollection);
}

export function fetchOrgDetails(orgId) {
  return apiV3Client.get(`organizations/${orgId}`);
}

export function fetchCareerPortalClient(orgId) {
  return careerPortalClient.get(`/api/clients/orgs/${orgId}`);
}

export function fetchTestPixelJobUrls(clientId) {
  careerPortalClient.defaults.headers["x-client-id"] = clientId;
  return careerPortalClient.get("/api/jobs/pixelJobUrls");
}

export function fetchClickDetails(clickId) {
  return careerPortalClient.get(`/api/clicktracking/clicks/${clickId}`);
}

export function fetchBulkOrgConfiguration(orgIdsCollection) {
  return apiV3Client.post(`organizations/_sourceconfig`, orgIdsCollection);
}

export function fetchOrgConfiguration(orgId, sourceCofigurationQuery) {
  return apiV3Client.get(`organizations/${orgId}/_config`);
}

export function fetchJobStatuses(orgId) {
  return apiV3Client.get(`organizations/${orgId}/jobstatuses`);
}

export function fetchAvailableAryaFeatures() {
  return apiV3Client.get(`features`);
}

export function fetchAvailableConnectFeatures() {
  return connectV1Client.get(`features`);
}

export function fetchOrgAryaFeatures(orgId) {
  return apiV3Client.get(`organizations/${orgId}/features`);
}

export function fetchOrgConnectFeatures(orgGuid) {
  return connectV1Client.get(`organizations/${orgGuid}/features`);
}

export function fetchOrgTrackingInfo(orgId, pixelType) {
  return apiV3Client.get(`orgs/${orgId}/_trackingInfo?pixelType=${pixelType}`)
}

export function configureAOD(orgId, aodConfiguration) {
  return apiV3Client.post(`orgs/${orgId}/_config/aodConfig`, aodConfiguration);
}

export function updateAuthenticatedCountries(orgId, countryCodesCollection) {
  return apiV3Client.put(
    `organizations/${orgId}/countries`,
    countryCodesCollection
  );
}

export function updateOrgJobStatusMappings(orgId, jobStatusesCollection) {
  return apiV3Client.put(
    `organizations/${orgId}/jobstatuses`,
    jobStatusesCollection
  );
}

export function updateOrgAryaFeatures(orgId, aryaFeaturesCollection) {
  return apiV3Client.put(
    `organizations/${orgId}/features`,
    aryaFeaturesCollection
  );
}

export function updateOrgConnectFeatures(orgGuid, connectFeaturesCollection) {
  return connectV1Client.put(
    `organizations/${orgGuid}/features`,
    connectFeaturesCollection
  );
}

export function updateOrgConfiguration(orgId, orgConfiguration) {
  return apiV3Client.put(`organizations/${orgId}/_config`, orgConfiguration);
}

export function createPlan(plan) {
  return payV1Client.post(`plans`, plan);
}

// export function fetchPlan(planId) {
//   return payV1Client.get(`plans/${planId}`, planId);
// }

export function fetchPlanList(orgGuidsCollection) {
  return payV1Client.post(`plans/_search`, orgGuidsCollection);
}

export function updatePlan(planId, plan) {
  return payV1Client.put(`plans/${planId}`, plan);
}

export function addCredits(paymentCommand) {
  return payV1Client.post(`Credits/add`, paymentCommand);
}

export function addJobCredits(addJobCreditsCommand) {
  return payV1Client.post(`plans/_credits/`, addJobCreditsCommand);
}

export function extractConnectOrgDetails(orgGuid, orgDetails) {
  const connectOrgDetails = {};
  connectOrgDetails.Id = orgGuid;
  connectOrgDetails.Name = orgDetails.Name.replace(/\s/g, "");
  connectOrgDetails.DisplayName = orgDetails.Name;
  return connectOrgDetails;
}

export function extractPayOrgDetails(orgGuid, orgDetails) {
  const payOrgDetails = {};
  payOrgDetails.Id = orgGuid;
  payOrgDetails.Name = orgDetails.Name.replace(/\s/g, "");
  payOrgDetails.DisplayName = orgDetails.Name;
  return payOrgDetails;
}

export function setSourceConfig(payload) {
  return apiV3Client.put("/_config/sourceconfig", payload);
}

export function getEligibleSources(accType) {
  return apiV3Client.get(`/_config/_sources/${accType}`);
}

export function getAccountTypes() {
  return apiV3Client.get(`/_config/accountTypes`);
}

export function getLicenceTypes() {
  return apiV3Client.get(`/_config/licenceTypes`);
}

export function getPendingRequests(orgId) {
  return payV1Client.get(`/Credits/pending/${orgId}`);
}

export function changePendingRequestStatus(filter, id) {
  return payV1Client.post(`Credits/${id}/status`, filter);
}
